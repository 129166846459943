import { useEffect, useMemo, useState } from 'react'
import { BarChartByYear, BeeomonitoringSite } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { UseQueryResult } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { BarChartValueWithColor } from '@/types/graph'
import { getBarSubstancePeriodData } from '@/lib/graph'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import CardBarChartSkeleton from '@/components/card-barchart-skeleton'
import CardBarChartSubstance from '@/components/card-barchart-substances'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import SubstancesTable from '@/components/substances-table'
import { H3 } from '@/components/typography'

interface SubstancePageViewProps {
  title: string
  subtitle: string
  description: React.ReactNode
  siteData: UseQueryResult<BeeomonitoringSite, Error>
  substanceData: UseQueryResult<BarChartByYear[], Error>
  type?: string
  table_title?: string
  table_subtitle?: string
}

const SubstancePageView = ({
  title,
  subtitle,
  description,
  siteData,
  substanceData,
  type = 'PAH',
  table_title,
  table_subtitle,
}: SubstancePageViewProps) => {
  const [searchParams] = useSearchParams()

  const year = searchParams.get('year') ?? '2023'
  const period = searchParams.get('period') ?? 'P4'

  const { t } = useTranslation()
  const [selectedYear, setSelectedYear] = useState(year)
  const [selectedPeriod, setSelectedPeriod] = useState(period)
  const [years, setYears] = useState<string[]>([])
  const [periods, setPeriods] = useState<string[]>([])

  const [substanceDataByPeriods, setSubstanceDataByPeriods] = useState<BarChartValueWithColor[]>([])
  const [substanceFullDataByPeriods, setSubstanceFullDataByPeriods] = useState<BarChartValueWithColor[]>([])

  const { data: site, isLoading: isLoadingSite } = siteData
  const { data: dataValuesByPeriod, isLoading: isLoadingDataByPeriod } = substanceData

  useEffect(() => {
    setSelectedYear(year ?? selectedYear)
    setSelectedPeriod(period ?? selectedPeriod)
  }, [year, period])

  const allYears = useMemo(() => {
    const yearsFamilies = dataValuesByPeriod?.map((item) => item.year) || []
    return Array.from(new Set([...yearsFamilies])).sort()
  }, [dataValuesByPeriod])

  useEffect(() => {
    if (allYears.length > 0) {
      setYears(allYears)
      setSelectedYear(year ?? allYears[allYears.length - 1])
    }
  }, [allYears])

  const allPeriods = useMemo(() => {
    const periodsCountValues = dataValuesByPeriod?.find((item) => item.year === selectedYear)?.periods || []
    return Array.from(new Set([...periodsCountValues].map((item) => item.key))).sort()
  }, [dataValuesByPeriod, selectedYear])

  useEffect(() => {
    if (allPeriods.length > 0) {
      setPeriods(allPeriods)
      setSelectedPeriod(allPeriods.includes(period) ? period : allPeriods[allPeriods.length - 1])
    }
  }, [allPeriods])

  useEffect(() => {
    if (selectedYear && selectedPeriod) {
      const { filteredItems, fullData } = getBarSubstancePeriodData(
        dataValuesByPeriod ?? [],
        selectedPeriod,
        selectedYear
      )
      setSubstanceDataByPeriods(type === 'PAH' ? filteredItems : fullData)
      setSubstanceFullDataByPeriods(fullData)
    }
  }, [dataValuesByPeriod, selectedPeriod, selectedYear])

  return (
    <div className="container relative my-8">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: 'beeomonitoring',
            href: getPath(Routes.BEEOMONITORING_SITES),
          },
          {
            label: t(i18nKeys.beeoinitiative.common.breadcrumb.sites),
            href: getPath(Routes.BEEOMONITORING_SITES),
          },
          {
            label: site?.name ?? '',
            href: getPath(Routes.BEEOMONITORING_SITE, { params: { siteId: site?.id as number } }),
          },
        ]}
        currentPage={title}
      />

      <div className="flex flex-col justify-between">
        <div className="flex items-center justify-between space-y-2">
          <PageHeader>
            <PageHeaderHeading>
              {isLoadingSite ? (
                <div className="flex items-end justify-start">
                  <Skeleton className="mr-2 h-[34px] w-[400px]" />
                  <Skeleton className="h-[24px] w-[80px]" />
                </div>
              ) : (
                site && (
                  <div>
                    {site.name} <span className="text-sm font-medium text-muted-foreground"># {site.key}</span>
                  </div>
                )
              )}
            </PageHeaderHeading>
            <div className="flex flex-row">
              {isLoadingSite ? (
                <>
                  <div>
                    <Skeleton className="h-[22px] w-[62px]" />
                  </div>

                  <div>
                    <Skeleton className="ml-2 h-[22px] w-[110px]" />
                  </div>
                </>
              ) : (
                site?.tags?.map((tag) => (
                  <Badge key={tag} className="mr-4 rounded-none bg-muted text-foreground hover:bg-foreground/10">
                    {tag}
                  </Badge>
                ))
              )}
            </div>
          </PageHeader>
          <div className="flex items-center space-x-2">
            {isLoadingSite || isLoadingDataByPeriod ? (
              <>
                <Skeleton className="h-10 w-44" />
                <Skeleton className="h-10 w-44" />
              </>
            ) : (
              <>
                <Select value={selectedYear} onValueChange={(e) => setSelectedYear(e)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={selectedYear} placeholder={selectedYear} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {years.map((year) => (
                        <SelectItem key={year} value={year}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <Select value={selectedPeriod} onValueChange={(e) => setSelectedPeriod(e)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={selectedPeriod} placeholder={`${selectedPeriod}`} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {periods.map((period, index) => (
                        <SelectItem key={index} value={period}>
                          {period}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-2">
          {isLoadingDataByPeriod ? (
            <CardBarChartSkeleton />
          ) : (
            <CardBarChartSubstance data={substanceDataByPeriods} title={title} subtitle={subtitle} type={type} />
          )}
          <Card>
            <CardHeader>
              <CardTitle>{t(i18nKeys.beeomonitoring.common.definition)}</CardTitle>
              <CardDescription>{subtitle}</CardDescription>
            </CardHeader>
            <CardContent>{description}</CardContent>
          </Card>
        </div>
      </div>
      <div className="my-8">
        <div className="my-8">
          <H3>{table_title}</H3>
          <p className="pb-4 text-sm text-muted-foreground">{table_subtitle}</p>
          <Separator />
        </div>
        <SubstancesTable data={substanceFullDataByPeriods} headers={['Substance', 'Value', 'Limit', 'Units']} />
      </div>
    </div>
  )
}

export default SubstancePageView
