export const SENSITIVE_AREA_SOURCE = {
  CDDA: 'cdda',
  N2K: 'n2k',
  PADUS: 'padus',
}

export const SENSITIVE_AREA_SOURCE_LABELS = {
  [SENSITIVE_AREA_SOURCE.CDDA]: 'purple',
  [SENSITIVE_AREA_SOURCE.N2K]: 'orange',
  [SENSITIVE_AREA_SOURCE.PADUS]: 'blue',
}

export const SENSITIVE_AREA_SOURCE_LABELS_TW = {
  [SENSITIVE_AREA_SOURCE.CDDA]: 'bg-violet-600',
  [SENSITIVE_AREA_SOURCE.N2K]: 'bg-orange-500',
  [SENSITIVE_AREA_SOURCE.PADUS]: 'bg-blue-500',
}
