import { SensitiveAreaOut } from '@/client/backend/models'
import * as turf from '@turf/turf'
import { PathOptions } from 'leaflet'
import { Polygon, Tooltip } from 'react-leaflet'

import { SENSITIVE_AREA_SOURCE_LABELS } from '@/lib/constants/sensitive-area-source'
import { convertGeoJsonToGeometry, GeoJsonArea } from '@/lib/geo'

type SensitiveAreasPolygonProps = {
  sensitiveArea: SensitiveAreaOut
  fullGeom?: boolean
}

const SensitiveAreasPolygon = ({ sensitiveArea, fullGeom = false }: SensitiveAreasPolygonProps) => {
  const options: PathOptions = {
    color: 'white',
    fillColor: SENSITIVE_AREA_SOURCE_LABELS[sensitiveArea.source],
    fillOpacity: 0.5,
    weight: 1,
    opacity: 0.5,
  }
  const newGeom = fullGeom
    ? convertGeoJsonToGeometry(sensitiveArea.geom as GeoJsonArea)
    : convertGeoJsonToGeometry(sensitiveArea.intersection as GeoJsonArea)

  if (!newGeom) {
    return null
  }

  return (
    <Polygon pathOptions={options} positions={newGeom} pane="markerPane" className="z-0">
      <Tooltip sticky>
        <ul>
          <li>{sensitiveArea.name}</li>
          <li>{(turf.area(sensitiveArea.intersection) / 10000).toFixed(2)} ha</li>
        </ul>
      </Tooltip>
    </Polygon>
  )
}

export default SensitiveAreasPolygon
