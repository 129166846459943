import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { Crosshair, FlaskConical, Leaf } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Circle, MapContainer, TileLayer } from 'react-leaflet'
import { Link } from 'react-router-dom'

import { ScoringType } from '@/types/scoring-type'
import { getSiteCenter } from '@/lib/geo'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'

interface SitesCardImpactProps {
  site: Site
  onSiteHover: () => void
  onSiteHoverOut: () => void
  link: string
  scoringType: ScoringType
}

const SiteCardImpact = ({ site, onSiteHover, onSiteHoverOut, link, scoringType }: SitesCardImpactProps) => {
  const { t } = useTranslation()
  const center = getSiteCenter(site)

  const scoring = {
    [ScoringType.BIODIVERSITY]: site?.properties?.stats_biodiversity?.biodiversity_score,
    [ScoringType.PESTICIDES]: site?.properties?.stats_pesticides?.ecoscore,
  }

  const scoreTextColor = (score) => {
    if (score === undefined) {
      return 'text-green-600'
    }

    if (score <= 30) {
      return 'text-[#4CAF50]'
    } else if (score > 30 && score < 71) {
      return 'text-[#FF9800]'
    } else if (score > 70) {
      return 'text-[#F44336]'
    } else {
      return 'text-gray-500'
    }
  }

  return (
    <Card
      className="flex min-h-[280px] w-full flex-col transition-all duration-200 hover:border hover:border-[#1f6f6d]"
      onMouseOver={onSiteHover}
      onMouseOut={onSiteHoverOut}
    >
      <div className="h-[130px] w-full">
        <MapContainer
          className="size-full rounded-t-lg"
          center={center}
          zoom={11.5}
          scrollWheelZoom={false}
          zoomControl={false}
          attributionControl={false}
        >
          <Circle center={center} radius={1500} color="#1f6f6d" fillColor="none" weight={1} />
          <TileLayer
            attribution="© Google Maps"
            url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
            maxZoom={20}
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            tileSize={256}
            detectRetina={true}
          />
        </MapContainer>
      </div>
      <div className="px-8">
        <div className="pb-1 pt-4">
          <p className="truncate text-xl font-semibold">{site?.properties?.name}</p>
          <p className="text-sm text-muted-foreground">
            {t(i18nKeys.global.settings.page.sites.buildingType[site?.properties?.building_type ?? ''])} -{' '}
            {site?.properties?.biodiversity_radius}m
          </p>
        </div>

        <div className="space-y-3">
          <Separator />
          <div className="flex items-center">
            <div className="flex flex-1 flex-col items-center text-sm">
              <div className="flex items-center space-x-1 ">
                {scoringType === ScoringType.BIODIVERSITY ? (
                  <Leaf className={cn('size-3.5 font-semibold', scoreTextColor(scoring[scoringType]))} />
                ) : (
                  <FlaskConical className={cn('size-3.5 font-semibold', scoreTextColor(scoring[scoringType]))} />
                )}

                <p className="font-bold">{scoring[scoringType]}%</p>
              </div>

              <p className="p-2 text-center text-xs">{t(i18nKeys.beeoimpact.sites.labels[scoringType])}</p>
            </div>
            <Separator orientation="vertical" />
            {scoringType === ScoringType.BIODIVERSITY && (
              <div className="flex flex-1 flex-col items-center text-sm">
                <div className="flex items-center space-x-1">
                  <Crosshair className={'size-3.5 font-semibold text-red-600'} />
                  <p className="font-bold">{site?.properties?.stats_biodiversity?.nb_total_threatened_species}</p>
                </div>
                <p className="p-2 text-center text-xs"> {t(i18nKeys.beeoimpact.sites.labels.threatenedSpecies)}</p>
              </div>
            )}
          </div>
          <Separator />
        </div>
        <div className="flex items-center justify-center py-4">
          <Link to={link}>
            <Button variant="outline" size="lg" className="h-[35px] w-24 border-2 border-black">
              {t(i18nKeys.beeoinitiative.sites.details)}
            </Button>
          </Link>
        </div>
      </div>
    </Card>
  )
}

export default SiteCardImpact
