import { useEffect, useState } from 'react'
import { PaginatedSiteList } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import InitiativeChartTooltip from '@/pages/beeoinitiative/components/initiative-chart-tooltip'
import { Euro, EuroIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Label, Pie, PieChart, Tooltip } from 'recharts'

import { Card } from '@/components/ui/card'
import { ChartConfig, ChartContainer } from '@/components/ui/chart'
import I18nNumberFormatter from '@/components/ui/i18n-number-formatter'

interface BudgetTotalProps {
  actionsTakenInitiatives?: PaginatedSiteList
}
const BudgetTotal = ({ actionsTakenInitiatives }: BudgetTotalProps) => {
  const { t } = useTranslation()
  const [showNumberInCircle, setShowNumberInCircle] = useState(false)

  const chartConfig = {
    planned: {
      label: t(i18nKeys.beeoinitiative.dashboard.planned),
      color: 'hsl(var(--biodiv-amberSixHundred))',
    },
    spent: {
      label: t(i18nKeys.beeoinitiative.dashboard.spent),
      color: 'hsl(var(--biodiv-amberThreeHundred))',
    },
  } satisfies ChartConfig

  useEffect(() => {
    const handleResize = () => {
      setShowNumberInCircle(window.innerWidth >= 1737)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const totalPlannedBudget = actionsTakenInitiatives?.results?.features?.reduce((acc, site) => {
    const sitePlanned = site?.properties?.actions_taken?.reduce(
      (sum, action) => sum + parseInt(String(action?.planned_cost ?? 0)),
      0
    )
    return acc + (sitePlanned ?? 0)
  }, 0)

  const totalSpentBudget = actionsTakenInitiatives?.results?.features?.reduce((acc, site) => {
    const siteSpent = site?.properties?.actions_taken?.reduce(
      (sum, action) => sum + parseInt(String(action?.spent_budget ?? 0)),
      0
    )
    return acc + (siteSpent ?? 0)
  }, 0)

  const percentageSpentPlanned = totalPlannedBudget ? ((totalSpentBudget ?? 0) / totalPlannedBudget) * 100 : 0
  const chartData = [
    {
      status: t(i18nKeys.beeoinitiative.dashboard.planned),
      budget: totalPlannedBudget,
      fill: 'hsl(var(--biodiv-amberSixHundred))',
    },
    {
      status: t(i18nKeys.beeoinitiative.dashboard.spent),
      budget: totalSpentBudget,
      fill: 'hsl(var(--biodiv-amberThreeHundred))',
    },
  ]

  return (
    <Card className="z-10 flex flex-col bg-amber-50  sm:px-8 sm:py-9 md:px-8 md:py-9 lg:px-8 lg:py-9 xl:px-6 xl:py-9 2xl:px-10 2xl:py-9">
      <div className="flex justify-between md:h-28 xl:h-[90px]">
        <div>
          <p className="pb-2 text-xl font-semibold">{t(i18nKeys.beeoinitiative.dashboard.budget.title)}</p>
          <p className="pb-5 text-sm">{t(i18nKeys.beeoinitiative.dashboard.budget.subtitle)}</p>
          <p className="flex items-center gap-x-2 self-start font-semibold md:text-xl lg:text-2xl">
            <I18nNumberFormatter value={totalSpentBudget ?? 0} /> /{' '}
            <I18nNumberFormatter value={totalPlannedBudget ?? 0} />
            <Euro className="size-6 font-bold" />
          </p>
        </div>
        <div className="pl-6 pt-8">
          {showNumberInCircle && (
            <div className="flex flex-col items-center">
              <p className="w-max text-6xl font-semibold">{percentageSpentPlanned?.toFixed()}%</p>
              <p className="text-sm">{t(i18nKeys.beeoinitiative.dashboard.used)}</p>
            </div>
          )}
        </div>
        <div className="md:size-44 2xl:size-48">
          <ChartContainer config={chartConfig} className="aspect-square md:mt-[-28px] xl:mt-[-28px]">
            <PieChart>
              <Tooltip
                cursor={false}
                content={({ payload }) => {
                  if (payload && payload.length) {
                    const data = payload[0].payload
                    const label = data.status.toUpperCase()
                    const value = data.budget
                    const color = data.fill
                    return <InitiativeChartTooltip value={value} title={label} color={color} />
                  }
                  return null
                }}
              />
              <Pie
                data={chartData}
                dataKey="budget"
                nameKey="status"
                innerRadius={50}
                strokeWidth={100}
                paddingAngle={2}
              >
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                      return (
                        <>
                          {showNumberInCircle ? (
                            <g>
                              <EuroIcon
                                x={viewBox.cx && viewBox.cx - 15}
                                y={viewBox.cy && viewBox.cy - 20}
                                className="text-biodivAmberSixHundred"
                                width={30}
                                height={36}
                              />

                              <p className="text-4xl font-semibold">{percentageSpentPlanned} %</p>
                            </g>
                          ) : (
                            <text
                              x={viewBox.cx}
                              y={viewBox.cy && viewBox.cy - 30}
                              textAnchor="middle"
                              dominantBaseline="central"
                              className="flex  flex-col text-2xl text-black"
                            >
                              <tspan x={viewBox.cx} dy="1em" className="font-semibold">
                                {percentageSpentPlanned.toFixed()} %
                              </tspan>
                              <tspan x={viewBox.cx} dy="1.2em" className="text-sm">
                                {t(i18nKeys.beeoinitiative.dashboard.used)}
                              </tspan>
                            </text>
                          )}
                        </>
                      )
                    }
                  }}
                />
              </Pie>
            </PieChart>
          </ChartContainer>
        </div>
      </div>
    </Card>
  )
}

export default BudgetTotal
