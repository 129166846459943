import { SensitiveAreaOut } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { SENSITIVE_AREA_COLORS } from '@/lib/constants/sensitive-area-colors'
import { SENSITIVE_AREA_SOURCE_LABELS_TW } from '@/lib/constants/sensitive-area-source'
import { cn } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'
import I18nNumberFormatter from '@/components/ui/i18n-number-formatter'
import { DataTable } from '@/components/data-table/data-table'
import RenderTooltip from '@/components/render-tooltip/render-tooltip'

type SensitiveAreasTableProps = {
  sensitiveAreas: SensitiveAreaOut[]
  biodiversityRadius: number
}

const SensitiveAreasTable = ({ sensitiveAreas, biodiversityRadius }: SensitiveAreasTableProps) => {
  const { t } = useTranslation()

  const columns: ColumnDef<SensitiveAreaOut>[] = [
    {
      id: 'source',
      header: t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.type),
      cell: ({ row }) => {
        const color = SENSITIVE_AREA_SOURCE_LABELS_TW[row.original.source]
        return (
          <Badge className={cn('flex h-6 w-12 justify-center text-center text-xs text-white', color)}>
            {t(i18nKeys.beeoimpact.sensitiveAreas.source[row.original.source])}
          </Badge>
        )
      },
    },
    {
      id: 'radius',
      header: t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.radius),
      cell: () => biodiversityRadius,
    },
    {
      id: 'name',
      header: t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name),
      cell: ({ row }) => row.original.name,
    },
    {
      id: 'location',
      header: t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.position),
      cell: ({ row }) => (
        <Badge className={`w-max ${SENSITIVE_AREA_COLORS[row.original.location]}`}>
          {t(i18nKeys.beeoimpact.biodiversityTable.location[row.original.location])}
        </Badge>
      ),
    },
    {
      id: 'surface_ha',
      header: () => (
        <div className="flex items-center gap-x-2">
          {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surface)}{' '}
          <RenderTooltip
            size={20}
            explanationText={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surfaceDefinition)}
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className=" w-20 text-end">
          <I18nNumberFormatter
            options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            value={row.original.surface_ha ?? 0}
          />
        </div>
      ),
    },
    {
      id: 'distance_m',
      header: () => (
        <div className="flex items-center gap-x-2">
          {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.distance)}{' '}
          <RenderTooltip
            size={20}
            explanationText={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.distanceDefinition)}
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="w-20 text-end tabular-nums">
          <I18nNumberFormatter
            options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            value={row.original.distance_m ?? 0}
          />
        </div>
      ),
    },
  ]

  return <DataTable columns={columns} data={sensitiveAreas} filterIdColumn="name" />
}

export default SensitiveAreasTable
