/* eslint-disable react-refresh/only-export-components */

import { createContext, FC, useEffect, useMemo } from 'react'
import { FeatureFlagsIntegration, getClient } from '@sentry/browser'

/**
 * Feature flags are used to enable or disable features in the application.
 * They can be set in the environment variables, in localStorage, or via URL parameters.
 * This allows for easy testing and gradual rollouts of new features.
 */
type FeatureFlags = Record<string, boolean>

const defaultFlags: FeatureFlags = __FEATURE_FLAGS__ || {}
const LOCAL_STORAGE_KEY = 'featureFlags'

// Get URL overrides (e.g., `?feature-new-dashboard=false`)
const getUrlOverrides = () => {
  const params = new URLSearchParams(window.location.search)
  const overrides: FeatureFlags = {}
  for (const [key, value] of params.entries()) {
    if (key.startsWith('feature-')) {
      overrides[key.slice(8)] = value === 'true'
    }
  }
  return overrides
}

// Load feature flags from localStorage, environment variables, and URL parameters
// The order of precedence is: URL parameters > localStorage > environment variables
const loadFeatureFlags = (): FeatureFlags => {
  const storedFlags = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')
  return { ...defaultFlags, ...storedFlags, ...getUrlOverrides() }
}

const featureFlagsIntegration = getClient()?.getIntegrationByName<FeatureFlagsIntegration>('FeatureFlags')
export const FeatureFlagContext = createContext<FeatureFlags>(defaultFlags)

export const FeatureFlagProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const flags = useMemo(() => {
    const loadedFeatureFlags = loadFeatureFlags()

    if (!__PROD__) {
      // eslint-disable-next-line no-console
      console.log('Feature flags:', JSON.stringify(loadedFeatureFlags, null, 2))
    }

    // Send feature flags to Sentry
    if (featureFlagsIntegration) {
      Object.entries(loadedFeatureFlags).forEach(([key, value]) => {
        featureFlagsIntegration.addFeatureFlag(key, value)
      })
    }

    return loadedFeatureFlags
  }, [])

  useEffect(() => {
    // Store flags in localStorage
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(flags))
  }, [flags])

  return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>
}
