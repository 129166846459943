import chroma from 'chroma-js'

const valueToHex = (pColor) => {
  const hex = pColor.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export const rgbToHex = (pColor) => {
  return '#' + valueToHex(pColor.r) + valueToHex(pColor.g) + valueToHex(pColor.b)
}

export const generateColorPalette = (colors: string | string[], steps: number) => chroma.scale(colors).colors(steps)
