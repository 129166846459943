import { PaginatedSiteList, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { Earth } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { SUPPORT_EMAIL } from '@/lib/constants/email'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Icons } from '@/components/icons'
import { IndicatorCard } from '@/components/indicator-card'

interface IndicatorsDashboardProps {
  sites: Site[]
  actionsTakenInitiatives?: PaginatedSiteList
  isLoadingSites: boolean
  isLoadingActionsTakenInitiatives: boolean
}

const IndicatorsDashboard = ({
  sites,
  actionsTakenInitiatives,
  isLoadingSites,
  isLoadingActionsTakenInitiatives,
}: IndicatorsDashboardProps) => {
  const { t } = useTranslation()

  const sitesLength = sites.length ?? 0

  const numberOfTakenInitiatives = actionsTakenInitiatives?.results?.features?.reduce(
    (acc, site) => acc + (site?.properties?.actions_taken?.filter((action) => action.status === 'DONE').length ?? 0),
    0
  )
  const totalInitiativeScore = sites?.reduce((acc, site) => acc + (site?.properties?.initiative_score ?? 0), 0)
  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
      <IndicatorCard
        title={t(i18nKeys.beeoinitiative.dashboard.site, { count: sitesLength })}
        icon={<Earth className=" size-8 text-biodivLimeFiveHundred" />}
        value={sitesLength}
        linkText={t(i18nKeys.beeoinitiative.dashboard.seeAllSites)}
        to={getPath(Routes.BEEOINITIATIVE_SITES)}
        isLoading={isLoadingSites}
        skeletonSize="h-[210px]"
      />

      <IndicatorCard
        title={t(i18nKeys.beeoinitiative.dashboard.takenInits.title)}
        value={numberOfTakenInitiatives ?? 0}
        bgColor="bg-white"
        linkText={t(i18nKeys.beeoinitiative.dashboard.takenInits.link)}
        to={getPath(Routes.BEEOINITIATIVE_INITIATIVES)}
        isLoading={isLoadingActionsTakenInitiatives}
        skeletonSize="h-[210px]"
      />

      <IndicatorCard
        title={t(i18nKeys.beeoinitiative.dashboard.totalScore)}
        icon={<Icons.Leaf className="size-8 text-teal-700" />}
        value={totalInitiativeScore}
        bgColor="bg-biodivStoneHundred"
        isLoading={isLoadingSites}
        skeletonSize="h-[210px]"
      />

      <Card className="relative flex flex-col items-center bg-biodivTealSevenHundred text-white">
        <div className="absolute bottom-8 left-40 pt-2 ">
          <Icons.GreenCardLines />
        </div>
        <div className="flex justify-center">
          <Icons.GreenCardIcon size={84} />
        </div>
        <div className="flex flex-col items-center justify-center pb-4 text-sm">
          <p>{t(i18nKeys.beeoinitiative.dashboard.evaluateRisks.description1)}</p>
          <p>{t(i18nKeys.beeoinitiative.dashboard.evaluateRisks.description2)}</p>
        </div>
        <div className="z-10 sm:pb-4">
          <Button variant="outline" className="border-white bg-biodivTealSevenHundred text-sm font-semibold">
            <a href={`mailto:${SUPPORT_EMAIL}?subject=I would like to discover BeeOimpact`}>
              {t(i18nKeys.beeoinitiative.dashboard.evaluateRisks.button)}
            </a>
          </Button>
        </div>
      </Card>
    </div>
  )
}
export default IndicatorsDashboard
