import { isAdmin } from '@/lib/permission'
import { useAuth } from '@/hooks/useAuth'
import AddBomSite from '@/components/add-bom-site'
import AddSite from '@/components/add-site'
import ImportSite from '@/components/import-site'

type SettingsSitesTableActionProps = {
  accountId?: number
  queryKey: readonly string[]
}

const SettingsSitesTableAction = ({ accountId, queryKey }: SettingsSitesTableActionProps) => {
  const { user } = useAuth()

  return (
    <div className="flex flex-row">
      {isAdmin(user) && (
        <div className="flex md:flex-col md:space-y-2 xl:flex-row xl:space-y-0">
          <AddBomSite accountId={accountId} queryKey={queryKey} />
          <ImportSite accountId={accountId} />
          <AddSite accountId={accountId} queryKey={queryKey} />
        </div>
      )}
    </div>
  )
}

export default SettingsSitesTableAction
