import { i18nKeys } from '@/locales/keys'
import { Search } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

const SITE_FILTER_VALUES = {
  ALL: 'all',
  ACTIVE: 'activeSites',
  INACTIVE: 'inactiveSites',
} as const

const SITE_FILTER_TO_QUERY_PARAM: Record<(typeof SITE_FILTER_VALUES)[keyof typeof SITE_FILTER_VALUES], string | null> =
  {
    [SITE_FILTER_VALUES.ACTIVE]: 'true',
    [SITE_FILTER_VALUES.INACTIVE]: 'false',
    [SITE_FILTER_VALUES.ALL]: null,
  }

const QUERY_PARAM_TO_SITE_FILTER: Record<string, (typeof SITE_FILTER_VALUES)[keyof typeof SITE_FILTER_VALUES]> = {
  true: SITE_FILTER_VALUES.ACTIVE,
  false: SITE_FILTER_VALUES.INACTIVE,
  '': SITE_FILTER_VALUES.ALL,
}

const SiteFilter = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const filteredSiteValue = QUERY_PARAM_TO_SITE_FILTER[searchParams.get('activeSite') || ''] || SITE_FILTER_VALUES.ALL
  const nameFilter = searchParams.get('search') ?? ''

  const updateSearchParams = (key: string, value: string | null) => {
    const params = Object.fromEntries(searchParams)
    if (value) {
      params[key] = value
    } else {
      delete params[key]
    }
    setSearchParams(params)
  }

  return (
    <div className="flex flex-row items-center space-x-4">
      <div className="relative flex w-[170px] items-center ">
        <Search className="absolute ml-2 w-4 text-gray-400" />
        <Input
          value={nameFilter}
          type="text"
          placeholder={t(i18nKeys.beeoinitiative.sites.searchSites)}
          onChange={(e) => updateSearchParams('search', e.target.value || null)}
          className="rounded-md border border-separatorGray pl-8  text-biodivZincFiveHundred focus:border-black "
        />
      </div>
      <div className="w-[170px]">
        <Select
          onValueChange={(value: string) => updateSearchParams('activeSite', SITE_FILTER_TO_QUERY_PARAM[value])}
          value={filteredSiteValue}
        >
          <SelectTrigger>
            <SelectValue placeholder={t(i18nKeys.beeoinitiative.sites.display)} />
          </SelectTrigger>
          <SelectContent>
            {Object.values(SITE_FILTER_VALUES).map((filter) => (
              <SelectItem key={filter} value={filter}>
                {t(i18nKeys.beeoinitiative.sites[filter])}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}

export default SiteFilter
