import React from 'react'
import { SensitiveAreaOut, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { SpeciesType } from '@/types/species-type'
import {
  filtercategoryCrEn,
  getCategories,
  getIucnSpeciesCount,
  getNotIucnSpeciesCount,
  getUniqueSpecies,
  SubSpecies,
} from '@/lib/n2k'
import { useLanguage } from '@/hooks/useLanguage'
import { Badge } from '@/components/ui/badge'
import { Card } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import SpeciesCards from '@/components/beeoimpact/impacts/species-cards'
import CardAnalytics from '@/components/card-analytics'
import { DataTablePagination } from '@/components/data-table/data-table-pagination'
import { Icons } from '@/components/icons'
import { SpeciesIcon } from '@/components/icons-components/active-matters-icons'
import RenderTooltip from '@/components/render-tooltip/render-tooltip'

type RowIdType = {
  id: number
  protectedAreaId: number
  name: string
  vernacular_name: string
}

type MixedSpecies = SubSpecies & RowIdType

interface SpeciesTableProps {
  filtering: string
  setFiltering: (filter: string) => void
  sensitiveAreas: SensitiveAreaOut[]
  site: Site
}

const SpeciesTable = ({ filtering, setFiltering, sensitiveAreas, site }: SpeciesTableProps) => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const iucnColors = {
    LC: 'bg-green-400',
    NT: 'bg-green-700',
    VU: 'bg-yellow-500',
    EN: 'bg-orange-500',
    CR: 'bg-ruby hover:bg-ruby-hover',
  }

  const uniqueSpecies = getUniqueSpecies(sensitiveAreas)
  const categories = getCategories(uniqueSpecies)

  const columns: ColumnDef<MixedSpecies>[] = [
    {
      id: 'name',
      header: () => `${t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name)}`,
      cell: ({ row }) => {
        return (
          <div className="flex flex-col">
            <span className="max-w-[500px] truncate font-medium">{row.original.name}</span>
            <span className="max-w-[500px] truncate text-xs text-muted-foreground">
              {(row.original as RowIdType).vernacular_name}
            </span>
          </div>
        )
      },
    },
    {
      id: 'group',
      header: () => `${t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.type)}`,
      cell: ({ row }) => row.original.group,
    },
  ]

  const filteredUniqueSpeciesCrEn = filtercategoryCrEn(uniqueSpecies)

  const data = React.useMemo(() => filteredUniqueSpeciesCrEn, [language])
  const table = useReactTable({
    data: data,
    columns,
    enableFilters: true,
    enableColumnFilters: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      globalFilter: filtering,
    },
    onGlobalFilterChange: setFiltering,
  })

  const applyBorder = (key) => ['VU', 'EN', 'CR'].includes(key)

  const iucnSpeciesSum = getIucnSpeciesCount(uniqueSpecies)

  const otherStatusSum = getNotIucnSpeciesCount(uniqueSpecies)

  return (
    <>
      {filteredUniqueSpeciesCrEn.length === 0 && uniqueSpecies.length === 0 ? (
        <div className="container flex h-80 items-center justify-center rounded-md border border-dashed">
          <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
            <Icons.Squirrel size={42} />
            <h3 className="mt-4 text-lg font-semibold">
              {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.noSpecies)}
            </h3>
            <div className="mb-4 mt-2 text-sm text-muted-foreground">
              <div className="w-max">
                {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.noPrioritySpecies)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col items-center justify-center space-y-20 text-lg">
          <div className="flex w-full flex-col items-center justify-start rounded-md pb-8">
            <div className="flex w-full flex-col">
              <div className="mt-4 flex flex-col">
                <p className="pb-2">{t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.invasiveSpecies)}</p>
                <div className="mb-4  grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                  <CardAnalytics
                    icon={
                      <SpeciesIcon
                        value={
                          site.properties?.stats_biodiversity?.invasive_alien_species_in_sensitive_areas?.animals ?? 0
                        }
                        species={SpeciesType.Squirrel}
                      />
                    }
                    value={site.properties?.stats_biodiversity?.invasive_alien_species_in_sensitive_areas?.animals ?? 0}
                    label={t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.animal)}
                  />
                  <CardAnalytics
                    icon={
                      <SpeciesIcon
                        value={
                          site.properties?.stats_biodiversity?.invasive_alien_species_in_sensitive_areas?.plants ?? 0
                        }
                        species={SpeciesType.Plant}
                      />
                    }
                    value={site.properties?.stats_biodiversity?.invasive_alien_species_in_sensitive_areas?.plants ?? 0}
                    label={t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.plant)}
                  />
                </div>
              </div>
              <div className="mb-2 flex flex-row items-center">
                <h3>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.taxonsArea)} :</h3>
                <h3>
                  <span className="pl-1 font-semibold">{iucnSpeciesSum} </span>
                  {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.taxonsArea2)}{' '}
                  <span className=" font-semibold"> {otherStatusSum} </span>{' '}
                  {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.taxonsArea3)}
                </h3>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row flex-wrap justify-start">
                  <SpeciesCards categories={categories} />
                </div>
                <div className="text-xs">
                  <Card className="flex w-max flex-col justify-center space-y-2 p-2">
                    <div className="flex h-10 flex-row items-center gap-x-2 font-bold">
                      {' '}
                      {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.table.iucn)}
                      <RenderTooltip
                        size={20}
                        explanationText={t(
                          i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.table.iucnstatusDefinition
                        )}
                      />
                    </div>
                    <div className="flex flex-row ">
                      <p className="flex items-center justify-center pr-2 pt-14">
                        {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.threatened)}
                      </p>
                      <div className="flex flex-col space-y-2 ">
                        {Object.keys(iucnColors).map((key) => (
                          <span key={key} className={`flex flex-row space-x-2`}>
                            <span className={`${applyBorder(key) && '-mb-2 border'}`}></span>
                            <Badge className={iucnColors[key]}>{key}</Badge>
                            <p className="flex flex-row space-x-4 text-xs">
                              {t(i18nKeys.beeoimpact.biodiversityTable.iucn[key])}
                            </p>
                          </span>
                        ))}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              {filteredUniqueSpeciesCrEn.length !== 0 ? (
                <div>
                  <h3 className="mb-2 ">
                    {' '}
                    {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.table.prioritySpecies)}
                  </h3>
                  <div className="mb-4 flex w-64 flex-row items-center space-x-4  text-sm">
                    <Input
                      value={filtering}
                      onChange={(e) => setFiltering(e.target.value)}
                      placeholder={t(i18nKeys.global.common.datatable.filter)}
                    />
                  </div>
                  <div>
                    <Table className="border">
                      <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <TableHead key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </TableHead>
                            ))}
                          </TableRow>
                        ))}
                      </TableHeader>
                      <TableBody>
                        {table.getRowModel().rows.map((row) => {
                          return (
                            <TableRow key={row.id}>
                              {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </div>
                  <div className="mt-4">
                    <DataTablePagination table={table} />
                  </div>
                </div>
              ) : (
                <div className="container mt-4 flex h-80 items-center justify-center rounded-md border border-dashed">
                  <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
                    <Icons.Squirrel size={42} />
                    <h3 className="mt-4 w-max text-lg font-semibold">
                      {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.noSpecies2)}
                    </h3>
                    <div className="mb-4 mt-2 text-sm text-muted-foreground">
                      <div className="w-max">
                        {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.noPrioritySpecies)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default SpeciesTable
