import { useMemo } from 'react'
import { i18nKeys } from '@/locales/keys'
import { ResponsivePie } from '@nivo/pie'
import { useTranslation } from 'react-i18next'

import { CultureArea } from '@/types/culture-area'
import { generateColorPalette } from '@/lib/colors'
import { COLORS } from '@/lib/constants/constants'
import { calculatePercentage } from '@/lib/math'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

interface CulturesPieChartProps {
  cultures: CultureArea[]
}

interface ChartItem {
  id: string
  label: string
  value: number
}

const getFormattedPercentage = (ratio: number) => `${(ratio * 100).toFixed(2)}%`

const CulturesPieChart = ({ cultures }: CulturesPieChartProps) => {
  const { t } = useTranslation()

  const pieData = useMemo(() => {
    const totalValue = cultures.reduce((acc, culture) => acc + culture.total_surface, 0)

    const { groupedCultures, othersPercentage } = cultures.reduce(
      (acc, culture) => {
        const percentage = calculatePercentage(culture.total_surface, totalValue)
        if (percentage >= 0.01) {
          acc.groupedCultures.push({
            id: culture.name,
            label: culture.name,
            value: percentage,
          })
        } else {
          acc.othersPercentage += percentage
        }

        return acc
      },
      { groupedCultures: new Array<ChartItem>(), othersPercentage: 0 }
    )

    if (othersPercentage > 0) {
      groupedCultures.push({
        id: 'others',
        label: t(i18nKeys.beeoimpact.pesticidesTable.cultures.pie.others),
        value: othersPercentage,
      })
    }

    return groupedCultures
  }, [cultures])

  const colors = useMemo(() => generateColorPalette(COLORS, pieData.length), [pieData.length])

  return (
    <Card className="mt-12 flex min-h-[80px] w-full min-w-0 flex-col">
      <CardHeader>
        <CardTitle> {t(i18nKeys.beeoimpact.pesticidesTable.cultures.pie.title)}</CardTitle>
        <CardDescription>{t(i18nKeys.beeoimpact.pesticidesTable.cultures.pie.description)}</CardDescription>
      </CardHeader>

      <CardContent>
        <section className="h-96">
          <ResponsivePie
            data={pieData}
            startAngle={-180}
            margin={{ top: 20, right: 20, bottom: 40, left: 0 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            colors={colors}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.2]],
            }}
            valueFormat={getFormattedPercentage}
            enableArcLinkLabels={true}
            enableArcLabels={false}
            arcLinkLabel={(e) => getFormattedPercentage(e.value)}
            arcLabelsRadiusOffset={1.3}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [['darker', 2]],
            }}
            fill={[
              {
                match: {
                  id: 'ignored',
                },
                id: 'dots',
              },
            ]}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
          />
        </section>
        <section>
          <ul className="flex flex-col gap-1">
            {pieData.map((culture, index) => (
              <li key={culture.id} className="flex items-start">
                <span className="mr-2 mt-1 size-4 shrink-0" style={{ backgroundColor: colors[index] }}></span>
                <span className="text-sm">{culture.label}</span>
              </li>
            ))}
          </ul>
        </section>
      </CardContent>
    </Card>
  )
}

export default CulturesPieChart
