import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import GreenCardSmallCard from '@/pages/beeoinitiative/site-detail/green-card-small-card'
import { useTranslation } from 'react-i18next'

import { SUPPORT_EMAIL } from '@/lib/constants/email'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { Icons } from '@/components/icons'

interface GreenCardProps {
  site?: Site
  isLoading: boolean
}
const GreenCard = ({ site, isLoading }: GreenCardProps) => {
  const { t } = useTranslation()

  const siteIsAtRisk = site?.properties?.stats_pesticides?.is_at_risk
  const numberOfCRandENspecies =
    (site?.properties?.stats_biodiversity?.nb_species_in?.CR ?? 0) +
    (site?.properties?.stats_biodiversity?.nb_species_in?.EN ?? 0)

  if (isLoading) {
    return <Skeleton className="h-[460px] w-full" />
  }
  return (
    <Card className="mb-4 flex h-[460px] flex-col items-center bg-biodivTealSevenHundred text-white md:max-w-[696px] md:px-8 md:py-9 lg:max-w-full 2xl:px-10 2xl:py-9">
      <p className="pb-3 text-xl font-semibold">{t(i18nKeys.beeoinitiative.site.greenCard.siteAnalyse)}</p>
      <div className="flex flex-col items-center justify-center pb-5 text-sm">
        <p>{t(i18nKeys.beeoinitiative.site.greenCard.firstSentence)}</p>
        <p>{t(i18nKeys.beeoinitiative.site.greenCard.secondSentence)}</p>
      </div>
      <div className="pb-12">
        <Button variant="outline" className="bg-biodivTealSevenHundred text-sm font-semibold">
          <a href={`mailto:${SUPPORT_EMAIL}?subject=?subject=I would like to discover BeeOimpact`}>
            {t(i18nKeys.beeoinitiative.dashboard.evaluateRisks.button)}
          </a>
        </Button>
      </div>
      <div className="flex space-x-4">
        <GreenCardSmallCard
          icon={<Icons.AlertTriangle className="text-red-500" />}
          text={site?.properties?.name ?? ''}
          text1={
            siteIsAtRisk
              ? t(i18nKeys.beeoinitiative.site.greenCard.isConsidered)
              : t(i18nKeys.beeoinitiative.site.greenCard.isNotConsidered)
          }
          text2={t(i18nKeys.beeoinitiative.site.greenCard.riskySite2)}
        />
        <GreenCardSmallCard
          icon={<Icons.Squirrel className="text-orange-500" />}
          text={numberOfCRandENspecies + ' ' + t(i18nKeys.beeoinitiative.site.greenCard.species)}
          text2={t(i18nKeys.beeoinitiative.site.greenCard.endageredSpecies)}
          text3={' ' + t(i18nKeys.beeoinitiative.site.greenCard.yourSite)}
        />
      </div>
    </Card>
  )
}

export default GreenCard
