import { useState } from 'react'
import {
  getSitesRetrieveQueryKey,
  getSitesSensitiveAreaListQueryKey,
  sitesRetrieve,
  sitesSensitiveAreaList,
} from '@/client/backend/api/sites/sites'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { SUPPORT_EMAIL } from '@/lib/constants/email'
import { getSiteCenter } from '@/lib/geo'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import SensitiveAreasMap from '@/components/beeoimpact/impacts/sensitive-areas-map'
import CallToACtion from '@/components/beeoimpact/sites/cta'
import SensitiveAreasTable from '@/components/beeoimpact/tables/sensitive-areas-table'
import SpeciesTable from '@/components/beeoimpact/tables/species-table'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import RenderTooltip from '@/components/render-tooltip/render-tooltip'
import { H3 } from '@/components/typography'

const SensitiveAreasPage = () => {
  const { t } = useTranslation()

  const { siteId } = useParams<{ siteId: string }>()
  if (!siteId) throw new Error('siteId is required')

  const { data: site } = useQuery({
    queryFn: () => sitesRetrieve(siteId),
    queryKey: [getSitesRetrieveQueryKey(siteId)],
  })

  const { data: sensitiveAreas } = useQuery({
    queryKey: [getSitesSensitiveAreaListQueryKey(siteId)],
    queryFn: () => sitesSensitiveAreaList(siteId),
  })

  const center = site ? getSiteCenter(site) : null

  const [filtering, setFiltering] = useState<string>('')

  return (
    <div className="container relative my-8">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoimpact),
          },
          {
            label: t(i18nKeys.beeoimpact.common.breadcrumb.sites),
            href: getPath(Routes.BEEOIMPACT_SITES),
          },
          {
            label: site?.properties?.name ?? t(i18nKeys.beeoimpact.common.breadcrumb.site),
            href: getPath(Routes.BEEOIMPACT_SITE, { params: { siteId: site?.id as number } }),
          },
        ]}
        currentPage={t(i18nKeys.beeoimpact.common.breadcrumb.biodiversity)}
      />
      <div className="mb-4 flex flex-col justify-between">
        <PageHeader>
          <PageHeaderHeading>{t(i18nKeys.beeoimpact.common.breadcrumb.biodiversity)}</PageHeaderHeading>
        </PageHeader>
        <div className="pb-4">
          <CallToACtion
            href={`mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(t(i18nKeys.beeoimpact.site.cta.subject, { siteName: site?.properties?.name }))}`}
          />
        </div>
      </div>
      {site && (
        <div className="flex flex-col justify-center ">
          <div>
            {center && (
              <SensitiveAreasMap
                sensitiveAreas={sensitiveAreas}
                radius={site?.properties?.biodiversity_radius}
                center={center}
                fullGeom={true}
              />
            )}
          </div>
          <div className="mb-8">
            <div className="mb-4 flex flex-row items-end space-x-2">
              <H3>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.sensitiveAreas)}</H3>
              <RenderTooltip
                size={24}
                explanationText={t(
                  i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.sensitiveAreasDefinition
                )}
              />
            </div>
            <Separator />
          </div>

          <div className="my-8">
            <SensitiveAreasTable
              sensitiveAreas={sensitiveAreas ?? []}
              biodiversityRadius={site?.properties?.biodiversity_radius ?? 1500}
            />
          </div>

          <div className="my-8">
            <H3 className="mb-4 leading-none">
              {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.table.species)}
            </H3>
            <Separator />
          </div>

          <Card className="w-full min-w-0">
            <CardContent>
              {site && (
                <SpeciesTable
                  sensitiveAreas={sensitiveAreas ?? []}
                  filtering={filtering}
                  setFiltering={setFiltering}
                  site={site}
                />
              )}
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  )
}

export default SensitiveAreasPage
