import Markdown from 'react-markdown'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'

interface InitiativeDetailDescriptionProps {
  bgColor: string
  description?: string | null
  borderColor?: string
  isLoading?: boolean
  size?: string
  title?: string
  icon?: React.ReactNode
  iconColor?: string
  implementationTitle?: string
}

const InitiativeDetailDescription = ({
  bgColor,
  description,
  borderColor,
  isLoading,
  size,
  title,
  icon,
  iconColor,
  implementationTitle,
}: InitiativeDetailDescriptionProps) => {
  if (isLoading) {
    return <Skeleton className={size} />
  }

  const renderCardsFromMarkdown = (description: string | null) => {
    if (!description) return null

    // Split description into sections by `##` headers
    const markdownSections = description.split(/(?=## )/)

    return markdownSections.map((section, index) => {
      return (
        <Card key={index} className={`drop-shadow-sm ${bgColor} ${borderColor} mb-6`}>
          {title ? (
            <CardHeader className="pt-8">
              <div className="flex justify-between">
                <CardTitle>{title}</CardTitle>
                {icon && <div className={`text-3xl ${iconColor}`}>{icon}</div>}
              </div>
            </CardHeader>
          ) : (
            <div className="py-4"></div>
          )}

          <CardContent className="px-6 pb-8">
            <Markdown
              components={{
                h2(props) {
                  const children = props.children as string
                  const parts = children.trim().split(/\s+/) // Split into parts
                  const number = parts[0] // First part is the number
                  const text = parts.slice(1).join(' ') // Combine the rest as text

                  return (
                    <div className="mb-6 flex items-center">
                      <div className="text-lg font-bold text-biodivLimeFiveHundred">{number}</div>
                      <Separator className="mx-3 h-7" orientation="vertical" />
                      <h2 className="text-lg font-bold text-biodivTealSevenHundred">{text}</h2>
                    </div>
                  )
                },
                ul(props) {
                  return (
                    <ul className="list-disc space-y-4 pl-6 marker:text-biodivLimeFiveHundred">{props.children}</ul>
                  )
                },
                li(props) {
                  return <li className="text-base text-black">{props.children}</li>
                },
              }}
            >
              {section}
            </Markdown>
          </CardContent>
        </Card>
      )
    })
  }

  return (
    <div>
      {implementationTitle && <h2 className="mb-4 pl-4 text-xl font-semibold">{implementationTitle}</h2>}
      <div>{description && renderCardsFromMarkdown(description)}</div>
    </div>
  )
}

export default InitiativeDetailDescription
