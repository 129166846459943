import { useEffect, useMemo, useState } from 'react'
import {
  beeomonitoringGraphsCountVegetalsByPeriodList,
  beeomonitoringGraphsDiofurValuesByPeriodList,
  beeomonitoringGraphsHeavymetalCountByPeriodList,
  beeomonitoringGraphsPahValuesByPeriodList,
  beeomonitoringGraphsPcbValuesByPeriodList,
  beeomonitoringGraphsPestCountByPeriodList,
  getBeeomonitoringGraphsCountVegetalsByPeriodListQueryKey,
  getBeeomonitoringGraphsDiofurValuesByPeriodListQueryKey,
  getBeeomonitoringGraphsHeavymetalCountByPeriodListQueryKey,
  getBeeomonitoringGraphsPahValuesByPeriodListQueryKey,
  getBeeomonitoringGraphsPcbValuesByPeriodListQueryKey,
  getBeeomonitoringGraphsPestCountByPeriodListQueryKey,
} from '@/client/backend/api/graphs/graphs'
import {
  beeomonitoringSitesSiteInfosRetrieve,
  getBeeomonitoringSitesSiteInfosRetrieveQueryKey,
} from '@/client/backend/api/sites/sites'
import { BarChartByYear } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { LatLng } from 'leaflet'
import { MapPin, Target } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Circle, MapContainer, TileLayer } from 'react-leaflet'
import { useParams } from 'react-router'

import { BarChartValueWithColor } from '@/types/graph'
import { DEFAULT_RADIUS } from '@/lib/geo'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import CardMarketing from '@/components/card-marketing'
import CardStatsBarchart from '@/components/card-stats-barchart'
import CardStatsBarchartSkeleton from '@/components/card-stats-barchart-skeleton'
import { default as ErrorComponent } from '@/components/error'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import { H3 } from '@/components/typography'

const SiteDetailMonitoringPage = () => {
  const { t } = useTranslation()

  const [selectedYear, setSelectedYear] = useState('2023')
  const [years, setYears] = useState<string[]>(['2021', '2022', '2023'])

  const [biodiversityDataByPeriods, setBiodiversityDataByPeriods] = useState<BarChartValueWithColor[]>([])
  const [biodiversityDataByYear, setBiodiversityDataByYear] = useState<number>(0)

  const [metalsDataByPeriods, setMetalsDataByPeriods] = useState<BarChartValueWithColor[]>([])
  const [metalsDataByYear, setMetalsDataByYear] = useState<number>(0)

  const [pesticidesDataByPeriods, setPesticidesDataByPeriods] = useState<BarChartValueWithColor[]>([])
  const [pesticidesDataByYear, setPesticidesDataByYear] = useState<number>(0)

  const [pahDataByPeriods, setPahsDataByPeriods] = useState<BarChartValueWithColor[]>([])
  const [pcbDataByPeriods, setPcbDataByPeriods] = useState<BarChartValueWithColor[]>([])
  const [diofurDataByPeriods, setDiofurDataByPeriods] = useState<BarChartValueWithColor[]>([])

  const { siteId } = useParams<{ siteId: string }>()
  if (!siteId) throw new Error('siteId is required')

  const {
    data: site,
    isLoading: isLoadingSite,
    isError,
  } = useQuery({
    queryKey: [getBeeomonitoringSitesSiteInfosRetrieveQueryKey(siteId)],
    queryFn: () => beeomonitoringSitesSiteInfosRetrieve(siteId, undefined),
  })

  const { data: dataCountVegetalsByPeriod, isLoading: isLoadingCountByPeriod } = useQuery({
    queryKey: [getBeeomonitoringGraphsCountVegetalsByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsCountVegetalsByPeriodList(siteId, undefined),
  })

  const { data: dataCountMetalsByPeriod, isLoading: isLoadingCountMetalsByPeriod } = useQuery({
    queryKey: [getBeeomonitoringGraphsHeavymetalCountByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsHeavymetalCountByPeriodList(siteId, undefined),
  })

  const { data: dataCountPesticidesByPeriod, isLoading: isLoadingCountPesticidesByPeriod } = useQuery({
    queryKey: [getBeeomonitoringGraphsPestCountByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsPestCountByPeriodList(siteId, undefined),
  })

  const { data: dataValuesPahsByPeriod, isLoading: isLoadingPahsByPeriod } = useQuery({
    queryKey: [getBeeomonitoringGraphsPahValuesByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsPahValuesByPeriodList(siteId, undefined),
  })

  const { data: dataValuesPcbByPeriod, isLoading: isLoadingPcbByPeriod } = useQuery({
    queryKey: [getBeeomonitoringGraphsPcbValuesByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsPcbValuesByPeriodList(siteId, undefined),
  })

  const { data: dataValuesDiofurByPeriod, isLoading: isLoadingDiofurByPeriod } = useQuery({
    queryKey: [getBeeomonitoringGraphsDiofurValuesByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsDiofurValuesByPeriodList(siteId, undefined),
  })

  const allYears = useMemo(() => {
    const yearsVegetals = dataCountVegetalsByPeriod?.map((item) => item.year) || []
    const yearsMetals = dataCountMetalsByPeriod?.map((item) => item.year) || []
    const yearsPesticides = dataCountPesticidesByPeriod?.map((item) => item.year) || []

    const yearsPahs = dataValuesPahsByPeriod?.map((item) => item.year) || []
    const yearsPcb = dataValuesPcbByPeriod?.map((item) => item.year) || []
    const yearsDiofur = dataValuesDiofurByPeriod?.map((item) => item.year) || []

    return Array.from(
      new Set([...yearsVegetals, ...yearsMetals, ...yearsPesticides, ...yearsPahs, ...yearsPcb, ...yearsDiofur])
    ).sort()
  }, [
    dataCountVegetalsByPeriod,
    dataCountMetalsByPeriod,
    dataCountPesticidesByPeriod,
    dataValuesPahsByPeriod,
    dataValuesPcbByPeriod,
    dataValuesDiofurByPeriod,
  ])

  useEffect(() => {
    if (allYears.length > 0) {
      setYears(allYears)
      setSelectedYear(allYears[allYears.length - 1])
    }
  }, [allYears])

  useEffect(() => {
    processBarChartData(
      dataCountVegetalsByPeriod,
      selectedYear,
      COLORS.vegetals,
      setBiodiversityDataByPeriods,
      setBiodiversityDataByYear
    )

    processBarChartData(
      dataCountMetalsByPeriod,
      selectedYear,
      COLORS.metals,
      setMetalsDataByPeriods,
      setMetalsDataByYear
    )

    processBarChartData(
      dataCountPesticidesByPeriod,
      selectedYear,
      COLORS.pesticides,
      setPesticidesDataByPeriods,
      setPesticidesDataByYear
    )

    processBarChartSubstanceData(dataValuesPahsByPeriod, selectedYear, COLORS.pah, setPahsDataByPeriods)
    processBarChartSubstanceData(dataValuesPcbByPeriod, selectedYear, COLORS.pcb, setPcbDataByPeriods)
    processBarChartSubstanceData(dataValuesPcbByPeriod, selectedYear, COLORS.diofur, setDiofurDataByPeriods)
  }, [
    dataCountVegetalsByPeriod,
    dataCountMetalsByPeriod,
    dataCountPesticidesByPeriod,
    dataValuesPahsByPeriod,
    dataValuesPcbByPeriod,
    selectedYear,
  ])

  const center = { lat: site?.lat ?? 0, lng: site?.lon ?? 0 } as LatLng

  return (
    <div className="container relative my-8">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: 'beeomonitoring',
          },
          {
            label: t(i18nKeys.beeoinitiative.common.breadcrumb.sites),
            href: getPath(Routes.BEEOMONITORING_SITES),
          },
        ]}
        currentPage={site?.name}
      />

      {isError ? (
        <ErrorComponent
          status={503}
          title={t(i18nKeys.beeomonitoring.siteDetail.error.title)}
          description={t(i18nKeys.beeomonitoring.siteDetail.error.description)}
        />
      ) : (
        <>
          <div className="flex flex-col justify-between">
            <div className="flex items-center justify-between space-y-2">
              <PageHeader>
                <PageHeaderHeading>
                  {isLoadingSite ? (
                    <div className="flex items-end justify-start">
                      <Skeleton className="mr-2 h-[34px] w-[400px]" />
                      <Skeleton className="h-[24px] w-[80px]" />
                    </div>
                  ) : (
                    site && (
                      <div>
                        {site.name} <span className="text-sm font-medium text-muted-foreground"># {site.key}</span>
                      </div>
                    )
                  )}
                </PageHeaderHeading>
                <div className="flex flex-row">
                  {isLoadingSite ? (
                    <>
                      <div>
                        <Skeleton className="h-[22px] w-[62px]" />
                      </div>

                      <div>
                        <Skeleton className="ml-2 h-[22px] w-[110px]" />
                      </div>
                    </>
                  ) : (
                    site?.tags?.map((tag) => (
                      <Badge key={tag} className="mr-4 rounded-none bg-muted text-foreground hover:bg-foreground/10">
                        {tag}
                      </Badge>
                    ))
                  )}
                </div>
              </PageHeader>

              <div className="flex items-center space-x-2">
                {isLoadingSite ||
                isLoadingCountByPeriod ||
                isLoadingCountMetalsByPeriod ||
                isLoadingCountPesticidesByPeriod ? (
                  <Skeleton className="h-10 w-44" />
                ) : (
                  <Select value={selectedYear} onValueChange={(e) => setSelectedYear(e)}>
                    <SelectTrigger className="w-44">
                      <SelectValue defaultValue={selectedYear} placeholder={selectedYear} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {years.map((year) => (
                          <SelectItem key={year} value={year}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            {isLoadingSite ? (
              <>
                <Card className="w-2/3">
                  <CardHeader>
                    <div>
                      <Skeleton className="h-6 w-52" />
                    </div>
                  </CardHeader>
                  <CardContent>
                    <div className="flex flex-col justify-evenly  overflow-auto rounded-r-md ">
                      {Array.from({ length: 3 }).map((_, index) => (
                        <div className="flex flex-row space-x-4 [&:not(:last-child)]:mb-2" key={index}>
                          <Skeleton className="size-6" />
                          <div>
                            <Skeleton className="h-6 w-52" />
                            <Skeleton className="mt-2 h-6 w-12" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
                <Card className="ml-4 w-1/3 border-none">
                  <Skeleton className="z-0 h-96 rounded-2xl sm:h-48 md:h-[300px] xl:h-[300px]" />
                </Card>
              </>
            ) : (
              <>
                <Card className="w-2/3">
                  <CardHeader>
                    <CardTitle>{t(i18nKeys.beeomonitoring.siteDetail.general.title)}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex flex-col justify-evenly space-y-4 overflow-auto rounded-r-md ">
                      <div className="flex flex-row space-x-4">
                        <Target className="text-muted-foreground" />
                        <div>
                          <p>{site?.name}</p>
                          <p className="text-muted-foreground">{t(i18nKeys.beeomonitoring.siteDetail.general.name)}</p>
                        </div>
                      </div>
                      <div className="flex flex-row space-x-4">
                        <MapPin />
                        <div>
                          <p>{site?.lon}</p>
                          <p className="text-muted-foreground">
                            {t(i18nKeys.beeomonitoring.siteDetail.general.longitude)}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-row space-x-4">
                        <MapPin />
                        <div>
                          <p>{site?.lat}</p>
                          <p className="text-muted-foreground">
                            {t(i18nKeys.beeomonitoring.siteDetail.general.latitude)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card className="ml-4 w-1/3 border-none">
                  <div>
                    <MapContainer
                      className="3xl:h-[400px] z-0 h-96 rounded-2xl hover:cursor-pointer sm:h-48 md:h-[300px] xl:h-[300px]"
                      center={center}
                      zoom={12}
                      dragging={true}
                      scrollWheelZoom={false}
                      attributionControl={false}
                    >
                      {center && (
                        <Circle center={center} color="blue" fillColor="none" weight={1} radius={DEFAULT_RADIUS} />
                      )}

                      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    </MapContainer>
                  </div>
                </Card>
              </>
            )}
          </div>
          <div className="my-10">
            <div className="my-8">
              <H3>{t(i18nKeys.beeomonitoring.siteDetail.content.general.title)}</H3>
              <p className="pb-4 text-sm text-muted-foreground">
                {t(i18nKeys.beeomonitoring.siteDetail.content.general.description)}
              </p>
              <Separator />
            </div>
            <div className="mt-4 grid gap-2 md:grid-cols-2 md:gap-4 lg:grid-cols-3">
              {isLoadingCountByPeriod || isLoadingSite ? (
                <CardStatsBarchartSkeleton />
              ) : site?.order_types.includes('BIO') || site?.order_types.includes('DNA') ? (
                <CardStatsBarchart
                  title={t(i18nKeys.beeomonitoring.siteDetail.cards.biodiversity)}
                  value={biodiversityDataByYear.toString()}
                  data={biodiversityDataByPeriods}
                  height="h-20"
                  colors={[COLORS[4]]}
                  keys={['local_value']}
                  indexBy="id"
                  to={getPath(Routes.BEEOMONITORING_SITE_BIODIVERSITY, {
                    params: { siteId: site?.id },
                    queryParams: {
                      year: selectedYear,
                    },
                  })}
                />
              ) : (
                <CardMarketing title={t(i18nKeys.beeomonitoring.siteDetail.cards.marketing.biodiversity)} />
              )}

              {isLoadingCountPesticidesByPeriod || isLoadingSite ? (
                <CardStatsBarchartSkeleton />
              ) : site?.order_types.includes('PEST') ? (
                <CardStatsBarchart
                  title={t(i18nKeys.beeomonitoring.siteDetail.cards.pesticides)}
                  value={pesticidesDataByYear.toString()}
                  data={pesticidesDataByPeriods}
                  height="h-20"
                  colors={[COLORS[1]]}
                  keys={['local_value']}
                  indexBy="id"
                  to={getPath(Routes.BEEOMONITORING_SITE_PESTICIDES, {
                    params: { siteId: site?.id },
                    queryParams: {
                      year: selectedYear,
                    },
                  })}
                />
              ) : (
                <CardMarketing title={t(i18nKeys.beeomonitoring.siteDetail.cards.marketing.pesticides)} />
              )}

              {isLoadingCountMetalsByPeriod || isLoadingSite ? (
                <CardStatsBarchartSkeleton />
              ) : site?.order_types.includes('HM') ? (
                <CardStatsBarchart
                  title={t(i18nKeys.beeomonitoring.siteDetail.cards.metals)}
                  value={metalsDataByYear.toString()}
                  data={metalsDataByPeriods}
                  height="h-20"
                  colors={[COLORS[9]]}
                  keys={['local_value']}
                  indexBy="id"
                  to={getPath(Routes.BEEOMONITORING_SITE_METALS, {
                    params: { siteId: site?.id },
                    queryParams: {
                      year: selectedYear,
                    },
                  })}
                />
              ) : (
                <CardMarketing title={t(i18nKeys.beeomonitoring.siteDetail.cards.marketing.metals)} />
              )}
            </div>
          </div>
          <div className="my-10">
            <div className="my-8">
              <H3>{t(i18nKeys.beeomonitoring.siteDetail.content.other.title)}</H3>
              <p className="pb-4 text-sm text-muted-foreground">
                {t(i18nKeys.beeomonitoring.siteDetail.content.other.description)}
              </p>
              <Separator />
            </div>
            <div className="mt-4 grid gap-2 md:grid-cols-2 md:gap-4 lg:grid-cols-3">
              {isLoadingPahsByPeriod || isLoadingSite ? (
                <CardStatsBarchartSkeleton />
              ) : site?.order_types?.some((type) => ['PAH', 'DNA'].includes(type)) && pahDataByPeriods.length !== 0 ? (
                <CardStatsBarchart
                  title={t(i18nKeys.beeomonitoring.siteDetail.cards.pah)}
                  data={pahDataByPeriods}
                  height="h-20"
                  colors={[COLORS[4]]}
                  keys={['local_value']}
                  indexBy="id"
                  to={getPath(Routes.BEEOMONITORING_SITE_PAH_POLLUANTS, {
                    params: { siteId: site?.id },
                    queryParams: {
                      year: selectedYear,
                    },
                  })}
                />
              ) : (
                <CardMarketing title={t(i18nKeys.beeomonitoring.siteDetail.cards.marketing.pah)} />
              )}

              {isLoadingPcbByPeriod || isLoadingSite ? (
                <CardStatsBarchartSkeleton />
              ) : site?.order_types?.some((type) => ['PCB', 'PCDFMix'].includes(type)) &&
                pcbDataByPeriods.length !== 0 ? (
                <CardStatsBarchart
                  title={t(i18nKeys.beeomonitoring.siteDetail.cards.pcb)}
                  data={pcbDataByPeriods}
                  height="h-20"
                  colors={[COLORS[1]]}
                  keys={['local_value']}
                  indexBy="id"
                  to={getPath(Routes.BEEOMONITORING_SITE_PCB_POLLUANTS, {
                    params: { siteId: site?.id },
                    queryParams: {
                      year: selectedYear,
                    },
                  })}
                />
              ) : (
                <CardMarketing title={t(i18nKeys.beeomonitoring.siteDetail.cards.marketing.pcb)} />
              )}

              {isLoadingDiofurByPeriod || isLoadingSite ? (
                <CardStatsBarchartSkeleton />
              ) : site?.order_types.includes('PCDFMix') && diofurDataByPeriods.length !== 0 ? (
                <CardStatsBarchart
                  title={t(i18nKeys.beeomonitoring.siteDetail.cards.dioxin)}
                  data={diofurDataByPeriods}
                  height="h-20"
                  colors={[COLORS[9]]}
                  keys={['local_value']}
                  indexBy="id"
                  to={getPath(Routes.BEEOMONITORING_SITE_DIOXINES_POLLUANTS, {
                    params: { siteId: site?.id },
                    queryParams: {
                      year: selectedYear,
                    },
                  })}
                />
              ) : (
                <CardMarketing title={t(i18nKeys.beeomonitoring.siteDetail.cards.marketing.dioxin)} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SiteDetailMonitoringPage

const COLORS = {
  vegetals: '#9966FF',
  metals: '#FFCD56',
  pesticides: '#FF6384',
  pah: '#36A2EB',
  pcb: '#FF9F40',
  diofur: '#4BC0C0',
}

const processBarChartData = (
  data: BarChartByYear[] | undefined,
  selectedYear: string,
  color: string,
  setPeriodsData: React.Dispatch<React.SetStateAction<BarChartValueWithColor[]>>,
  setDataByYear: React.Dispatch<React.SetStateAction<number>>
) => {
  if (!data) {
    setPeriodsData([])
    setDataByYear(0)
    return
  }

  const yearData = data.find((item) => item.year === selectedYear)

  if (!yearData) {
    setPeriodsData([])
    setDataByYear(0)
    return
  }

  const periodsData = yearData.periods.map((period) => {
    const record = period.items.find((item) => {
      return item.local_value !== undefined && item.local_value !== null
    })
    const periodKey = period.key
    const id = parseInt(periodKey.replace(/[^\d]/g, '')) - 1
    return {
      ...record,
      color,
      id,
      period: periodKey,
    } as BarChartValueWithColor
  })

  setPeriodsData(periodsData)

  const totalValue = periodsData.reduce((acc, cur) => acc + cur.local_value, 0)
  setDataByYear(totalValue)
}

const processBarChartSubstanceData = (
  data: BarChartByYear[] | undefined,
  selectedYear: string,
  color: string,
  setPeriodsData: React.Dispatch<React.SetStateAction<BarChartValueWithColor[]>>
) => {
  if (!data && data !== undefined) {
    setPeriodsData([])
    return
  }

  const yearData = data?.find((item) => item.year === selectedYear)

  if (!yearData) {
    setPeriodsData([])
    return
  }

  const periodsData = yearData.periods.map((period) => {
    const record = period.items.find((item) => item.name?.toLowerCase().startsWith('sum'))

    // With this:
    const id = parseInt(period.key.replace(/[^\d]/g, '')) - 1

    return {
      ...record,
      local_value: record?.local_value.toFixed(2) ?? 0,
      color,
      id,
      period: period.key,
    } as BarChartValueWithColor
  })

  setPeriodsData(periodsData)
}
