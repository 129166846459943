import { SensitiveAreaOut, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'

import { DEFAULT_RADIUS } from '@/lib/geo'
import SensitiveAreasMap from '@/components/beeoimpact/impacts/sensitive-areas-map'
import { Icons } from '@/components/icons'

interface RiskMapReportProps extends React.HTMLAttributes<HTMLDivElement> {
  center: LatLng
  site: Site
  sensitiveAreas: SensitiveAreaOut[]
  onMapReady?: () => void
}

const RiskMapReport = ({ sensitiveAreas, site, center, className, onMapReady, ...props }: RiskMapReportProps) => {
  const { t } = useTranslation()

  const biodivScore = (site?.properties?.stats_biodiversity?.biodiversity_score ?? 0).toFixed(2) + ' %'

  return (
    <div className={className} {...props}>
      {!(sensitiveAreas.length === 0 && parseInt(biodivScore) !== 0) ? (
        <>
          <div className="size-96">
            <SensitiveAreasMap
              sensitiveAreas={sensitiveAreas}
              radius={site?.properties?.biodiversity_radius ?? DEFAULT_RADIUS}
              center={center}
              onMapReady={onMapReady}
              classname="size-96"
            />
          </div>
          <div>
            <div className="flex flex-col justify-evenly space-y-4">
              <div className="flex flex-row space-x-4">
                <p>
                  <Icons.Leaf />
                </p>
                <div>
                  <p>{biodivScore}</p>
                  <p className="text-muted-foreground">
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.biodiversityScore)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row space-x-4">
                <p>
                  <Icons.Trees />
                </p>
                <div>
                  <p>
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.sensitiveAreasIn, {
                      count: site.properties?.stats_biodiversity?.nb_in_sensitive_area,
                    })}
                  </p>
                  <p className="text-muted-foreground">
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.sensitiveAreaInDef)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row space-x-4">
                <p>
                  <Icons.Shrub />
                </p>
                <div>
                  <p>
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.influenceZoneAdj, {
                      count: site.properties?.stats_biodiversity?.nb_adjacent_sensitive_area,
                    })}
                  </p>
                  <p className="text-muted-foreground">
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.influenceZoneAdjDef)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row space-x-4">
                <Icons.TreeDeciduous />
                <div>
                  <p>
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.closestReserve)}{' '}
                    {site?.properties?.stats_biodiversity?.closest_reserve?.distance_m?.toFixed(2)} m
                  </p>
                  <p className="text-muted-foreground">
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.closestReserveDef)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container mt-4 flex h-80 items-center justify-center rounded-md border border-dashed">
          <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
            <Icons.Leaf size={42} />
            <h3 className="mt-4 text-lg font-semibold">
              {t(i18nKeys.beeoimpact.site.biodiversityAround.noSensitiveAreasFound)}
            </h3>
            <div className="mb-4 mt-2 text-sm text-muted-foreground">
              <p>
                {t(i18nKeys.beeoimpact.site.biodiversityAround.noSensitiveAreas)}{' '}
                {site?.properties?.biodiversity_radius} {t(i18nKeys.beeoimpact.site.measurementUnit)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RiskMapReport
