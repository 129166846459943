import { useState } from 'react'
import { sitesDestroy } from '@/client/backend/api/sites/sites'
import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { Pen, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { toast } from '@/components/ui/use-toast'
import SiteForm from '@/components/site-form'

type SitesTableRowActionProps = {
  site: Site
  sites: Site[]
  accountId?: number
  queryKey: readonly string[]
}
const SettingsSiteTableRowAction = ({ site, sites, accountId, queryKey }: SitesTableRowActionProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [open, setOpen] = useState(false)

  const deleteSite = async (siteToDelete: Site) => {
    try {
      if (!siteToDelete.id) return
      await sitesDestroy(siteToDelete.id?.toString())
      toast({
        title: t(i18nKeys.global.settings.page.sites.dialog.toast.success),
      })
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: t(i18nKeys.global.settings.page.sites.dialog.toast.error),
          description: error.response.data[0],
        })
      }
    }
    sites.filter((site) => site.id !== siteToDelete.id)
    queryClient.invalidateQueries({ queryKey: [queryKey] })
  }

  return (
    <div className="flex items-center justify-center ">
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetTrigger asChild>
          <Button size="icon" variant="outline" className="ml-auto hidden border-0 lg:flex">
            <Pen className="size-3" />
          </Button>
        </SheetTrigger>
        <SheetContent side="right" className="overflow-y-scroll">
          <SheetHeader>
            <SheetTitle> {t(i18nKeys.global.settings.page.sites.sheet.edit.title)}</SheetTitle>
            <SheetDescription>{t(i18nKeys.global.settings.page.sites.sheet.edit.description)}</SheetDescription>
          </SheetHeader>
          <SiteForm
            setOpenSiteSheet={setOpen}
            setOpenBomSheet={setOpen}
            site={site}
            accountId={accountId}
            queryKey={queryKey}
          />
        </SheetContent>
      </Sheet>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline" className="ml-auto hidden border-0 lg:flex">
            <Trash2 className="size-3" />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t(i18nKeys.global.settings.page.sites.dialog.title, { name: site.properties?.name })}
            </AlertDialogTitle>
            <AlertDialogDescription>{t(i18nKeys.global.settings.page.sites.dialog.description)}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t(i18nKeys.global.settings.page.sites.dialog.button.cancel)}</AlertDialogCancel>

            <Button variant="destructive" onClick={() => deleteSite(site)}>
              {t(i18nKeys.global.settings.page.sites.dialog.button.delete)}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default SettingsSiteTableRowAction
