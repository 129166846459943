import { useEffect, useMemo, useState } from 'react'
import {
  beeomonitoringGraphsDiofurValuesByPeriodList,
  getBeeomonitoringGraphsDiofurValuesByPeriodListQueryKey,
} from '@/client/backend/api/graphs/graphs'
import {
  beeomonitoringSitesSiteInfosRetrieve,
  getBeeomonitoringSitesSiteInfosRetrieveQueryKey,
} from '@/client/backend/api/sites/sites'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { BarChartValueWithColor } from '@/types/graph'
import { getBarDiofurData } from '@/lib/graph'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import CardBarChartSkeleton from '@/components/card-barchart-skeleton'
import CardBarChartSubstance from '@/components/card-barchart-substances'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import SubstancesTable from '@/components/substances-table'
import { H3 } from '@/components/typography'

const DioxinesPolluantsMonitoringPage = () => {
  const [searchParams] = useSearchParams()

  const year = searchParams.get('year') ?? '2023'
  const period = searchParams.get('period') ?? 'P4'

  const { t } = useTranslation()
  const [selectedYear, setSelectedYear] = useState(year)
  const [selectedPeriod, setSelectedPeriod] = useState(period)

  const [years, setYears] = useState<string[]>([])

  const [periods, setPeriods] = useState<string[]>([])

  const [substanceFullDataByPeriods, setSubstanceFullDataByPeriods] = useState<
    Record<string, BarChartValueWithColor[]>
  >({})
  const [dataSumValuesDiofurByPeriod, setSumDataValuesDiofurByPeriod] = useState<BarChartValueWithColor[]>([])

  useEffect(() => {
    setSelectedYear(year ?? selectedYear)
    setSelectedPeriod(period ?? selectedPeriod)
  }, [year, period])

  const { siteId } = useParams<{ siteId: string }>()
  if (!siteId) throw new Error('siteId is required')

  const { data: site, isLoading: isLoadingSite } = useQuery({
    queryKey: [getBeeomonitoringSitesSiteInfosRetrieveQueryKey(siteId)],
    queryFn: () => beeomonitoringSitesSiteInfosRetrieve(siteId, undefined),
  })
  const { data: dataValuesDiofurByPeriod, isLoading: isLoadingDiofurByPeriod } = useQuery({
    queryKey: [getBeeomonitoringGraphsDiofurValuesByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsDiofurValuesByPeriodList(siteId, undefined),
  })

  const allYears = useMemo(() => {
    const years = dataValuesDiofurByPeriod?.map((item) => item.year) || []
    return Array.from(new Set([...years])).sort()
  }, [dataValuesDiofurByPeriod])

  useEffect(() => {
    if (allYears.length > 0) {
      setYears(allYears)
      setSelectedYear(year ?? allYears[allYears.length - 1])
    }
  }, [allYears])

  const allPeriods = useMemo(() => {
    const periodsCountValues = dataValuesDiofurByPeriod?.find((item) => item.year === selectedYear)?.periods || []
    return Array.from(new Set([...periodsCountValues].map((item) => item.key))).sort()
  }, [dataValuesDiofurByPeriod, selectedYear])

  useEffect(() => {
    if (allPeriods.length > 0) {
      setPeriods(allPeriods)
      setSelectedPeriod(allPeriods.includes(period) ? period : allPeriods[allPeriods.length - 1])
    }
  }, [allPeriods])

  useEffect(() => {
    if (selectedYear && selectedPeriod) {
      const { groupedData, sumItems } = getBarDiofurData(dataValuesDiofurByPeriod ?? [], selectedPeriod, selectedYear)
      setSumDataValuesDiofurByPeriod(sumItems)

      delete groupedData.other

      setSubstanceFullDataByPeriods(groupedData)
    }
  }, [dataValuesDiofurByPeriod, selectedPeriod, selectedYear])

  return (
    <div className="container relative my-8">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: 'beeomonitoring',
            href: getPath(Routes.BEEOMONITORING_SITES),
          },
          {
            label: t(i18nKeys.beeoinitiative.common.breadcrumb.sites),
            href: getPath(Routes.BEEOMONITORING_SITES),
          },
          {
            label: site?.name ?? '',
            href: getPath(Routes.BEEOMONITORING_SITE, { params: { siteId: site?.id as number } }),
          },
        ]}
        currentPage={t(i18nKeys.beeomonitoring.diofur.graph.title)}
      />

      <div className="flex flex-col justify-between">
        <div className="flex items-center justify-between space-y-2">
          <PageHeader>
            <PageHeaderHeading>
              {isLoadingSite ? (
                <div className="flex items-end justify-start">
                  <Skeleton className="mr-2 h-[34px] w-[400px]" />
                  <Skeleton className="h-[24px] w-[80px]" />
                </div>
              ) : (
                site && (
                  <div>
                    {site.name} <span className="text-sm font-medium text-muted-foreground"># {site.key}</span>
                  </div>
                )
              )}
            </PageHeaderHeading>
            <div className="flex flex-row">
              {isLoadingSite ? (
                <>
                  <div>
                    <Skeleton className="h-[22px] w-[62px]" />
                  </div>

                  <div>
                    <Skeleton className="ml-2 h-[22px] w-[110px]" />
                  </div>
                </>
              ) : (
                site?.tags?.map((tag) => (
                  <Badge key={tag} className="mr-4 rounded-none bg-muted text-foreground hover:bg-foreground/10">
                    {tag}
                  </Badge>
                ))
              )}
            </div>
          </PageHeader>
          <div className="flex items-center space-x-2">
            {isLoadingSite || isLoadingDiofurByPeriod ? (
              <>
                <Skeleton className="h-10 w-44" />
                <Skeleton className="h-10 w-44" />
              </>
            ) : (
              <>
                <Select value={selectedYear} onValueChange={(e) => setSelectedYear(e)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={selectedYear} placeholder={selectedYear} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {years.map((year) => (
                        <SelectItem key={year} value={year}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <Select value={selectedPeriod} onValueChange={(e) => setSelectedPeriod(e)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={selectedPeriod} placeholder={`${selectedPeriod}`} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {periods.map((period, index) => (
                        <SelectItem key={index} value={period}>
                          {period}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-2">
          {isLoadingDiofurByPeriod ? (
            <CardBarChartSkeleton />
          ) : (
            <CardBarChartSubstance
              data={dataSumValuesDiofurByPeriod}
              title={t(i18nKeys.beeomonitoring.diofur.graph.title)}
              subtitle={t(i18nKeys.beeomonitoring.diofur.graph.description)}
              type="DIOFUR"
              showAllSums
            />
          )}
          <Card>
            <CardHeader>
              <CardTitle>{t(i18nKeys.beeomonitoring.common.definition)}</CardTitle>
              <CardDescription></CardDescription>
            </CardHeader>
            <CardContent>
              <Trans
                i18nKey={i18nKeys.beeomonitoring.diofur.definition.content.para_1}
                components={{ p: <p className="mt-4 leading-6" /> }}
              />
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="my-8">
        {Object.entries(substanceFullDataByPeriods).map(([substance, data]) => (
          <div key={substance} className="mt-8 w-full min-w-0">
            <div className="my-8">
              <H3>{t(i18nKeys.beeomonitoring.diofur.table.title, { type: substance })}</H3>
              <p className="pb-4 text-sm text-muted-foreground">
                {t(i18nKeys.beeomonitoring.diofur.table.type[substance])}
              </p>
              <Separator />
            </div>
            <SubstancesTable
              data={data}
              substance={substance}
              headers={['Substance', 'Value', 'TEF-WHO-2005', 'TEQ', 'Units']}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default DioxinesPolluantsMonitoringPage
