import { useMemo } from 'react'
import { SensitiveAreaOut } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { Circle, LayerGroup, LayersControl, MapContainer, ScaleControl, TileLayer } from 'react-leaflet'

import { DEFAULT_RADIUS, getBoundsFromCenterAndRadius } from '@/lib/geo'
import { cn } from '@/lib/utils'
import MapBadge from '@/components/beeoimpact/impacts/map-badge'
import SensitiveAreasPolygon from '@/components/beeoimpact/impacts/sensitive-areas-polygon'

interface SensitiveAreasMapProps {
  sensitiveAreas?: SensitiveAreaOut[]
  center: LatLng
  radius?: number
  onMapReady?: () => void
  classname?: string
  fullGeom?: boolean
}

const SensitiveAreasMap = ({
  sensitiveAreas,
  center,
  radius,
  onMapReady,
  classname,
  fullGeom = false,
}: SensitiveAreasMapProps) => {
  const { t } = useTranslation()
  const mapRadius = radius ?? DEFAULT_RADIUS

  const mapBounds = useMemo(() => {
    return getBoundsFromCenterAndRadius(center, mapRadius)
  }, [center, mapRadius])

  const sources = useMemo(() => {
    return [...new Set(sensitiveAreas?.map((area) => area.source) ?? [])]
  }, [sensitiveAreas])

  return (
    <MapContainer
      className={cn('z-0 min-h-[30rem] rounded-md', classname)}
      bounds={mapBounds}
      scrollWheelZoom={false}
      attributionControl={false}
      zoomSnap={0.1}
      whenReady={onMapReady}
    >
      <Circle center={center} color={'cadetblue'} fillColor="cadetblue" radius={mapRadius} />
      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <MapBadge content={`${((radius ?? 0) / 1000).toFixed(1)} km radius`} />
      <LayersControl position="bottomright">
        {sources.map((source, idx) => (
          <LayersControl.Overlay key={idx} checked name={t(i18nKeys.beeoimpact.sensitiveAreas.source[source])}>
            <LayerGroup>
              {sensitiveAreas
                ?.filter((area) => area.source === source)
                .map((area, areaIdx) => (
                  <SensitiveAreasPolygon key={areaIdx} sensitiveArea={area} fullGeom={fullGeom} />
                ))}
            </LayerGroup>
          </LayersControl.Overlay>
        ))}
      </LayersControl>
      <ScaleControl position="bottomleft" />
    </MapContainer>
  )
}

export default SensitiveAreasMap
