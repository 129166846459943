import { PaginatedSiteList, SitesListParams, User } from '@/client/backend/models'

import { ScoringType } from '@/types/scoring-type'
import { MODULE } from '@/lib/module'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Skeleton } from '@/components/ui/skeleton'
import SiteCardImpact from '@/components/beeoimpact/sites/site-card-impact'
import SiteCardInitiative from '@/components/beeoinitiative/site-card-initiative'
import NoSitesFound from '@/components/no-sites-found'
import NoSitesMessage from '@/components/no-sites-message'
import SiteCard from '@/components/site-card'
import { PaginationControls } from '@/components/sites/pagination-controls'
import SitesRiskLegend from '@/components/sites/sites-risk-legend'

interface SitesCardsProps {
  module: MODULE
  isLoadingDataSites: boolean
  user: User
  setHoveredSiteId: (id: number | undefined) => void
  filters: SitesListParams
  paginatedData?: PaginatedSiteList
  activeScoring: ScoringType
}

const SitesCards = ({
  module,
  isLoadingDataSites,
  setHoveredSiteId,
  filters,
  paginatedData,
  activeScoring,
}: SitesCardsProps) => {
  const transformPath = (page: number) => {
    const params: SitesListParams = {
      page,
      ...(filters.search && { search: filters.search }),
      ...(filters.ordering && { ordering: filters.ordering }),
    }

    switch (module) {
      case MODULE.MONITORING:
        return getPath(Routes.BEEOMONITORING_SITES, { queryParams: params })
      case MODULE.AUDIT:
        return getPath(Routes.BEEOAUDIT_SITES, { queryParams: params })
      case MODULE.IMPACT:
        return getPath(Routes.BEEOIMPACT_SITES, { queryParams: params })
      default:
        return ''
    }
  }

  const getLinkCard = (siteId: number) => {
    switch (module) {
      case MODULE.MONITORING:
        return getPath(Routes.BEEOMONITORING_SITE, { params: { siteId } })
      case MODULE.AUDIT:
        return getPath(Routes.BEEOAUDIT_SITE, { params: { siteId } })
      default:
      case MODULE.IMPACT:
        return getPath(Routes.BEEOIMPACT_SITE, { params: { siteId } })
    }
  }

  const sites = paginatedData?.results?.features ?? []
  return (
    <ScrollArea className="flex grow flex-col">
      <div className="grid grow grid-cols-1 gap-5 pb-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
        {module === MODULE.IMPACT && (
          <div className="col-span-full">
            <SitesRiskLegend scoringType={activeScoring} />
          </div>
        )}
        {isLoadingDataSites ? (
          Array.from({ length: 9 }).map((_, index) => <Skeleton key={index} className="min-h-[280px] w-full" />)
        ) : sites?.length === 0 ? (
          filters.search !== '' ? (
            <NoSitesFound />
          ) : (
            <NoSitesMessage />
          )
        ) : (
          <>
            {sites.map((site) => {
              switch (module) {
                case MODULE.INITIATIVE:
                  return (
                    <SiteCardInitiative
                      key={site.id}
                      site={site}
                      onSiteHover={() => setHoveredSiteId(site.id)}
                      onSiteHoverOut={() => setHoveredSiteId(undefined)}
                      link={getLinkCard(site.id)}
                    />
                  )
                case MODULE.IMPACT:
                  return (
                    <SiteCardImpact
                      key={site.id}
                      site={site}
                      onSiteHover={() => setHoveredSiteId(site.id)}
                      onSiteHoverOut={() => setHoveredSiteId(undefined)}
                      link={getLinkCard(site.id)}
                      scoringType={activeScoring}
                    />
                  )
                case MODULE.MONITORING:
                  return (
                    <SiteCard
                      key={site.id}
                      site={site}
                      to={getLinkCard(site.id)}
                      withScoring={false}
                      onSiteHoverEnter={() => setHoveredSiteId(site.id)}
                      onSiteHoverOut={() => setHoveredSiteId(undefined)}
                    />
                  )
                default:
                  return (
                    <SiteCard
                      key={site.id}
                      site={site}
                      to={getLinkCard(site.id)}
                      withScoring={true}
                      onSiteHoverEnter={() => setHoveredSiteId(site.id)}
                      onSiteHoverOut={() => setHoveredSiteId(undefined)}
                    />
                  )
              }
            })}
          </>
        )}
      </div>
      {paginatedData && <PaginationControls paginatedData={paginatedData} transformPath={transformPath} />}
    </ScrollArea>
  )
}

export default SitesCards
