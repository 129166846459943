import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Progress } from '@/components/ui/progress'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

const InitativeProgress = ({
  actionTakenCount,
  actionTakenDoneCount,
}: {
  actionTakenCount: number
  actionTakenDoneCount: number
}) => {
  const { t } = useTranslation()
  const percentage = (actionTakenDoneCount / actionTakenCount) * 100

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className="w-full max-w-44">
          <Progress value={percentage} className="h-2 bg-biodivTealSevenHundred [&>div]:bg-biodivLimeFiveHundred" />
        </TooltipTrigger>
        <TooltipContent>
          <p>
            {t(i18nKeys.beeoinitiative.site.initiativesAchieved, {
              count: actionTakenCount,
              actionTakenDoneCount,
              actionTakenCount,
            })}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
type InitativeCardProps = {
  site?: Site
  actionTakenCount: number
  actionTakenPlannedCount: number
  actionTakenDoneCount: number
}
const InitativesCard = ({
  site,
  actionTakenCount,
  actionTakenPlannedCount,
  actionTakenDoneCount,
}: InitativeCardProps) => {
  const { t } = useTranslation()
  const siteId = site?.id

  return (
    <div className="flex flex-1 flex-col items-center justify-center space-y-3 pb-2">
      <p className="text-xl font-semibold">{`${actionTakenCount} ${t(i18nKeys.beeoinitiative.site.initiatives, { count: actionTakenCount })}`}</p>
      {actionTakenCount > 0 ? (
        <>
          <InitativeProgress actionTakenCount={actionTakenCount} actionTakenDoneCount={actionTakenDoneCount} />
          {actionTakenPlannedCount ? (
            <p className="text-sm font-semibold text-biodivTealSevenHundred">
              {t(i18nKeys.beeoinitiative.site.stillXToGo, { count: actionTakenPlannedCount })}
            </p>
          ) : (
            <p className="text-sm font-semibold text-biodivLimeFiveHundred">
              {t(i18nKeys.beeoinitiative.site.allAchived)}
            </p>
          )}
        </>
      ) : (
        siteId && (
          <Link to={getPath(Routes.BEEOINITIATIVE_INITIATIVES_BY_SITE, { params: { siteId } })}>
            <Button variant="outline" className="border-black font-semibold">
              {t(i18nKeys.beeoinitiative.site.planAnInitative)}
            </Button>
          </Link>
        )
      )}
    </div>
  )
}

export default InitativesCard
