import { getUsersManagedUsersListQueryKey, usersManagedUsersList } from '@/client/backend/api/users/users'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@/hooks/useAuth'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import UsersTable from '@/components/users-table'

const Users = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const { data: users } = useQuery({
    queryKey: user?.id ? [getUsersManagedUsersListQueryKey(user.id)] : [],
    queryFn: () => usersManagedUsersList(user!.id),
    enabled: !!user?.id,
  })

  if (!users) {
    return <Skeleton className="block h-96" />
  }

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">{t(i18nKeys.global.settings.page.users.title)}</h3>
        <p className="text-sm text-muted-foreground">{t(i18nKeys.global.settings.page.users.description)}</p>
      </div>
      <Separator />
      <UsersTable users={users} queryKey={getUsersManagedUsersListQueryKey(user!.id)} />
    </div>
  )
}

export default Users
