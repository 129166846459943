import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { Compass, Grid, Microscope } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { FeatureType } from '@/types/feature-type'
import { Properties } from '@/types/properties'
import { ZoneWithPercentage } from '@/types/zone'
import { hasAccesTo } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import { Skeleton } from '@/components/ui/skeleton'
import ScoreCard from '@/components/beeoimpact/general-summary/score-card'
import { Icons } from '@/components/icons'
import Loader from '@/components/loader'
import MarketingCard from '@/components/marketing-card'

interface GeneralSummaryProps {
  site?: Site
  zoneWithPercentage: ZoneWithPercentage[]
  threatenedSpeciesNumber?: number
  isLoadingSite?: boolean
  isLoadingClc?: boolean
  isLoadingGbif?: boolean
}

const GeneralSummary = ({
  site,
  zoneWithPercentage,
  threatenedSpeciesNumber,
  isLoadingSite,
  isLoadingClc,
  isLoadingGbif,
}: GeneralSummaryProps) => {
  const { t } = useTranslation()
  const { user } = useAuth()

  if (!user) return <Loader />

  const sensitiveAreasNumber =
    (site?.properties?.stats_biodiversity?.nb_n2k ?? 0) + (site?.properties?.stats_biodiversity?.nb_cdda ?? 0)

  const properties_biodiversity: Properties[] = []
  const properties_pesticides: Properties[] = []

  const properties_clcgbif: Properties[] = []

  if (site?.properties?.stats_biodiversity?.closest_reserve) {
    const c = site.properties.stats_biodiversity.closest_reserve
    const distance = c.distance_m?.toFixed(2)
    let value

    if (distance && parseInt(distance) !== 0.0) {
      value = `"${c.name}" ${t(i18nKeys.beeoimpact.site.keyInformation.is)} ${t(
        i18nKeys.beeoimpact.site.keyInformation.at
      )} ${distance} ${t(i18nKeys.beeoimpact.site.measurementUnit)}`
    } else {
      value = `${t(i18nKeys.beeoimpact.site.keyInformation.siteIsIn)} "${c.name}"`
    }

    if (sensitiveAreasNumber !== 0) {
      properties_biodiversity.push({
        value: `${sensitiveAreasNumber} ${t(i18nKeys.beeoimpact.site.foundItems.sensitiveAreas, {
          count: sensitiveAreasNumber,
        })}`,
        icon: <Icons.Trees className="mt-px size-5" />,
        description: t(i18nKeys.beeoimpact.site.keyInformation.sensitiveAreaDesc),
      })
    }

    properties_biodiversity.push({
      value: value,
      icon: <Compass className="mt-px size-5" />,
      description: t(i18nKeys.beeoimpact.site.keyInformation.closestReserveDescription),
    })
  }

  if (site?.properties?.stats_biodiversity?.animal_at_risk) {
    const a = site.properties.stats_biodiversity.animal_at_risk
    properties_biodiversity.push({
      value: `${t(i18nKeys.beeoimpact.site.keyInformation.presence)} ${a.name}`,
      icon: <Icons.Bird className="mt-px size-5" />,
      description: t(i18nKeys.beeoimpact.site.keyInformation.animatAtRiskDescription),
    })
  }

  if (site?.properties?.stats_pesticides?.probable_pesticides) {
    const probablePesticides = site.properties?.stats_pesticides?.probable_pesticides
    properties_pesticides.push({
      value: `${t(i18nKeys.beeoimpact.site.keyInformation.probablePresence)} ${
        probablePesticides.map((pesticide) => pesticide.name)[0]
      }`,
      icon: <Icons.FlaskConical className="mt-px size-5" />,
      description: t(i18nKeys.beeoimpact.site.keyInformation.probablePesticideDesc),
    })
  }

  if (site?.properties?.stats_pesticides?.parcels !== 0) {
    properties_pesticides.push({
      value: `${site?.properties?.stats_pesticides?.parcels} ${t(i18nKeys.beeoimpact.site.foundItems.fields)}`,
      icon: <Grid className="mt-px size-5" />,
      description: t(i18nKeys.beeoimpact.site.keyInformation.parcelsDesc),
    })
  }
  if (site?.properties?.stats_pesticides?.matters !== 0) {
    properties_pesticides.push({
      value: `${site?.properties?.stats_pesticides?.matters} ${t(i18nKeys.beeoimpact.site.foundItems.activeMatters)}`,
      icon: <Microscope className="mt-px size-5" />,
      description: t(i18nKeys.beeoimpact.site.keyInformation.activeMattersDesc),
    })
  }

  zoneWithPercentage.map((zone) => {
    properties_clcgbif.push({
      value: `${zone.percentage.toFixed(2)} %`,
      icon: zone.icon,
      description: t(zone.descriptionKey),
    })
  })

  if (zoneWithPercentage.length !== 0) {
    properties_clcgbif.push({
      value: threatenedSpeciesNumber ?? 0,
      icon: <Icons.Bird className="mt-px w-9" />,
      description: t(i18nKeys.beeoimpact.site.biodiversityAround.threatenedSpecies),
    })
  }

  return (
    <section className="mt-4 content-stretch items-stretch gap-4 xl:flex">
      {hasAccesTo(user.features, FeatureType.POTENTIAL) ? (
        isLoadingClc || isLoadingGbif ? (
          <Skeleton className="h-[450px] w-full" />
        ) : (
          <ScoreCard
            title={t(i18nKeys.beeoimpact.site.dashboard.sitePotential)}
            description={t(i18nKeys.beeoimpact.site.dashboard.potentialDescription)}
            properties={properties_clcgbif}
            scoreText={t(i18nKeys.beeoimpact.site.biodiversityAround.clcGbifNoData)}
            link={getPath(Routes.BEEOIMPACT_SITE_GENERAL_ANALYSIS, {
              params: { siteId: site?.id as number },
            })}
          />
        )
      ) : (
        <MarketingCard
          text={t(i18nKeys.beeoimpact.common.marketing.card.generalAnalysis.text)}
          contact={t(i18nKeys.beeoimpact.common.marketing.card.generalAnalysis.contact)}
        />
      )}

      {hasAccesTo(user.features, FeatureType.BIODIVERSITY) ? (
        isLoadingSite ? (
          <Skeleton className="h-[450px] w-full" />
        ) : (
          <ScoreCard
            score={site?.properties?.stats_biodiversity?.biodiversity_score}
            title={t(i18nKeys.beeoimpact.site.dashboard.scoring.biodiversity.title)}
            icon={<Icons.Leaf className="mr-1 inline-block size-4 align-text-top" />}
            description={t(i18nKeys.beeoimpact.site.dashboard.scoring.biodiversity.description)}
            properties={properties_biodiversity}
            scoreText={t(i18nKeys.beeoimpact.site.scoreText.biodiv)}
            link={getPath(Routes.BEEOIMPACT_SITE_IMPACTS, { params: { siteId: site?.id as number } })}
          />
        )
      ) : (
        <MarketingCard
          text={t(i18nKeys.beeoimpact.common.marketing.card.biodiversity.text)}
          contact={t(i18nKeys.beeoimpact.common.marketing.card.biodiversity.contact)}
        />
      )}

      {hasAccesTo(user.features, FeatureType.PESTICIDES) ? (
        isLoadingSite ? (
          <Skeleton className="h-[450px] w-full" />
        ) : (
          <ScoreCard
            score={site?.properties?.stats_pesticides?.ecoscore}
            title={t(i18nKeys.beeoimpact.site.dashboard.scoring.pesticide.title)}
            description={t(i18nKeys.beeoimpact.site.dashboard.scoring.pesticide.description)}
            properties={properties_pesticides}
            icon={<Icons.FlaskConical className="mr-1 inline-block size-4 align-text-top" />}
            scoreText={t(i18nKeys.beeoimpact.site.scoreText.pesticide)}
            link={getPath(Routes.BEEOIMPACT_SITE_PESTICIDES, { params: { siteId: site?.id as number } })}
          />
        )
      ) : (
        <MarketingCard
          text={t(i18nKeys.beeoimpact.common.marketing.card.pesticide.text)}
          contact={t(i18nKeys.beeoimpact.common.marketing.card.pesticide.contact)}
        />
      )}
    </section>
  )
}

export default GeneralSummary
