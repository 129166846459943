import { i18nKeys } from '@/locales/keys'
import {
  BadgeCheck,
  CalendarCheck2,
  ChartNoAxesCombined,
  GitCompareArrows,
  Leaf,
  Route,
  Satellite,
  Search,
  Sprout,
  Target,
} from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { SUPPORT_EMAIL } from '@/lib/constants/email'
import { cn } from '@/lib/utils'
import { Button, ButtonProps } from '@/components/ui/button'

const Timeline = ({ children }: { children: React.ReactNode }) => {
  return <ol className="sm:flex">{children}</ol>
}

const TimelineItem = ({
  icon,
  title,
  children,
}: {
  icon: React.ReactNode
  title: string
  children: React.ReactNode
}) => {
  return (
    <li className="mb-6 flex items-start gap-2 sm:mb-0 sm:block sm:w-full">
      <div className="flex items-center pt-1">
        <div className="z-10 flex size-6 shrink-0 items-center justify-center rounded-full text-teal-700 ring-0 ring-white sm:ring-8">
          {icon}
        </div>
        <div className="hidden h-0.5 w-full bg-zinc-200 sm:flex"></div>
      </div>
      <div className="pe-8 sm:mt-3">
        <h3 className="mb-3 text-2xl font-bold text-teal-700">{title}</h3>
        {children}
      </div>
    </li>
  )
}

const TimelineItemElement = ({ title, description }: { title: string; description: string }) => {
  return (
    <div className="mb-2">
      <h4 className="text-xl font-semibold">{title}</h4>
      <p className="text-lg text-gray-500 ">{description}</p>
    </div>
  )
}

const CTA = ({ children, variant }: React.HTMLAttributes<HTMLButtonElement> & ButtonProps) => {
  const { t } = useTranslation()

  return (
    <a href={`mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(t(i18nKeys.global.landingPage.cta.subject))}`}>
      <Button variant={variant} className="w-fit">
        <div className="text-xs font-semibold leading-normal xl:text-lg">{children}</div>
      </Button>
    </a>
  )
}
const SectionTitle = ({ children, className }: React.HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <h2
      className={cn(
        'my-8 text-3xl font-semibold text-neutral-800 sm:text-center md:my-10 lg:text-4xl xl:my-16 xl:text-5xl 2xl:text-6xl',
        className
      )}
    >
      {children}
    </h2>
  )
}

const FeatureCard = ({ feature }: { feature: keyof typeof i18nKeys.global.landingPage.features }) => {
  const { t } = useTranslation()

  return (
    <div className="container mx-auto flex flex-col items-center gap-16 rounded-2xl bg-white p-16 md:flex-row md:even:flex-row-reverse">
      <div className="flex flex-col">
        <img src={`/assets/landing/${feature}-logo.svg`} alt={`${feature}-logo`} className="w-12 pb-4" />
        <h3 className="mb-6 text-3xl font-semibold text-stone-800">
          {t(i18nKeys.global.landingPage.features[feature].title)}
        </h3>
        <p className="pb-3 text-sm font-semibold text-teal-700 xl:text-base">
          {t(i18nKeys.global.landingPage.features[feature].subtitle)}
        </p>
        <div className="mb-7 h-px w-full border border-zinc-200 text-teal-600"></div>
        <p className="text-sm xl:text-base">{t(i18nKeys.global.landingPage.features[feature].description)}</p>
      </div>
      <img
        src={`/assets/landing/${feature}.jpg`}
        alt={`${feature}-preview`}
        className="max-w-full md:w-60 lg:w-80 xl:w-96 2xl:w-max"
      />
    </div>
  )
}

type FeatureProps = {
  id: keyof typeof i18nKeys.global.landingPage.benefits
  icon: React.ReactNode
  className?: string
}

const BenefitCard = ({ id, icon, className }: FeatureProps) => {
  const { t } = useTranslation()

  return (
    <div
      key={id}
      className={cn(
        'relative col-span-6 flex flex-col gap-4 rounded-2xl border border-zinc-200 pb-16 pl-16 pr-24 pt-20 lg:col-span-3 xl:col-span-2',
        className
      )}
    >
      <h3 className="break-words text-2xl font-semibold text-stone-800 xl:text-3xl">
        {t(i18nKeys.global.landingPage.benefits[id].title)}
      </h3>
      <p className="text-sm xl:text-base">{t(i18nKeys.global.landingPage.benefits[id].description)}</p>
      <div className="absolute right-10 top-10 font-light text-teal-600">{icon}</div>
    </div>
  )
}

const LandingPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <section className="relative bg-white lg:bg-gradient-to-r lg:from-white lg:via-white lg:via-60% lg:to-teal-700 lg:to-60%">
        <div className="container mx-auto flex justify-between">
          <div className="my-8 flex flex-col gap-7 lg:my-24 xl:my-32">
            <h1 className="text-6xl font-semibold lg:text-4xl xl:text-5xl 2xl:text-6xl">
              {t(i18nKeys.global.landingPage.intro.title)}
            </h1>
            <p className="text-xl lg:text-base xl:text-lg 2xl:text-xl">
              {t(i18nKeys.global.landingPage.intro.description)}
            </p>
            <ul className="list-inside list-disc text-xl lg:text-base xl:text-lg 2xl:text-xl">
              {Object.values(i18nKeys.global.landingPage.benefits).map((benefit) => (
                <li key={benefit.title}>{t(benefit.title)}</li>
              ))}
            </ul>
            <CTA>{t(i18nKeys.global.landingPage.intro.button)}</CTA>
          </div>
          <div className="relative hidden w-2/5 min-w-[480px] bg-teal-700 object-left text-white lg:block lg:h-[550px] xl:h-[650px] 2xl:h-[750px]">
            <div className="mb-7 ml-16 mt-12 flex flex-col gap-4">
              <div className="flex items-center gap-5">
                <Sprout size={24} />
                <div>
                  <div className="font-semibold">68.34%</div>
                  <div className="text-xs">{t(i18nKeys.global.landingPage.intro.agricultural)}</div>
                </div>
              </div>
              <div className="h-px w-52 border border-white/30"></div>
              <div className="flex items-center gap-5">
                <Leaf size={24} />
                <div>
                  <div className="font-semibold">23.00%</div>
                  <div className="text-xs">{t(i18nKeys.global.landingPage.intro.vegetation)}</div>
                </div>
              </div>
            </div>
            <img
              className="absolute bottom-0 left-14 w-2/5 max-w-[562px]"
              src="/assets/landing/bar-chart.svg"
              alt="bar-chart"
            />
            <img
              className="absolute -left-40 top-52 w-4/5 max-w-[562px]"
              src="/assets/landing/ipad-preview.png"
              alt="ipad-preview"
            />
          </div>
          <img
            className="absolute right-0 top-16 hidden w-1/5 max-w-[382px] lg:block"
            src="/assets/landing/map-preview.png"
            alt="map-preview"
          />
        </div>
      </section>
      <section className="container mx-auto py-8">
        {/* <SectionTitle>{t(i18nKeys.global.landingPage.timeline.title)}</SectionTitle> */}
        <p className="pb-8 text-xl">{t(i18nKeys.global.landingPage.timeline.description)}</p>
        <Timeline>
          <TimelineItem
            icon={<Satellite size={57} />}
            title={t(i18nKeys.global.landingPage.timeline.beeoimpact.title)}
            children={
              <>
                <TimelineItemElement
                  title={t(i18nKeys.global.landingPage.timeline.beeoimpact.priorisation.title)}
                  description={t(i18nKeys.global.landingPage.timeline.beeoimpact.priorisation.description)}
                />
                <TimelineItemElement
                  title={t(i18nKeys.global.landingPage.timeline.beeoimpact.assessment.title)}
                  description={t(i18nKeys.global.landingPage.timeline.beeoimpact.assessment.description)}
                />
              </>
            }
          />
          <TimelineItem
            icon={<Search size={57} />}
            title={t(i18nKeys.global.landingPage.timeline.beeonsite.title)}
            children={
              <>
                <TimelineItemElement
                  title={t(i18nKeys.global.landingPage.timeline.beeonsite.monitoring.title)}
                  description={t(i18nKeys.global.landingPage.timeline.beeonsite.monitoring.description)}
                />
                <TimelineItemElement
                  title={t(i18nKeys.global.landingPage.timeline.beeonsite.dna.title)}
                  description={t(i18nKeys.global.landingPage.timeline.beeonsite.dna.description)}
                />
                <TimelineItemElement
                  title={t(i18nKeys.global.landingPage.timeline.beeonsite.audit.title)}
                  description={t(i18nKeys.global.landingPage.timeline.beeonsite.audit.description)}
                />
              </>
            }
          />
          <TimelineItem
            icon={<CalendarCheck2 size={57} />}
            title={t(i18nKeys.global.landingPage.timeline.beeoinitiative.title)}
            children={
              <TimelineItemElement
                title={t(i18nKeys.global.landingPage.timeline.beeoinitiative.action.title)}
                description={t(i18nKeys.global.landingPage.timeline.beeoinitiative.action.description)}
              />
            }
          />
        </Timeline>
      </section>
      <section className="bg-stone-100 py-8">
        <SectionTitle className="container">{t(i18nKeys.global.landingPage.featureTitle)}</SectionTitle>
        <div className="container flex flex-col gap-5">
          <FeatureCard feature="beeoimpact-site-priorisation" />
          <FeatureCard feature="beeoimpact-high-level-site-assessment" />
          <FeatureCard feature="beeomonitoring" />
          <FeatureCard feature="beeodna" />
          <FeatureCard feature="beeoaudit" />
          <FeatureCard feature="beeoinitiative" />
        </div>
      </section>
      <section className="container relative mx-auto py-8">
        <SectionTitle>{t(i18nKeys.global.landingPage.benefitTitle)}</SectionTitle>
        <div className="grid grid-cols-6 gap-5">
          <BenefitCard id="biodiversity" icon={<Route size={57} absoluteStrokeWidth />} />
          <BenefitCard id="centralize" icon={<Target size={57} absoluteStrokeWidth />} />
          <BenefitCard id="compare" icon={<GitCompareArrows size={57} absoluteStrokeWidth />} />
          <BenefitCard
            id="robust"
            icon={<ChartNoAxesCombined size={57} absoluteStrokeWidth />}
            className="xl:col-span-3"
          />
          <BenefitCard
            id="frameworks"
            icon={<BadgeCheck size={57} absoluteStrokeWidth />}
            className="lg:col-span-6 xl:col-span-3"
          />
        </div>
      </section>
      <section className="container mx-auto bg-teal-700 py-8 sm:relative sm:mb-8 sm:rounded-2xl sm:bg-inherit sm:py-0">
        <div className="z-10 flex flex-col items-start gap-3 sm:absolute sm:left-24 sm:top-8 lg:left-24 lg:top-10 lg:gap-5 xl:top-24 xl:gap-7 2xl:top-24 2xl:gap-9">
          <h3 className="font-semibold text-white sm:bg-[#1f6f6c] sm:text-xl md:whitespace-pre-line md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
            {t(i18nKeys.global.landingPage.cta.title)}
          </h3>
          <CTA variant="secondary">{t(i18nKeys.global.landingPage.cta.button)}</CTA>
        </div>
        <img className="hidden sm:block" src="/assets/landing/contact-us.png" alt="contact-us" />
      </section>
    </>
  )
}
export default LandingPage
