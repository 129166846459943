// keys.ts

export const i18nKeys = {
  beeoaudit: {
    auditForm: {
      notification: {
        success: 'beeoaudit.auditForm.notification.success',
        error: 'beeoaudit.auditForm.notification.error',
        preventClose: 'beeoaudit.auditForm.notification.preventClose',
        preventCloseDescription: 'beeoaudit.auditForm.notification.preventCloseDescription',
      },
      auditDate: 'beeoaudit.auditForm.auditDate',
      addAudit: 'beeoaudit.auditForm.addAudit',
      addAuditDescription: 'beeoaudit.auditForm.addAuditDescription',
      superficy: 'beeoaudit.auditForm.superficy',
      vegetalCoverPc: 'beeoaudit.auditForm.vegetalCoverPc',
      surfaceBiotopeCoefficient: 'beeoaudit.auditForm.surfaceBiotopeCoefficient',
      amountBirds: 'beeoaudit.auditForm.amountBirds',
      amountMammals: 'beeoaudit.auditForm.amountMammals',
      amountInsects: 'beeoaudit.auditForm.amountInsects',
      amountVegetalSpecies: 'beeoaudit.auditForm.amountVegetalSpecies',
      amountReptiles: 'beeoaudit.auditForm.amountReptiles',
      amountTrees: 'beeoaudit.auditForm.amountTrees',
      amountTreesDescription: 'beeoaudit.auditForm.amountTreesDescription',
      indigenousVegetalPc: 'beeoaudit.auditForm.indigenousVegetalPc',
      save: 'beeoaudit.auditForm.save',
      cancel: 'beeoaudit.auditForm.cancel',
    },
    common: {
      menu: {
        sites: 'beeoaudit.common.menu.sites',
      },
    },
    siteDetails: {
      info: 'beeoaudit.siteDetails.info',
      desc: 'beeoaudit.siteDetails.desc',
      name: 'beeoaudit.siteDetails.name',
      longitude: 'beeoaudit.siteDetails.longitude',
      latitude: 'beeoaudit.siteDetails.latitude',
      addAudit: 'beeoaudit.siteDetails.addAudit',
      addAuditDesc: 'beeoaudit.siteDetails.addAuditDesc',
      deletedSuccess: 'beeoaudit.siteDetails.deletedSuccess',
      deleteDialog: {
        title: 'beeoaudit.siteDetails.deleteDialog.title',
        content: 'beeoaudit.siteDetails.deleteDialog.content',
        cancel: 'beeoaudit.siteDetails.deleteDialog.cancel',
        delete: 'beeoaudit.siteDetails.deleteDialog.delete',
      },
      auditTable: {
        date: 'beeoaudit.siteDetails.auditTable.date',
        pcVgCover: 'beeoaudit.siteDetails.auditTable.pcVgCover',
        surfaceCoefBiotop: 'beeoaudit.siteDetails.auditTable.surfaceCoefBiotop',
        pcVgIndigen: 'beeoaudit.siteDetails.auditTable.pcVgIndigen',
        amountVg: 'beeoaudit.siteDetails.auditTable.amountVg',
        amountTrees: 'beeoaudit.siteDetails.auditTable.amountTrees',
        amountBirds: 'beeoaudit.siteDetails.auditTable.amountBirds',
        amountMammals: 'beeoaudit.siteDetails.auditTable.amountMammals',
        amountInsects: 'beeoaudit.siteDetails.auditTable.amountInsects',
        amountReptiles: 'beeoaudit.siteDetails.auditTable.amountReptiles',
      },
    },
    sites: {
      title: 'beeoaudit.sites.title',
      description: 'beeoaudit.sites.description',
    },
  },
  beeoimpact: {
    biodiversityTable: {
      biodiversityReserves: {
        table: {
          sensitiveAreas: 'beeoimpact.biodiversityTable.biodiversityReserves.table.sensitiveAreas',
          sensitiveAreasDefinition: 'beeoimpact.biodiversityTable.biodiversityReserves.table.sensitiveAreasDefinition',
          type: 'beeoimpact.biodiversityTable.biodiversityReserves.table.type',
          name: 'beeoimpact.biodiversityTable.biodiversityReserves.table.name',
          surface: 'beeoimpact.biodiversityTable.biodiversityReserves.table.surface',
          surfaceDefinition: 'beeoimpact.biodiversityTable.biodiversityReserves.table.surfaceDefinition',
          distance: 'beeoimpact.biodiversityTable.biodiversityReserves.table.distance',
          distanceDefinition: 'beeoimpact.biodiversityTable.biodiversityReserves.table.distanceDefinition',
          radius: 'beeoimpact.biodiversityTable.biodiversityReserves.table.radius',
          siteType: 'beeoimpact.biodiversityTable.biodiversityReserves.table.siteType',
          distance_km: 'beeoimpact.biodiversityTable.biodiversityReserves.table.distance_km',
          iucn_eu: 'beeoimpact.biodiversityTable.biodiversityReserves.table.iucn_eu',
          category: 'beeoimpact.biodiversityTable.biodiversityReserves.table.category',
          color: 'beeoimpact.biodiversityTable.biodiversityReserves.table.color',
          surfacePercentage: 'beeoimpact.biodiversityTable.biodiversityReserves.table.surfacePercentage',
          position: 'beeoimpact.biodiversityTable.biodiversityReserves.table.position',
          priority_species: 'beeoimpact.biodiversityTable.biodiversityReserves.table.priority_species',
          priority_species_yes: 'beeoimpact.biodiversityTable.biodiversityReserves.table.priority_species_yes',
          priority_species_no: 'beeoimpact.biodiversityTable.biodiversityReserves.table.priority_species_no',
          is_invasive: 'beeoimpact.biodiversityTable.biodiversityReserves.table.is_invasive',
        },
      },
      biodiversitySpecies: {
        table: {
          species: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.species',
          prioritySpecies: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.prioritySpecies',
          species_gbif: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.species_gbif',
          group: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.group',
          iucn: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.iucn',
          iucnstatusDefinition: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.iucnstatusDefinition',
          buttonFilter: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.buttonFilter',
          clc: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.clc',
          dropDown: {
            all: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.dropDown.all',
            select: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.dropDown.select',
            search: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.dropDown.search',
          },
          notThreatened: 'beeoimpact.biodiversityTable.biodiversitySpecies.table.notThreatened',
        },
        noSpecies: 'beeoimpact.biodiversityTable.biodiversitySpecies.noSpecies',
        noSpecies2: 'beeoimpact.biodiversityTable.biodiversitySpecies.noSpecies2',
        noSpeciesDetails: 'beeoimpact.biodiversityTable.biodiversitySpecies.noSpeciesDetails',
        threatened: 'beeoimpact.biodiversityTable.biodiversitySpecies.threatened',
        noPrioritySpecies: 'beeoimpact.biodiversityTable.biodiversitySpecies.noPrioritySpecies',
        taxonsArea: 'beeoimpact.biodiversityTable.biodiversitySpecies.taxonsArea',
        taxonsArea2: 'beeoimpact.biodiversityTable.biodiversitySpecies.taxonsArea2',
        taxonsArea3: 'beeoimpact.biodiversityTable.biodiversitySpecies.taxonsArea3',
        prioritySpeciesTableHead: 'beeoimpact.biodiversityTable.biodiversitySpecies.prioritySpeciesTableHead',
        animal: 'beeoimpact.biodiversityTable.biodiversitySpecies.animal',
        plant: 'beeoimpact.biodiversityTable.biodiversitySpecies.plant',
        invasiveSpecies: 'beeoimpact.biodiversityTable.biodiversitySpecies.invasiveSpecies',
      },
      iucn: {
        LC: 'beeoimpact.biodiversityTable.iucn.LC',
        NT: 'beeoimpact.biodiversityTable.iucn.NT',
        VU: 'beeoimpact.biodiversityTable.iucn.VU',
        EN: 'beeoimpact.biodiversityTable.iucn.EN',
        CR: 'beeoimpact.biodiversityTable.iucn.CR',
        EW: 'beeoimpact.biodiversityTable.iucn.EW',
        EX: 'beeoimpact.biodiversityTable.iucn.EX',
        DD: 'beeoimpact.biodiversityTable.iucn.DD',
        NE: 'beeoimpact.biodiversityTable.iucn.NE',
        other: 'beeoimpact.biodiversityTable.iucn.other',
        other_other: 'beeoimpact.biodiversityTable.iucn.other_other',
      },
      speciesGroups: {
        Mammals: 'beeoimpact.biodiversityTable.speciesGroups.Mammals',
        Birds: 'beeoimpact.biodiversityTable.speciesGroups.Birds',
        Reptiles: 'beeoimpact.biodiversityTable.speciesGroups.Reptiles',
        Amphibians: 'beeoimpact.biodiversityTable.speciesGroups.Amphibians',
        Fish: 'beeoimpact.biodiversityTable.speciesGroups.Fish',
        Invertebrates: 'beeoimpact.biodiversityTable.speciesGroups.Invertebrates',
        Plants: 'beeoimpact.biodiversityTable.speciesGroups.Plants',
      },
      location: {
        IN: 'beeoimpact.biodiversityTable.location.IN',
        OUT: 'beeoimpact.biodiversityTable.location.OUT',
        ADJACENT: 'beeoimpact.biodiversityTable.location.ADJACENT',
      },
      habitat: {
        title: 'beeoimpact.biodiversityTable.habitat.title',
        code: 'beeoimpact.biodiversityTable.habitat.code',
        name: 'beeoimpact.biodiversityTable.habitat.name',
        noData: {
          title: 'beeoimpact.biodiversityTable.habitat.noData.title',
          description: 'beeoimpact.biodiversityTable.habitat.noData.description',
          emailText: 'beeoimpact.biodiversityTable.habitat.noData.emailText',
        },
      },
      nacecode: {
        title: 'beeoimpact.biodiversityTable.nacecode.title',
        code: 'beeoimpact.biodiversityTable.nacecode.code',
        name: 'beeoimpact.biodiversityTable.nacecode.name',
        noData: {
          title: 'beeoimpact.biodiversityTable.nacecode.noData.title',
          description: 'beeoimpact.biodiversityTable.nacecode.noData.description',
          emailText: 'beeoimpact.biodiversityTable.nacecode.noData.emailText',
        },
      },
      impactedHabitat: {
        title: 'beeoimpact.biodiversityTable.impactedHabitat.title',
        noData: {
          title: 'beeoimpact.biodiversityTable.impactedHabitat.noData.title',
          description: 'beeoimpact.biodiversityTable.impactedHabitat.noData.description',
          emailText: 'beeoimpact.biodiversityTable.impactedHabitat.noData.emailText',
        },
      },
      spectralIndices: {
        title: 'beeoimpact.biodiversityTable.spectralIndices.title',
        description: 'beeoimpact.biodiversityTable.spectralIndices.description',
        link: 'beeoimpact.biodiversityTable.spectralIndices.link',
        noData: 'beeoimpact.biodiversityTable.spectralIndices.noData',
        card: {
          title: 'beeoimpact.biodiversityTable.spectralIndices.card.title',
          description: 'beeoimpact.biodiversityTable.spectralIndices.card.description',
        },
        legendNotAvailable: 'beeoimpact.biodiversityTable.spectralIndices.legendNotAvailable',
        ndviLegends: {
          cloud: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.cloud',
          waterNoVegetation: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.waterNoVegetation',
          bareSoilUrbanAreas: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.bareSoilUrbanAreas',
          verySparseVegetation: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.verySparseVegetation',
          lowVegetationCover: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.lowVegetationCover',
          sparseGrassDrySoil: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.sparseGrassDrySoil',
          semiAridVegetation: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.semiAridVegetation',
          lowToModerateVegetation: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.lowToModerateVegetation',
          managedCropsGrasslands: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.managedCropsGrasslands',
          moderateVegetation: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.moderateVegetation',
          healthyVegetation: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.healthyVegetation',
          denseVegetation: 'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.denseVegetation',
          veryDenseHealthyVegetation:
            'beeoimpact.biodiversityTable.spectralIndices.ndviLegends.veryDenseHealthyVegetation',
        },
        ndwiLegends: {
          cloud: 'beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.cloud',
          dryAreasOrBareSoil: 'beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.dryAreasOrBareSoil',
          minimalWaterPresence: 'beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.minimalWaterPresence',
          lowWaterContentInVegetation:
            'beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.lowWaterContentInVegetation',
          wetSoilOrModerateWaterBodies:
            'beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.wetSoilOrModerateWaterBodies',
          highWaterContentOrOpenWater:
            'beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.highWaterContentOrOpenWater',
        },
      },
    },
    common: {
      breadcrumb: {
        mySites: 'beeoimpact.common.breadcrumb.mySites',
        biodiversity: 'beeoimpact.common.breadcrumb.biodiversity',
        pesticide: 'beeoimpact.common.breadcrumb.pesticide',
        site: 'beeoimpact.common.breadcrumb.site',
        initiatives: 'beeoimpact.common.breadcrumb.initiatives',
        potential: 'beeoimpact.common.breadcrumb.potential',
        sites: 'beeoimpact.common.breadcrumb.sites',
      },
      account: {
        settings: 'beeoimpact.common.account.settings',
        updates: 'beeoimpact.common.account.updates',
        logOut: 'beeoimpact.common.account.logOut',
        mainTitle: 'beeoimpact.common.account.mainTitle',
        subTitle: 'beeoimpact.common.account.subTitle',
        myAccountButton: 'beeoimpact.common.account.myAccountButton',
        passwordTitle: 'beeoimpact.common.account.passwordTitle',
        passwordSubtitle: 'beeoimpact.common.account.passwordSubtitle',
        currentPasswordTitle: 'beeoimpact.common.account.currentPasswordTitle',
        newPasswordTitle: 'beeoimpact.common.account.newPasswordTitle',
        confirmPasswordTitle: 'beeoimpact.common.account.confirmPasswordTitle',
        buttonSave: 'beeoimpact.common.account.buttonSave',
      },
      connection: {
        email: 'beeoimpact.common.connection.email',
        password: 'beeoimpact.common.connection.password',
        forgotPassword: 'beeoimpact.common.connection.forgotPassword',
        ongoingConnection: 'beeoimpact.common.connection.ongoingConnection',
        connection: 'beeoimpact.common.connection.connection',
      },
      marketing: {
        card: {
          pesticide: {
            text: 'beeoimpact.common.marketing.card.pesticide.text',
            contact: 'beeoimpact.common.marketing.card.pesticide.contact',
          },
          biodiversity: {
            text: 'beeoimpact.common.marketing.card.biodiversity.text',
            contact: 'beeoimpact.common.marketing.card.biodiversity.contact',
          },
          generalAnalysis: {
            text: 'beeoimpact.common.marketing.card.generalAnalysis.text',
            contact: 'beeoimpact.common.marketing.card.generalAnalysis.contact',
          },
        },
      },
      species: {
        ecoscore_mammals: 'beeoimpact.common.species.ecoscore_mammals',
        ecoscore_birds: 'beeoimpact.common.species.ecoscore_birds',
        ecoscore_worms: 'beeoimpact.common.species.ecoscore_worms',
        ecoscore_fishes: 'beeoimpact.common.species.ecoscore_fishes',
        ecoscore_bees: 'beeoimpact.common.species.ecoscore_bees',
        ecoscore_soil: 'beeoimpact.common.species.ecoscore_soil',
      },
      impactLevel: {
        low: 'beeoimpact.common.impactLevel.low',
        medium: 'beeoimpact.common.impactLevel.medium',
        high: 'beeoimpact.common.impactLevel.high',
        noData: 'beeoimpact.common.impactLevel.noData',
      },
    },
    dashboard: {
      title: {
        main: 'beeoimpact.dashboard.title.main',
        riskySites: 'beeoimpact.dashboard.title.riskySites',
        mostFrequentSpecies: 'beeoimpact.dashboard.title.mostFrequentSpecies',
        indicators: 'beeoimpact.dashboard.title.indicators',
        reserveType: 'beeoimpact.dashboard.title.reserveType',
        viewSites: 'beeoimpact.dashboard.title.viewSites',
      },
      subtitle: {
        numberOfNatura2000: 'beeoimpact.dashboard.subtitle.numberOfNatura2000',
        numberOfCdda: 'beeoimpact.dashboard.subtitle.numberOfCdda',
        closestReserve: 'beeoimpact.dashboard.subtitle.closestReserve',
      },
      definition: {
        beeoimpact: 'beeoimpact.dashboard.definition.beeoimpact',
      },
      trigger: {
        pesticides: 'beeoimpact.dashboard.trigger.pesticides',
        biodiv: 'beeoimpact.dashboard.trigger.biodiv',
      },
      measurementUnit: 'beeoimpact.dashboard.measurementUnit',
      types: {
        none: 'beeoimpact.dashboard.types.none',
        both: 'beeoimpact.dashboard.types.both',
      },
      concentricRisks: {
        risky: 'beeoimpact.dashboard.concentricRisks.risky',
        notRisky: 'beeoimpact.dashboard.concentricRisks.notRisky',
      },
      metrics: {
        title: 'beeoimpact.dashboard.metrics.title',
        description: 'beeoimpact.dashboard.metrics.description',
        titleThreat: 'beeoimpact.dashboard.metrics.titleThreat',
        sites: 'beeoimpact.dashboard.metrics.sites',
        notAdjacent: 'beeoimpact.dashboard.metrics.notAdjacent',
        adjacent: 'beeoimpact.dashboard.metrics.adjacent',
        adjacentSurface: 'beeoimpact.dashboard.metrics.adjacentSurface',
        in: 'beeoimpact.dashboard.metrics.in',
        inSurface: 'beeoimpact.dashboard.metrics.inSurface',
        totalIUCNRedList: 'beeoimpact.dashboard.metrics.totalIUCNRedList',
        subtitle: 'beeoimpact.dashboard.metrics.subtitle',
        pie: {
          notAdjacent: 'beeoimpact.dashboard.metrics.pie.notAdjacent',
          adjacent: 'beeoimpact.dashboard.metrics.pie.adjacent',
          in: 'beeoimpact.dashboard.metrics.pie.in',
        },
        card: {
          notAdjacent: {
            title: 'beeoimpact.dashboard.metrics.card.notAdjacent.title',
            description: 'beeoimpact.dashboard.metrics.card.notAdjacent.description',
          },
          adjacent: {
            title: 'beeoimpact.dashboard.metrics.card.adjacent.title',
            description: 'beeoimpact.dashboard.metrics.card.adjacent.description',
          },
          in: {
            title: 'beeoimpact.dashboard.metrics.card.in.title',
            description: 'beeoimpact.dashboard.metrics.card.in.description',
          },
          adjancentAndIn: {
            title: 'beeoimpact.dashboard.metrics.card.adjancentAndIn.title',
            description: 'beeoimpact.dashboard.metrics.card.adjancentAndIn.description',
          },
          species: {
            CR: {
              title: 'beeoimpact.dashboard.metrics.card.species.CR.title',
              description: 'beeoimpact.dashboard.metrics.card.species.CR.description',
            },
            EN: {
              title: 'beeoimpact.dashboard.metrics.card.species.EN.title',
              description: 'beeoimpact.dashboard.metrics.card.species.EN.description',
            },
            title: 'beeoimpact.dashboard.metrics.card.species.title',
            description: 'beeoimpact.dashboard.metrics.card.species.description',
          },
        },
        repartition: {
          title: 'beeoimpact.dashboard.metrics.repartition.title',
          subtitle: 'beeoimpact.dashboard.metrics.repartition.subtitle',
        },
      },
      greenCard: {
        title: 'beeoimpact.dashboard.greenCard.title',
        title2: 'beeoimpact.dashboard.greenCard.title2',
        button: 'beeoimpact.dashboard.greenCard.button',
      },
      pesticides: {
        title: 'beeoimpact.dashboard.pesticides.title',
        sites: {
          title: 'beeoimpact.dashboard.pesticides.sites.title',
          description: 'beeoimpact.dashboard.pesticides.sites.description',
          totalOfAllYourSites: 'beeoimpact.dashboard.pesticides.sites.totalOfAllYourSites',
        },
        sitesAtRisk: {
          title: 'beeoimpact.dashboard.pesticides.sitesAtRisk.title',
          description: 'beeoimpact.dashboard.pesticides.sitesAtRisk.description',
          seeSitesAtRisk: 'beeoimpact.dashboard.pesticides.sitesAtRisk.seeSitesAtRisk',
        },
        sitesWithPesticides: {
          title: 'beeoimpact.dashboard.pesticides.sitesWithPesticides.title',
          description: 'beeoimpact.dashboard.pesticides.sitesWithPesticides.description',
        },
        mostPesticidesFound: {
          title: 'beeoimpact.dashboard.pesticides.mostPesticidesFound.title',
          description: 'beeoimpact.dashboard.pesticides.mostPesticidesFound.description',
        },
        mostCommonCultures: {
          title: 'beeoimpact.dashboard.pesticides.mostCommonCultures.title',
          description: 'beeoimpact.dashboard.pesticides.mostCommonCultures.description',
        },
        impactedTaxons: {
          title: 'beeoimpact.dashboard.pesticides.impactedTaxons.title',
        },
      },
      groups: {
        CR: 'beeoimpact.dashboard.groups.CR',
        EN: 'beeoimpact.dashboard.groups.EN',
        VU: 'beeoimpact.dashboard.groups.VU',
        NT: 'beeoimpact.dashboard.groups.NT',
        LC: 'beeoimpact.dashboard.groups.LC',
      },
      breadcrumb: {
        beeoapps: 'beeoimpact.dashboard.breadcrumb.beeoapps',
        beeoimpact: 'beeoimpact.dashboard.breadcrumb.beeoimpact',
        dashboard: 'beeoimpact.dashboard.breadcrumb.dashboard',
      },
      indicators: {
        threatened: 'beeoimpact.dashboard.indicators.threatened',
        CR: 'beeoimpact.dashboard.indicators.CR',
        EN: 'beeoimpact.dashboard.indicators.EN',
        threatenedDefinition: 'beeoimpact.dashboard.indicators.threatenedDefinition',
        CRDefinition: 'beeoimpact.dashboard.indicators.CRDefinition',
        ENDefinition: 'beeoimpact.dashboard.indicators.ENDefinition',
      },
    },
    methodology: {
      title: 'beeoimpact.methodology.title',
      description: 'beeoimpact.methodology.description',
      introduction: {
        p1: 'beeoimpact.methodology.introduction.p1',
        p2: 'beeoimpact.methodology.introduction.p2',
        p3: 'beeoimpact.methodology.introduction.p3',
        ul: {
          li1: {
            title: 'beeoimpact.methodology.introduction.ul.li1.title',
            description: 'beeoimpact.methodology.introduction.ul.li1.description',
          },
          li2: {
            title: 'beeoimpact.methodology.introduction.ul.li2.title',
            description: 'beeoimpact.methodology.introduction.ul.li2.description',
          },
          li3: {
            title: 'beeoimpact.methodology.introduction.ul.li3.title',
            description: 'beeoimpact.methodology.introduction.ul.li3.description',
          },
        },
        p4: 'beeoimpact.methodology.introduction.p4',
        blockquote1: 'beeoimpact.methodology.introduction.blockquote1',
        blockquote2: 'beeoimpact.methodology.introduction.blockquote2',
      },
      generalAnalysis: {
        title: 'beeoimpact.methodology.generalAnalysis.title',
        description: 'beeoimpact.methodology.generalAnalysis.description',
        data: {
          scope: {
            title: 'beeoimpact.methodology.generalAnalysis.data.scope.title',
            description: 'beeoimpact.methodology.generalAnalysis.data.scope.description',
          },
          datasets: {
            title: 'beeoimpact.methodology.generalAnalysis.data.datasets.title',
            description: 'beeoimpact.methodology.generalAnalysis.data.datasets.description',
          },
        },
        section: {
          scores: {
            title: 'beeoimpact.methodology.generalAnalysis.section.scores.title',
          },
        },
      },
      biodiversityRisk: {
        title: 'beeoimpact.methodology.biodiversityRisk.title',
        description: 'beeoimpact.methodology.biodiversityRisk.description',
        data: {
          scope: {
            title: 'beeoimpact.methodology.biodiversityRisk.data.scope.title',
            description: 'beeoimpact.methodology.biodiversityRisk.data.scope.description',
          },
          score: {
            title: 'beeoimpact.methodology.biodiversityRisk.data.score.title',
            p1: 'beeoimpact.methodology.biodiversityRisk.data.score.p1',
            p2: 'beeoimpact.methodology.biodiversityRisk.data.score.p2',
            p3: 'beeoimpact.methodology.biodiversityRisk.data.score.p3',
          },
          areaOfInfluence: {
            title: 'beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.title',
            description: 'beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.description',
            subtitle: 'beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.subtitle',
            distances: {
              office: 'beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.office',
              manufacturing: 'beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.manufacturing',
              extraction: 'beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.extraction',
              agricultural: 'beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.agricultural',
            },
          },
          datasets: {
            title: 'beeoimpact.methodology.biodiversityRisk.data.datasets.title',
            description: 'beeoimpact.methodology.biodiversityRisk.data.datasets.description',
            description2: 'beeoimpact.methodology.biodiversityRisk.data.datasets.description2',
          },
        },
      },
      pesticidesRisks: {
        title: 'beeoimpact.methodology.pesticidesRisks.title',
        description: 'beeoimpact.methodology.pesticidesRisks.description',
        data: {
          scope: {
            title: 'beeoimpact.methodology.pesticidesRisks.data.scope.title',
            description: 'beeoimpact.methodology.pesticidesRisks.data.scope.description',
          },
          score: {
            title: 'beeoimpact.methodology.pesticidesRisks.data.score.title',
            description: 'beeoimpact.methodology.pesticidesRisks.data.score.description',
          },
          datasets: {
            title: 'beeoimpact.methodology.pesticidesRisks.data.datasets.title',
            description: 'beeoimpact.methodology.pesticidesRisks.data.datasets.description',
            libs1: 'beeoimpact.methodology.pesticidesRisks.data.datasets.libs1',
            libs2: 'beeoimpact.methodology.pesticidesRisks.data.datasets.libs2',
            libs3: 'beeoimpact.methodology.pesticidesRisks.data.datasets.libs3',
            libs4: 'beeoimpact.methodology.pesticidesRisks.data.datasets.libs4',
          },
        },
      },
      spectralIndices: {
        title: 'beeoimpact.methodology.spectralIndices.title',
        description: 'beeoimpact.methodology.spectralIndices.description',
        NDVI: {
          title: 'beeoimpact.methodology.spectralIndices.NDVI.title',
          definition: {
            p1: 'beeoimpact.methodology.spectralIndices.NDVI.definition.p1',
            p2: 'beeoimpact.methodology.spectralIndices.NDVI.definition.p2',
            p3: 'beeoimpact.methodology.spectralIndices.NDVI.definition.p3',
          },
        },
        NDWI: {
          title: 'beeoimpact.methodology.spectralIndices.NDWI.title',
          definition: {
            p1: 'beeoimpact.methodology.spectralIndices.NDWI.definition.p1',
            p2: 'beeoimpact.methodology.spectralIndices.NDWI.definition.p2',
          },
        },
        analysis: {
          title: 'beeoimpact.methodology.spectralIndices.analysis.title',
          example1: {
            title: 'beeoimpact.methodology.spectralIndices.analysis.example1.title',
            context: {
              title: 'beeoimpact.methodology.spectralIndices.analysis.example1.context.title',
              description: 'beeoimpact.methodology.spectralIndices.analysis.example1.context.description',
            },
            dataObserved: {
              title: 'beeoimpact.methodology.spectralIndices.analysis.example1.dataObserved.title',
              description: {
                p1: 'beeoimpact.methodology.spectralIndices.analysis.example1.dataObserved.description.p1',
                p2: 'beeoimpact.methodology.spectralIndices.analysis.example1.dataObserved.description.p2',
              },
            },
            interpretation: {
              title: 'beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.title',
              description: {
                p1: 'beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.description.p1',
                p2: 'beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.description.p2',
              },
              conclusion: 'beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.conclusion',
              action: {
                title: 'beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.action.title',
                description:
                  'beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.action.description',
              },
            },
          },
          example2: {
            title: 'beeoimpact.methodology.spectralIndices.analysis.example2.title',
            context: {
              title: 'beeoimpact.methodology.spectralIndices.analysis.example2.context.title',
              description: 'beeoimpact.methodology.spectralIndices.analysis.example2.context.description',
            },
            dataObserved: {
              title: 'beeoimpact.methodology.spectralIndices.analysis.example2.dataObserved.title',
              description: {
                p1: 'beeoimpact.methodology.spectralIndices.analysis.example2.dataObserved.description.p1',
                p2: 'beeoimpact.methodology.spectralIndices.analysis.example2.dataObserved.description.p2',
              },
            },
            interpretation: {
              title: 'beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.title',
              description: {
                p1: 'beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.description.p1',
                p2: 'beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.description.p2',
              },
              conclusion: 'beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.conclusion',
              action: {
                title: 'beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.action.title',
                description:
                  'beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.action.description',
              },
            },
          },
          example3: {
            title: 'beeoimpact.methodology.spectralIndices.analysis.example3.title',
            context: {
              title: 'beeoimpact.methodology.spectralIndices.analysis.example3.context.title',
              description: 'beeoimpact.methodology.spectralIndices.analysis.example3.context.description',
            },
            dataObserved: {
              title: 'beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.title',
              description: {
                spring: {
                  title:
                    'beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.spring.title',
                  p1: 'beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.spring.p1',
                  p2: 'beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.spring.p2',
                },
                summer: {
                  title:
                    'beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.summer.title',
                  p1: 'beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.summer.p1',
                  p2: 'beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.summer.p2',
                },
              },
            },
            interpretation: {
              title: 'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.title',
              description: {
                spring: {
                  title:
                    'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.spring.title',
                  p1: {
                    title:
                      'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.spring.p1.title',
                    description:
                      'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.spring.p1.description',
                  },
                  p2: {
                    title:
                      'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.spring.p2.title',
                    description:
                      'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.spring.p2.description',
                  },
                },
                summer: {
                  title:
                    'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.summer.title',
                  p1: {
                    title:
                      'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.summer.p1.title',
                    description:
                      'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.summer.p1.description',
                  },
                  p2: {
                    title:
                      'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.summer.p2.title',
                    description:
                      'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.summer.p2.description',
                  },
                },
              },
              action: {
                title: 'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.action.title',
                description:
                  'beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.action.description',
              },
            },
          },
        },
      },
      encoreMethology: {
        title: 'beeoimpact.methodology.encoreMethology.title',
        description: {
          p1: 'beeoimpact.methodology.encoreMethology.description.p1',
          p2: 'beeoimpact.methodology.encoreMethology.description.p2',
        },
        code: {
          title: 'beeoimpact.methodology.encoreMethology.code.title',
          description: {
            p1: 'beeoimpact.methodology.encoreMethology.code.description.p1',
            p2: 'beeoimpact.methodology.encoreMethology.code.description.p2',
            scale: {
              li1: 'beeoimpact.methodology.encoreMethology.code.description.scale.li1',
              li2: 'beeoimpact.methodology.encoreMethology.code.description.scale.li2',
              li3: 'beeoimpact.methodology.encoreMethology.code.description.scale.li3',
              li4: 'beeoimpact.methodology.encoreMethology.code.description.scale.li4',
              li5: 'beeoimpact.methodology.encoreMethology.code.description.scale.li5',
            },
          },
        },
      },
      sensitivity: {
        title: 'beeoimpact.methodology.sensitivity.title',
        description: 'beeoimpact.methodology.sensitivity.description',
        code: {
          title: 'beeoimpact.methodology.sensitivity.code.title',
          scale: {
            li1: 'beeoimpact.methodology.sensitivity.code.scale.li1',
            li2: 'beeoimpact.methodology.sensitivity.code.scale.li2',
            li3: 'beeoimpact.methodology.sensitivity.code.scale.li3',
            li4: 'beeoimpact.methodology.sensitivity.code.scale.li4',
            li5: 'beeoimpact.methodology.sensitivity.code.scale.li5',
          },
        },
      },
      habitats: {
        title: 'beeoimpact.methodology.habitats.title',
        description: 'beeoimpact.methodology.habitats.description',
      },
    },
    pesticidesTable: {
      pesticides: 'beeoimpact.pesticidesTable.pesticides',
      activeMatters: {
        table: {
          title: 'beeoimpact.pesticidesTable.activeMatters.table.title',
          header: {
            probabilityBom: 'beeoimpact.pesticidesTable.activeMatters.table.header.probabilityBom',
            probabilityPurchases: 'beeoimpact.pesticidesTable.activeMatters.table.header.probabilityPurchases',
            activeMatter: 'beeoimpact.pesticidesTable.activeMatters.table.header.activeMatter',
            waterRisk: 'beeoimpact.pesticidesTable.activeMatters.table.header.waterRisk',
            toxicity: 'beeoimpact.pesticidesTable.activeMatters.table.header.toxicity',
          },
          information: 'beeoimpact.pesticidesTable.activeMatters.table.information',
        },
      },
      cultures: {
        table: {
          title: 'beeoimpact.pesticidesTable.cultures.table.title',
          header: {
            cultureType: 'beeoimpact.pesticidesTable.cultures.table.header.cultureType',
            surface: 'beeoimpact.pesticidesTable.cultures.table.header.surface',
          },
        },
        pie: {
          title: 'beeoimpact.pesticidesTable.cultures.pie.title',
          description: 'beeoimpact.pesticidesTable.cultures.pie.description',
          others: 'beeoimpact.pesticidesTable.cultures.pie.others',
        },
      },
      speciesGroups: {
        mammals: 'beeoimpact.pesticidesTable.speciesGroups.mammals',
        birds: 'beeoimpact.pesticidesTable.speciesGroups.birds',
        worms: 'beeoimpact.pesticidesTable.speciesGroups.worms',
        fishes: 'beeoimpact.pesticidesTable.speciesGroups.fishes',
        bees: 'beeoimpact.pesticidesTable.speciesGroups.bees',
        soil: 'beeoimpact.pesticidesTable.speciesGroups.soil',
      },
      map: {
        possibleOrigin: 'beeoimpact.pesticidesTable.map.possibleOrigin',
      },
    },
    'protected-zones-map': {
      types: {
        A: 'beeoimpact.protected-zones-map.types.A',
        B: 'beeoimpact.protected-zones-map.types.B',
        C: 'beeoimpact.protected-zones-map.types.C',
      },
    },
    report: {
      filename: 'beeoimpact.report.filename',
      title: 'beeoimpact.report.title',
      influence: 'beeoimpact.report.influence',
      position: 'beeoimpact.report.position',
      cta: 'beeoimpact.report.cta',
      loading: 'beeoimpact.report.loading',
      highLevelAssessment: {
        title: 'beeoimpact.report.highLevelAssessment.title',
        description: 'beeoimpact.report.highLevelAssessment.description',
      },
      riskAssessment: {
        title: 'beeoimpact.report.riskAssessment.title',
        mainDescription: 'beeoimpact.report.riskAssessment.mainDescription',
        areaOfInfluenceDescription: 'beeoimpact.report.riskAssessment.areaOfInfluenceDescription',
        biodiversityScoreDescription: 'beeoimpact.report.riskAssessment.biodiversityScoreDescription',
        sensitiveAreasDescription: 'beeoimpact.report.riskAssessment.sensitiveAreasDescription',
        species: {
          title: 'beeoimpact.report.riskAssessment.species.title',
          description: 'beeoimpact.report.riskAssessment.species.description',
          dataSourcesDescription: 'beeoimpact.report.riskAssessment.species.dataSourcesDescription',
          dataExhaustivenessNote: 'beeoimpact.report.riskAssessment.species.dataExhaustivenessNote',
          endangeredSpeciesTitle: 'beeoimpact.report.riskAssessment.species.endangeredSpeciesTitle',
          gbifSpeciesDescription: 'beeoimpact.report.riskAssessment.species.gbifSpeciesDescription',
          prioritySpeciesDefinition: 'beeoimpact.report.riskAssessment.species.prioritySpeciesDefinition',
          threatenedSpeciesTitle: 'beeoimpact.report.riskAssessment.species.threatenedSpeciesTitle',
          threatenedSpeciesDescription: 'beeoimpact.report.riskAssessment.species.threatenedSpeciesDescription',
        },
      },
    },
    sensitiveAreas: {
      source: {
        n2k: 'beeoimpact.sensitiveAreas.source.n2k',
        cdda: 'beeoimpact.sensitiveAreas.source.cdda',
        padus: 'beeoimpact.sensitiveAreas.source.padus',
      },
    },
    site: {
      dashboard: {
        title: 'beeoimpact.site.dashboard.title',
        scoring: {
          pesticide: {
            title: 'beeoimpact.site.dashboard.scoring.pesticide.title',
            description: 'beeoimpact.site.dashboard.scoring.pesticide.description',
            legend: {
              low: 'beeoimpact.site.dashboard.scoring.pesticide.legend.low',
              medium: 'beeoimpact.site.dashboard.scoring.pesticide.legend.medium',
              high: 'beeoimpact.site.dashboard.scoring.pesticide.legend.high',
            },
          },
          biodiversity: {
            title: 'beeoimpact.site.dashboard.scoring.biodiversity.title',
            description: 'beeoimpact.site.dashboard.scoring.biodiversity.description',
            legend: {
              low: 'beeoimpact.site.dashboard.scoring.biodiversity.legend.low',
              medium: 'beeoimpact.site.dashboard.scoring.biodiversity.legend.medium',
              high: 'beeoimpact.site.dashboard.scoring.biodiversity.legend.high',
            },
          },
        },
        foundItems: 'beeoimpact.site.dashboard.foundItems',
        keyInformation: 'beeoimpact.site.dashboard.keyInformation',
        location: 'beeoimpact.site.dashboard.location',
        locationDescription: 'beeoimpact.site.dashboard.locationDescription',
        noPesticidesScore1: 'beeoimpact.site.dashboard.noPesticidesScore1',
        noPesticidesScore2: 'beeoimpact.site.dashboard.noPesticidesScore2',
        noFoundItems: 'beeoimpact.site.dashboard.noFoundItems',
        potential: 'beeoimpact.site.dashboard.potential',
        potentialDescription: 'beeoimpact.site.dashboard.potentialDescription',
        sitePotential: 'beeoimpact.site.dashboard.sitePotential',
      },
      pesticidesStatesRisks: {
        title: 'beeoimpact.site.pesticidesStatesRisks.title',
        titleDefinition: 'beeoimpact.site.pesticidesStatesRisks.titleDefinition',
        plots: 'beeoimpact.site.pesticidesStatesRisks.plots',
        crops: 'beeoimpact.site.pesticidesStatesRisks.crops',
        formulations: 'beeoimpact.site.pesticidesStatesRisks.formulations',
        activeMatters: 'beeoimpact.site.pesticidesStatesRisks.activeMatters',
      },
      biodiversityAround: {
        title: 'beeoimpact.site.biodiversityAround.title',
        titleDefinition: 'beeoimpact.site.biodiversityAround.titleDefinition',
        natura2000AndCddaTitle: 'beeoimpact.site.biodiversityAround.natura2000AndCddaTitle',
        natura2000: 'beeoimpact.site.biodiversityAround.natura2000',
        nationalDesignatedZones: 'beeoimpact.site.biodiversityAround.nationalDesignatedZones',
        species: 'beeoimpact.site.biodiversityAround.species',
        natura2000Definition: 'beeoimpact.site.biodiversityAround.natura2000Definition',
        cddaDefinition: 'beeoimpact.site.biodiversityAround.cddaDefinition',
        iucnRedListDefinition: 'beeoimpact.site.biodiversityAround.iucnRedListDefinition',
        biodiversityScore: 'beeoimpact.site.biodiversityAround.biodiversityScore',
        sensitiveAreaInDef: 'beeoimpact.site.biodiversityAround.sensitiveAreaInDef',
        sensitiveAreasIn1: 'beeoimpact.site.biodiversityAround.sensitiveAreasIn1',
        sensitiveAreasIn2: 'beeoimpact.site.biodiversityAround.sensitiveAreasIn2',
        sensitiveAreasIn2_other: 'beeoimpact.site.biodiversityAround.sensitiveAreasIn2_other',
        sensitiveAreasIn: 'beeoimpact.site.biodiversityAround.sensitiveAreasIn',
        sensitiveAreasIn_other: 'beeoimpact.site.biodiversityAround.sensitiveAreasIn_other',
        site: 'beeoimpact.site.biodiversityAround.site',
        influenceZoneAdj: 'beeoimpact.site.biodiversityAround.influenceZoneAdj',
        influenceZoneAdj_other: 'beeoimpact.site.biodiversityAround.influenceZoneAdj_other',
        influenceZoneAdjDef: 'beeoimpact.site.biodiversityAround.influenceZoneAdjDef',
        iucnRedListSpecies: 'beeoimpact.site.biodiversityAround.iucnRedListSpecies',
        iucnRedListSpeciesDef: 'beeoimpact.site.biodiversityAround.iucnRedListSpeciesDef',
        closestReserve: 'beeoimpact.site.biodiversityAround.closestReserve',
        closestReserveDef: 'beeoimpact.site.biodiversityAround.closestReserveDef',
        siteSensitiveAreaSurfaceRatioDef: 'beeoimpact.site.biodiversityAround.siteSensitiveAreaSurfaceRatioDef',
        siteSensitiveAreaSurfaceInRatioDef: 'beeoimpact.site.biodiversityAround.siteSensitiveAreaSurfaceInRatioDef',
        ratioTotalSurface: 'beeoimpact.site.biodiversityAround.ratioTotalSurface',
        ratioTotalSurfaceIn: 'beeoimpact.site.biodiversityAround.ratioTotalSurfaceIn',
        noPesticides: 'beeoimpact.site.biodiversityAround.noPesticides',
        noSensitiveAreas: 'beeoimpact.site.biodiversityAround.noSensitiveAreas',
        noPesticidesFound: 'beeoimpact.site.biodiversityAround.noPesticidesFound',
        noSensitiveAreasFound: 'beeoimpact.site.biodiversityAround.noSensitiveAreasFound',
        noKeyInformation: 'beeoimpact.site.biodiversityAround.noKeyInformation',
        threatenedSpecies: 'beeoimpact.site.biodiversityAround.threatenedSpecies',
        invasiveSpecies: 'beeoimpact.site.biodiversityAround.invasiveSpecies',
        clcGbifNoData: 'beeoimpact.site.biodiversityAround.clcGbifNoData',
        clcGbifNoData2: 'beeoimpact.site.biodiversityAround.clcGbifNoData2',
        generalAnalysisDefinition: 'beeoimpact.site.biodiversityAround.generalAnalysisDefinition',
        mostProbableHabitat: 'beeoimpact.site.biodiversityAround.mostProbableHabitat',
      },
      buttonDetails: 'beeoimpact.site.buttonDetails',
      measurementUnit: 'beeoimpact.site.measurementUnit',
      found: 'beeoimpact.site.found',
      foundItems: {
        fields: 'beeoimpact.site.foundItems.fields',
        activeMatters: 'beeoimpact.site.foundItems.activeMatters',
        sensitiveAreas: 'beeoimpact.site.foundItems.sensitiveAreas',
        sensitiveAreas_other: 'beeoimpact.site.foundItems.sensitiveAreas_other',
      },
      keyInformation: {
        is: 'beeoimpact.site.keyInformation.is',
        at: 'beeoimpact.site.keyInformation.at',
        presence: 'beeoimpact.site.keyInformation.presence',
        probablePresence: 'beeoimpact.site.keyInformation.probablePresence',
        animatAtRiskDescription: 'beeoimpact.site.keyInformation.animatAtRiskDescription',
        closestReserveDescription: 'beeoimpact.site.keyInformation.closestReserveDescription',
        probablePesticideDesc: 'beeoimpact.site.keyInformation.probablePesticideDesc',
        parcelsDesc: 'beeoimpact.site.keyInformation.parcelsDesc',
        activeMattersDesc: 'beeoimpact.site.keyInformation.activeMattersDesc',
        sensitiveAreaDesc: 'beeoimpact.site.keyInformation.sensitiveAreaDesc',
        siteIsIn: 'beeoimpact.site.keyInformation.siteIsIn',
      },
      score: {
        biodiversityScore: 'beeoimpact.site.score.biodiversityScore',
      },
      clcTypes: {
        urban: 'beeoimpact.site.clcTypes.urban',
        agricultural: 'beeoimpact.site.clcTypes.agricultural',
        vegetation: 'beeoimpact.site.clcTypes.vegetation',
        wetAreas: 'beeoimpact.site.clcTypes.wetAreas',
        waterBodies: 'beeoimpact.site.clcTypes.waterBodies',
      },
      cta: {
        title: 'beeoimpact.site.cta.title',
        text: 'beeoimpact.site.cta.text',
        button: 'beeoimpact.site.cta.button',
        subject: 'beeoimpact.site.cta.subject',
      },
      scoreText: {
        pesticide: 'beeoimpact.site.scoreText.pesticide',
        biodiv: 'beeoimpact.site.scoreText.biodiv',
      },
      attribution: {
        clc: 'beeoimpact.site.attribution.clc',
        gbif: 'beeoimpact.site.attribution.gbif',
      },
      layer: {
        clc: 'beeoimpact.site.layer.clc',
        gbif: 'beeoimpact.site.layer.gbif',
      },
    },
    sites: {
      scoring: {
        low: 'beeoimpact.sites.scoring.low',
        medium: 'beeoimpact.sites.scoring.medium',
        good: 'beeoimpact.sites.scoring.good',
        high: 'beeoimpact.sites.scoring.high',
      },
      definition: {
        pesticidesScoring: 'beeoimpact.sites.definition.pesticidesScoring',
        biodivScoring: 'beeoimpact.sites.definition.biodivScoring',
      },
      table: {
        header: {
          name: 'beeoimpact.sites.table.header.name',
          protectedArea: 'beeoimpact.sites.table.header.protectedArea',
          distance: 'beeoimpact.sites.table.header.distance',
          type: 'beeoimpact.sites.table.header.type',
          typeProtectedArea: 'beeoimpact.sites.table.header.typeProtectedArea',
          surface: 'beeoimpact.sites.table.header.surface',
          location: 'beeoimpact.sites.table.header.location',
          totalSurface: 'beeoimpact.sites.table.header.totalSurface',
          typeActivity: 'beeoimpact.sites.table.header.typeActivity',
          nbTotalSpecies: 'beeoimpact.sites.table.header.nbTotalSpecies',
          percentageAnalyzedBuffer: 'beeoimpact.sites.table.header.percentageAnalyzedBuffer',
          analyzedBuffer: 'beeoimpact.sites.table.header.analyzedBuffer',
          score: 'beeoimpact.sites.table.header.score',
          crops: 'beeoimpact.sites.table.header.crops',
          substances: 'beeoimpact.sites.table.header.substances',
        },
        type: {
          N2K: 'beeoimpact.sites.table.type.N2K',
          CDDA: 'beeoimpact.sites.table.type.CDDA',
          BOTH: 'beeoimpact.sites.table.type.BOTH',
        },
        location: {
          IN: 'beeoimpact.sites.table.location.IN',
          OUT: 'beeoimpact.sites.table.location.OUT',
          ADJACENT: 'beeoimpact.sites.table.location.ADJACENT',
        },
        buildingType: {
          OFFICE: 'beeoimpact.sites.table.buildingType.OFFICE',
          INDUSTRIAL: 'beeoimpact.sites.table.buildingType.INDUSTRIAL',
          AGRICULTURAL: 'beeoimpact.sites.table.buildingType.AGRICULTURAL',
          EXTRACTION: 'beeoimpact.sites.table.buildingType.EXTRACTION',
          MANUFACTURING: 'beeoimpact.sites.table.buildingType.MANUFACTURING',
        },
      },
      top: {
        risk: 'beeoimpact.sites.top.risk',
        sortByPesticide: 'beeoimpact.sites.top.sortByPesticide',
        sortByBiodiv: 'beeoimpact.sites.top.sortByBiodiv',
        mySites: 'beeoimpact.sites.top.mySites',
      },
      risk: {
        low: 'beeoimpact.sites.risk.low',
        high: 'beeoimpact.sites.risk.high',
      },
      labels: {
        pesticides: 'beeoimpact.sites.labels.pesticides',
        biodiversity: 'beeoimpact.sites.labels.biodiversity',
        threatenedSpecies: 'beeoimpact.sites.labels.threatenedSpecies',
      },
    },
    speciesDialog: {
      redListSpecies: 'beeoimpact.speciesDialog.redListSpecies',
      ofWhich: 'beeoimpact.speciesDialog.ofWhich',
    },
  },
  beeoinitiative: {
    common: {
      breadcrumb: {
        sites: 'beeoinitiative.common.breadcrumb.sites',
        site: 'beeoinitiative.common.breadcrumb.site',
        initiatives: 'beeoinitiative.common.breadcrumb.initiatives',
        back: 'beeoinitiative.common.breadcrumb.back',
      },
      header: {
        dashboard: 'beeoinitiative.common.header.dashboard',
        allMySites: 'beeoinitiative.common.header.allMySites',
        initiatives: 'beeoinitiative.common.header.initiatives',
      },
      sheet: {
        title: 'beeoinitiative.common.sheet.title',
        description: 'beeoinitiative.common.sheet.description',
      },
      type: {
        beeo: 'beeoinitiative.common.type.beeo',
        custom: 'beeoinitiative.common.type.custom',
      },
      idealInstallationPeriod: 'beeoinitiative.common.idealInstallationPeriod',
    },
    customInitiative: {
      title: 'beeoinitiative.customInitiative.title',
      yourInitiative: 'beeoinitiative.customInitiative.yourInitiative',
      chooseName: 'beeoinitiative.customInitiative.chooseName',
      continue: 'beeoinitiative.customInitiative.continue',
      presentationImage: 'beeoinitiative.customInitiative.presentationImage',
      features: 'beeoinitiative.customInitiative.features',
      completeInformation: 'beeoinitiative.customInitiative.completeInformation',
      validate: 'beeoinitiative.customInitiative.validate',
      afterThisStep: 'beeoinitiative.customInitiative.afterThisStep',
      submitInitiative: 'beeoinitiative.customInitiative.submitInitiative',
      submitIniativeSentence: 'beeoinitiative.customInitiative.submitIniativeSentence',
      initiativeName: 'beeoinitiative.customInitiative.initiativeName',
      shortDescription: 'beeoinitiative.customInitiative.shortDescription',
      cost: 'beeoinitiative.customInitiative.cost',
      implementation: 'beeoinitiative.customInitiative.implementation',
      submission: 'beeoinitiative.customInitiative.submission',
      submitMyInitiative: 'beeoinitiative.customInitiative.submitMyInitiative',
      errors: {
        name: 'beeoinitiative.customInitiative.errors.name',
        shortDescription: 'beeoinitiative.customInitiative.errors.shortDescription',
        cost: 'beeoinitiative.customInitiative.errors.cost',
        step: 'beeoinitiative.customInitiative.errors.step',
      },
    },
    dashboard: {
      mainTitle: 'beeoinitiative.dashboard.mainTitle',
      ongoingYear: 'beeoinitiative.dashboard.ongoingYear',
      place: 'beeoinitiative.dashboard.place',
      user: 'beeoinitiative.dashboard.user',
      user_one: 'beeoinitiative.dashboard.user_one',
      user_other: 'beeoinitiative.dashboard.user_other',
      site: 'beeoinitiative.dashboard.site',
      site_one: 'beeoinitiative.dashboard.site_one',
      site_other: 'beeoinitiative.dashboard.site_other',
      risk: 'beeoinitiative.dashboard.risk',
      takenAction: 'beeoinitiative.dashboard.takenAction',
      takenAction_one: 'beeoinitiative.dashboard.takenAction_one',
      takenAction_other: 'beeoinitiative.dashboard.takenAction_other',
      plannedAction: 'beeoinitiative.dashboard.plannedAction',
      plannedAction_one: 'beeoinitiative.dashboard.plannedAction_one',
      plannedAction_other: 'beeoinitiative.dashboard.plannedAction_other',
      sitesWithAtLeastOneTakenInitiative: 'beeoinitiative.dashboard.sitesWithAtLeastOneTakenInitiative',
      aTotalScoreOnAllYourSites1: 'beeoinitiative.dashboard.aTotalScoreOnAllYourSites1',
      aTotalScoreOnAllYourSites2: 'beeoinitiative.dashboard.aTotalScoreOnAllYourSites2',
      bestSitesTotal1: 'beeoinitiative.dashboard.bestSitesTotal1',
      bestSitesTotal2: 'beeoinitiative.dashboard.bestSitesTotal2',
      mostRecentTakenActions: 'beeoinitiative.dashboard.mostRecentTakenActions',
      mostRecentTakenActionsTitle: 'beeoinitiative.dashboard.mostRecentTakenActionsTitle',
      takenActionsByCategory: 'beeoinitiative.dashboard.takenActionsByCategory',
      types: {
        managementBiodiversity: 'beeoinitiative.dashboard.types.managementBiodiversity',
        infrastructure: 'beeoinitiative.dashboard.types.infrastructure',
        awareness: 'beeoinitiative.dashboard.types.awareness',
        sustainableMaintenance: 'beeoinitiative.dashboard.types.sustainableMaintenance',
      },
      allMySites: 'beeoinitiative.dashboard.allMySites',
      seeAllSites: 'beeoinitiative.dashboard.seeAllSites',
      seeConcertedSites: 'beeoinitiative.dashboard.seeConcertedSites',
      learnMore: 'beeoinitiative.dashboard.learnMore',
      totalScore: 'beeoinitiative.dashboard.totalScore',
      evaluateRisks: {
        title: 'beeoinitiative.dashboard.evaluateRisks.title',
        description1: 'beeoinitiative.dashboard.evaluateRisks.description1',
        description2: 'beeoinitiative.dashboard.evaluateRisks.description2',
        button: 'beeoinitiative.dashboard.evaluateRisks.button',
      },
      threeCurrentObjectivesTitle: 'beeoinitiative.dashboard.threeCurrentObjectivesTitle',
      threeCurrentObjectivesTitleSubtitle: 'beeoinitiative.dashboard.threeCurrentObjectivesTitleSubtitle',
      takeAction: {
        title: 'beeoinitiative.dashboard.takeAction.title',
        subtitle: 'beeoinitiative.dashboard.takeAction.subtitle',
        link: 'beeoinitiative.dashboard.takeAction.link',
      },
      budget: {
        title: 'beeoinitiative.dashboard.budget.title',
        subtitle: 'beeoinitiative.dashboard.budget.subtitle',
        importance: 'beeoinitiative.dashboard.budget.importance',
      },
      takenInits: {
        title: 'beeoinitiative.dashboard.takenInits.title',
        subtitle: 'beeoinitiative.dashboard.takenInits.subtitle',
        link: 'beeoinitiative.dashboard.takenInits.link',
        zeroTakenInits: 'beeoinitiative.dashboard.takenInits.zeroTakenInits',
        oneToThreeTakenInits: 'beeoinitiative.dashboard.takenInits.oneToThreeTakenInits',
        moreThanThreeTakenInits: 'beeoinitiative.dashboard.takenInits.moreThanThreeTakenInits',
      },
      rapports: {
        title: 'beeoinitiative.dashboard.rapports.title',
        firstSubtitle: 'beeoinitiative.dashboard.rapports.firstSubtitle',
        secondSubtitle: 'beeoinitiative.dashboard.rapports.secondSubtitle',
        link: 'beeoinitiative.dashboard.rapports.link',
      },
      plannedInits: {
        title: 'beeoinitiative.dashboard.plannedInits.title',
        subTitle: 'beeoinitiative.dashboard.plannedInits.subTitle',
      },
      chartData: {
        oneToThree: 'beeoinitiative.dashboard.chartData.oneToThree',
        moreThanThree: 'beeoinitiative.dashboard.chartData.moreThanThree',
      },
      year: 'beeoinitiative.dashboard.year',
      exportResults: 'beeoinitiative.dashboard.exportResults',
      manageBudget: 'beeoinitiative.dashboard.manageBudget',
      breadcrumb: {
        beeoapps: 'beeoinitiative.dashboard.breadcrumb.beeoapps',
        beeoinitiative: 'beeoinitiative.dashboard.breadcrumb.beeoinitiative',
      },
      used: 'beeoinitiative.dashboard.used',
      percentage: 'beeoinitiative.dashboard.percentage',
      planned: 'beeoinitiative.dashboard.planned',
      spent: 'beeoinitiative.dashboard.spent',
      initiative_one: 'beeoinitiative.dashboard.initiative_one',
      initiative_other: 'beeoinitiative.dashboard.initiative_other',
      nomberOfSites: 'beeoinitiative.dashboard.nomberOfSites',
    },
    initiativeDetailPage: {
      breadcrumb: 'beeoinitiative.initiativeDetailPage.breadcrumb',
      impact: {
        impactsOnBiodiversity: 'beeoinitiative.initiativeDetailPage.impact.impactsOnBiodiversity',
        installationPeriod: 'beeoinitiative.initiativeDetailPage.impact.installationPeriod',
        implement: 'beeoinitiative.initiativeDetailPage.impact.implement',
        attentionPoints: 'beeoinitiative.initiativeDetailPage.impact.attentionPoints',
        impact: 'beeoinitiative.initiativeDetailPage.impact.impact',
        cost: 'beeoinitiative.initiativeDetailPage.impact.cost',
        lowImpact1: 'beeoinitiative.initiativeDetailPage.impact.lowImpact1',
        lowImpact2: 'beeoinitiative.initiativeDetailPage.impact.lowImpact2',
        moderateImpact: 'beeoinitiative.initiativeDetailPage.impact.moderateImpact',
        highImpact1: 'beeoinitiative.initiativeDetailPage.impact.highImpact1',
        highImpact2: 'beeoinitiative.initiativeDetailPage.impact.highImpact2',
        na: 'beeoinitiative.initiativeDetailPage.impact.na',
      },
      cost: {
        lowCost1: 'beeoinitiative.initiativeDetailPage.cost.lowCost1',
        lowCost2: 'beeoinitiative.initiativeDetailPage.cost.lowCost2',
        moderateCost: 'beeoinitiative.initiativeDetailPage.cost.moderateCost',
        highCost1: 'beeoinitiative.initiativeDetailPage.cost.highCost1',
        highCost2: 'beeoinitiative.initiativeDetailPage.cost.highCost2',
        na: 'beeoinitiative.initiativeDetailPage.cost.na',
      },
      month: {
        january: 'beeoinitiative.initiativeDetailPage.month.january',
        february: 'beeoinitiative.initiativeDetailPage.month.february',
        march: 'beeoinitiative.initiativeDetailPage.month.march',
        april: 'beeoinitiative.initiativeDetailPage.month.april',
        may: 'beeoinitiative.initiativeDetailPage.month.may',
        june: 'beeoinitiative.initiativeDetailPage.month.june',
        july: 'beeoinitiative.initiativeDetailPage.month.july',
        august: 'beeoinitiative.initiativeDetailPage.month.august',
        september: 'beeoinitiative.initiativeDetailPage.month.september',
        october: 'beeoinitiative.initiativeDetailPage.month.october',
        november: 'beeoinitiative.initiativeDetailPage.month.november',
        december: 'beeoinitiative.initiativeDetailPage.month.december',
        independent: 'beeoinitiative.initiativeDetailPage.month.independent',
      },
      addInitiative: {
        planAnInitiative: 'beeoinitiative.initiativeDetailPage.addInitiative.planAnInitiative',
        selectedInitiative: 'beeoinitiative.initiativeDetailPage.addInitiative.selectedInitiative',
        selectASite: 'beeoinitiative.initiativeDetailPage.addInitiative.selectASite',
        selectSite: 'beeoinitiative.initiativeDetailPage.addInitiative.selectSite',
        searchASite: 'beeoinitiative.initiativeDetailPage.addInitiative.searchASite',
        loading: 'beeoinitiative.initiativeDetailPage.addInitiative.loading',
        noSiteFound: 'beeoinitiative.initiativeDetailPage.addInitiative.noSiteFound',
        choseADate: 'beeoinitiative.initiativeDetailPage.addInitiative.choseADate',
        pickADate: 'beeoinitiative.initiativeDetailPage.addInitiative.pickADate',
        estimatedCost: 'beeoinitiative.initiativeDetailPage.addInitiative.estimatedCost',
        plan: 'beeoinitiative.initiativeDetailPage.addInitiative.plan',
      },
      veryLow: 'beeoinitiative.initiativeDetailPage.veryLow',
      low: 'beeoinitiative.initiativeDetailPage.low',
      moderate: 'beeoinitiative.initiativeDetailPage.moderate',
      high: 'beeoinitiative.initiativeDetailPage.high',
      veryHigh: 'beeoinitiative.initiativeDetailPage.veryHigh',
      planThisInitiative: 'beeoinitiative.initiativeDetailPage.planThisInitiative',
      addThisInitiativeToSite: 'beeoinitiative.initiativeDetailPage.addThisInitiativeToSite',
      plan: 'beeoinitiative.initiativeDetailPage.plan',
      concernedSites: 'beeoinitiative.initiativeDetailPage.concernedSites',
      thisInitiativeHadBeenTakenBy: 'beeoinitiative.initiativeDetailPage.thisInitiativeHadBeenTakenBy',
      thisInitiativeHadBeenTakenBy_zero: 'beeoinitiative.initiativeDetailPage.thisInitiativeHadBeenTakenBy_zero',
      thisInitiativeHadBeenTakenBy_one: 'beeoinitiative.initiativeDetailPage.thisInitiativeHadBeenTakenBy_one',
      thisInitiativeHadBeenTakenBy_other: 'beeoinitiative.initiativeDetailPage.thisInitiativeHadBeenTakenBy_other',
      forMoreInformation: 'beeoinitiative.initiativeDetailPage.forMoreInformation',
      contactUs: 'beeoinitiative.initiativeDetailPage.contactUs',
      na: 'beeoinitiative.initiativeDetailPage.na',
      planned: 'beeoinitiative.initiativeDetailPage.planned',
      modify: 'beeoinitiative.initiativeDetailPage.modify',
      modifyInitiative: 'beeoinitiative.initiativeDetailPage.modifyInitiative',
    },
    initiativesPage: {
      page: {
        title: 'beeoinitiative.initiativesPage.page.title',
        titleAll: 'beeoinitiative.initiativesPage.page.titleAll',
        contactUs: 'beeoinitiative.initiativesPage.page.contactUs',
        total: 'beeoinitiative.initiativesPage.page.total',
        filterInitiatives: 'beeoinitiative.initiativesPage.page.filterInitiatives',
        searchInitiative: 'beeoinitiative.initiativesPage.page.searchInitiative',
        positiveImpact: 'beeoinitiative.initiativesPage.page.positiveImpact',
        impact: 'beeoinitiative.initiativesPage.page.impact',
        cost: 'beeoinitiative.initiativesPage.page.cost',
        initiativeCategory: 'beeoinitiative.initiativesPage.page.initiativeCategory',
        installationPeriod: 'beeoinitiative.initiativesPage.page.installationPeriod',
        initiative: 'beeoinitiative.initiativesPage.page.initiative',
        initiative_one: 'beeoinitiative.initiativesPage.page.initiative_one',
        initiative_other: 'beeoinitiative.initiativesPage.page.initiative_other',
        detailButton: 'beeoinitiative.initiativesPage.page.detailButton',
        applyButton: 'beeoinitiative.initiativesPage.page.applyButton',
        siteCategory: 'beeoinitiative.initiativesPage.page.siteCategory',
        noInitiative: 'beeoinitiative.initiativesPage.page.noInitiative',
        resetFilters: 'beeoinitiative.initiativesPage.page.resetFilters',
      },
      impact: {
        lowImpact1: 'beeoinitiative.initiativesPage.impact.lowImpact1',
        lowImpact2: 'beeoinitiative.initiativesPage.impact.lowImpact2',
        moderateImpact: 'beeoinitiative.initiativesPage.impact.moderateImpact',
        highImpact1: 'beeoinitiative.initiativesPage.impact.highImpact1',
        highImpact2: 'beeoinitiative.initiativesPage.impact.highImpact2',
      },
      cost: {
        lowCost1: 'beeoinitiative.initiativesPage.cost.lowCost1',
        lowCost2: 'beeoinitiative.initiativesPage.cost.lowCost2',
        moderateCost: 'beeoinitiative.initiativesPage.cost.moderateCost',
        highCost1: 'beeoinitiative.initiativesPage.cost.highCost1',
        highCost2: 'beeoinitiative.initiativesPage.cost.highCost2',
      },
      month: {
        january: 'beeoinitiative.initiativesPage.month.january',
        february: 'beeoinitiative.initiativesPage.month.february',
        march: 'beeoinitiative.initiativesPage.month.march',
        april: 'beeoinitiative.initiativesPage.month.april',
        may: 'beeoinitiative.initiativesPage.month.may',
        june: 'beeoinitiative.initiativesPage.month.june',
        july: 'beeoinitiative.initiativesPage.month.july',
        august: 'beeoinitiative.initiativesPage.month.august',
        september: 'beeoinitiative.initiativesPage.month.september',
        october: 'beeoinitiative.initiativesPage.month.october',
        november: 'beeoinitiative.initiativesPage.month.november',
        december: 'beeoinitiative.initiativesPage.month.december',
        independent: 'beeoinitiative.initiativesPage.month.independent',
      },
      siteCategory: {
        urbanZone: 'beeoinitiative.initiativesPage.siteCategory.urbanZone',
        agriculturalZone: 'beeoinitiative.initiativesPage.siteCategory.agriculturalZone',
        forestZone: 'beeoinitiative.initiativesPage.siteCategory.forestZone',
        mixedZone: 'beeoinitiative.initiativesPage.siteCategory.mixedZone',
      },
      initiativeCategory: {
        greenSpacesMaintenance: 'beeoinitiative.initiativesPage.initiativeCategory.greenSpacesMaintenance',
        agriculture: 'beeoinitiative.initiativesPage.initiativeCategory.agriculture',
        impactReduction: 'beeoinitiative.initiativesPage.initiativeCategory.impactReduction',
        development: 'beeoinitiative.initiativesPage.initiativeCategory.development',
        awareness: 'beeoinitiative.initiativesPage.initiativeCategory.awareness',
        managementBiodiversity: 'beeoinitiative.initiativesPage.initiativeCategory.managementBiodiversity',
        custom: 'beeoinitiative.initiativesPage.initiativeCategory.custom',
      },
      planificationOfInit: {
        title: 'beeoinitiative.initiativesPage.planificationOfInit.title',
      },
    },
    newInitiative: {
      title: 'beeoinitiative.newInitiative.title',
      initiative: 'beeoinitiative.newInitiative.initiative',
      date: 'beeoinitiative.newInitiative.date',
      status: 'beeoinitiative.newInitiative.status',
      save: 'beeoinitiative.newInitiative.save',
      toDo: 'beeoinitiative.newInitiative.toDo',
      done: 'beeoinitiative.newInitiative.done',
    },
    site: {
      backToMySites: 'beeoinitiative.site.backToMySites',
      title: 'beeoinitiative.site.title',
      siteCharacteristics: 'beeoinitiative.site.siteCharacteristics',
      firstQuestion: 'beeoinitiative.site.firstQuestion',
      secondQuestion: 'beeoinitiative.site.secondQuestion',
      yes: 'beeoinitiative.site.yes',
      no: 'beeoinitiative.site.no',
      comment: 'beeoinitiative.site.comment',
      newInitiative: 'beeoinitiative.site.newInitiative',
      takenInitiative: 'beeoinitiative.site.takenInitiative',
      takenInitiative_one: 'beeoinitiative.site.takenInitiative_one',
      takenInitiative_other: 'beeoinitiative.site.takenInitiative_other',
      takenInitiativesDescription: 'beeoinitiative.site.takenInitiativesDescription',
      plannedInitiative: 'beeoinitiative.site.plannedInitiative',
      plannedInitiative_one: 'beeoinitiative.site.plannedInitiative_one',
      plannedInitiative_other: 'beeoinitiative.site.plannedInitiative_other',
      plannedInitiativesDescription: 'beeoinitiative.site.plannedInitiativesDescription',
      takenAgo: 'beeoinitiative.site.takenAgo',
      month: 'beeoinitiative.site.month',
      plannedAgo: 'beeoinitiative.site.plannedAgo',
      on: 'beeoinitiative.site.on',
      takenInitiativesPageDescription: 'beeoinitiative.site.takenInitiativesPageDescription',
      changeAnswers: 'beeoinitiative.site.changeAnswers',
      questions: {
        title: 'beeoinitiative.site.questions.title',
        description: 'beeoinitiative.site.questions.description',
        button: 'beeoinitiative.site.questions.button',
        success: 'beeoinitiative.site.questions.success',
      },
      initiativesCount: 'beeoinitiative.site.initiativesCount',
      seeMore: 'beeoinitiative.site.seeMore',
      lastAudit: 'beeoinitiative.site.lastAudit',
      rateOfVeg: 'beeoinitiative.site.rateOfVeg',
      surfaceBioCoef: 'beeoinitiative.site.surfaceBioCoef',
      animals: {
        Mammals: 'beeoinitiative.site.animals.Mammals',
        Birds: 'beeoinitiative.site.animals.Birds',
        Insects: 'beeoinitiative.site.animals.Insects',
        Amphibians: 'beeoinitiative.site.animals.Amphibians',
        Fish: 'beeoinitiative.site.animals.Fish',
        Plants: 'beeoinitiative.site.animals.Plants',
        Trees: 'beeoinitiative.site.animals.Trees',
      },
      allAudits: 'beeoinitiative.site.allAudits',
      greenCard: {
        siteAnalyse: 'beeoinitiative.site.greenCard.siteAnalyse',
        firstSentence: 'beeoinitiative.site.greenCard.firstSentence',
        secondSentence: 'beeoinitiative.site.greenCard.secondSentence',
        isConsidered: 'beeoinitiative.site.greenCard.isConsidered',
        isNotConsidered: 'beeoinitiative.site.greenCard.isNotConsidered',
        riskySite2: 'beeoinitiative.site.greenCard.riskySite2',
        species: 'beeoinitiative.site.greenCard.species',
        endageredSpecies: 'beeoinitiative.site.greenCard.endageredSpecies',
        yourSite: 'beeoinitiative.site.greenCard.yourSite',
      },
      initiatives: 'beeoinitiative.site.initiatives',
      initiatives_one: 'beeoinitiative.site.initiatives_one',
      stillXToGo: 'beeoinitiative.site.stillXToGo',
      stillXToGo_one: 'beeoinitiative.site.stillXToGo_one',
      planAnInitative: 'beeoinitiative.site.planAnInitative',
      initiativesAchieved: 'beeoinitiative.site.initiativesAchieved',
      initiativesAchieved_one: 'beeoinitiative.site.initiativesAchieved_one',
      allAchived: 'beeoinitiative.site.allAchived',
      totalBudget: 'beeoinitiative.site.totalBudget',
      spentVSplanned: 'beeoinitiative.site.spentVSplanned',
      pts: 'beeoinitiative.site.pts',
      suggestedInits: 'beeoinitiative.site.suggestedInits',
      answerInits: 'beeoinitiative.site.answerInits',
      availableInits: 'beeoinitiative.site.availableInits',
      forYourSite: 'beeoinitiative.site.forYourSite',
      modifyMyAnswers: 'beeoinitiative.site.modifyMyAnswers',
      indigenous: 'beeoinitiative.site.indigenous',
      local: 'beeoinitiative.site.local',
      close: 'beeoinitiative.site.close',
      seeLess: 'beeoinitiative.site.seeLess',
      error: 'beeoinitiative.site.error',
      noTakenInits: {
        text1: 'beeoinitiative.site.noTakenInits.text1',
        text2: 'beeoinitiative.site.noTakenInits.text2',
        text3: 'beeoinitiative.site.noTakenInits.text3',
      },
      NoPlannedInits: {
        text1: 'beeoinitiative.site.NoPlannedInits.text1',
        text2: 'beeoinitiative.site.NoPlannedInits.text2',
      },
      imageText: {
        title: 'beeoinitiative.site.imageText.title',
        description1: 'beeoinitiative.site.imageText.description1',
        description2: 'beeoinitiative.site.imageText.description2',
      },
      closeSheet: {
        title: 'beeoinitiative.site.closeSheet.title',
        realCost: 'beeoinitiative.site.closeSheet.realCost',
        button: 'beeoinitiative.site.closeSheet.button',
      },
    },
    sites: {
      title: 'beeoinitiative.sites.title',
      searchSites: 'beeoinitiative.sites.searchSites',
      lastInitiativeTaken: 'beeoinitiative.sites.lastInitiativeTaken',
      noTakenInitiative: 'beeoinitiative.sites.noTakenInitiative',
      all: 'beeoinitiative.sites.all',
      sitesAtRisk: 'beeoinitiative.sites.sitesAtRisk',
      initiativesDone: 'beeoinitiative.sites.initiativesDone',
      totalBudget: 'beeoinitiative.sites.totalBudget',
      score: 'beeoinitiative.sites.score',
      display: 'beeoinitiative.sites.display',
      siteSorting: 'beeoinitiative.sites.siteSorting',
      increasingInits: 'beeoinitiative.sites.increasingInits',
      decresingInits: 'beeoinitiative.sites.decresingInits',
      activeSites: 'beeoinitiative.sites.activeSites',
      inactiveSites: 'beeoinitiative.sites.inactiveSites',
      table: {
        siteName: 'beeoinitiative.sites.table.siteName',
        takenInitiatives: 'beeoinitiative.sites.table.takenInitiatives',
        plannedInitiatives: 'beeoinitiative.sites.table.plannedInitiatives',
        score: 'beeoinitiative.sites.table.score',
        totalBudget: 'beeoinitiative.sites.table.totalBudget',
        country: 'beeoinitiative.sites.table.country',
        BE: 'beeoinitiative.sites.table.BE',
        FR: 'beeoinitiative.sites.table.FR',
        IT: 'beeoinitiative.sites.table.IT',
      },
      initiatives: 'beeoinitiative.sites.initiatives',
      pts: 'beeoinitiative.sites.pts',
      details: 'beeoinitiative.sites.details',
      total: 'beeoinitiative.sites.total',
    },
  },
  beeomonitoring: {
    biodiversity: {
      graphs: {
        count_by_period: {
          title: 'beeomonitoring.biodiversity.graphs.count_by_period.title',
          description: 'beeomonitoring.biodiversity.graphs.count_by_period.description',
          axis: {
            distinct_idt_count: 'beeomonitoring.biodiversity.graphs.count_by_period.axis.distinct_idt_count',
            mean_idt_count: 'beeomonitoring.biodiversity.graphs.count_by_period.axis.mean_idt_count',
          },
          legend: {
            y: 'beeomonitoring.biodiversity.graphs.count_by_period.legend.y',
          },
        },
        count_by_split: {
          title: 'beeomonitoring.biodiversity.graphs.count_by_split.title',
          description: 'beeomonitoring.biodiversity.graphs.count_by_split.description',
        },
        map_by_category: {
          title: 'beeomonitoring.biodiversity.graphs.map_by_category.title',
          description: 'beeomonitoring.biodiversity.graphs.map_by_category.description',
        },
        map_by_family: {
          title: 'beeomonitoring.biodiversity.graphs.map_by_family.title',
          description: 'beeomonitoring.biodiversity.graphs.map_by_family.description',
        },
      },
      definition: {
        content: {
          para1: 'beeomonitoring.biodiversity.definition.content.para1',
          para2: 'beeomonitoring.biodiversity.definition.content.para2',
          para3: 'beeomonitoring.biodiversity.definition.content.para3',
        },
        description: 'beeomonitoring.biodiversity.definition.description',
      },
      species: {
        title: 'beeomonitoring.biodiversity.species.title',
        description: 'beeomonitoring.biodiversity.species.description',
        type: {
          dominant: 'beeomonitoring.biodiversity.species.type.dominant',
          significant: 'beeomonitoring.biodiversity.species.type.significant',
          trace: 'beeomonitoring.biodiversity.species.type.trace',
        },
        titleType: {
          dominant: 'beeomonitoring.biodiversity.species.titleType.dominant',
          significant: 'beeomonitoring.biodiversity.species.titleType.significant',
          trace: 'beeomonitoring.biodiversity.species.titleType.trace',
        },
      },
    },
    common: {
      menu: {
        sites: 'beeomonitoring.common.menu.sites',
      },
      contact: 'beeomonitoring.common.contact',
      definition: 'beeomonitoring.common.definition',
      breadcrumb: {
        biodiversity: 'beeomonitoring.common.breadcrumb.biodiversity',
        pesticides: 'beeomonitoring.common.breadcrumb.pesticides',
        metals: 'beeomonitoring.common.breadcrumb.metals',
      },
      graph: {
        legend: {
          local_value: 'beeomonitoring.common.graph.legend.local_value',
          average_value: 'beeomonitoring.common.graph.legend.average_value',
        },
        clickToDetail: 'beeomonitoring.common.graph.clickToDetail',
      },
    },
    diofur: {
      graph: {
        title: 'beeomonitoring.diofur.graph.title',
        description: 'beeomonitoring.diofur.graph.description',
        legend: 'beeomonitoring.diofur.graph.legend',
      },
      definition: {
        title: 'beeomonitoring.diofur.definition.title',
        content: {
          para_1: 'beeomonitoring.diofur.definition.content.para_1',
        },
      },
      table: {
        title: 'beeomonitoring.diofur.table.title',
        description: 'beeomonitoring.diofur.table.description',
        type: {
          PCDD: 'beeomonitoring.diofur.table.type.PCDD',
          PCDF: 'beeomonitoring.diofur.table.type.PCDF',
          'PCBP-DL': 'beeomonitoring.diofur.table.type.PCBP-DL',
        },
      },
    },
    metals: {
      title: 'beeomonitoring.metals.title',
      description: 'beeomonitoring.metals.description',
      definition: {
        content: {
          para1: 'beeomonitoring.metals.definition.content.para1',
          para2: 'beeomonitoring.metals.definition.content.para2',
          para3: 'beeomonitoring.metals.definition.content.para3',
        },
      },
      graph: {
        count_barchart: {
          title: 'beeomonitoring.metals.graph.count_barchart.title',
          description: 'beeomonitoring.metals.graph.count_barchart.description',
          legend: {
            y: 'beeomonitoring.metals.graph.count_barchart.legend.y',
          },
        },
        legend: {
          y: 'beeomonitoring.metals.graph.legend.y',
        },
      },
    },
    pah: {
      graph: {
        title: 'beeomonitoring.pah.graph.title',
        description: 'beeomonitoring.pah.graph.description',
      },
      definition: {
        title: 'beeomonitoring.pah.definition.title',
        content: {
          para_1: 'beeomonitoring.pah.definition.content.para_1',
        },
      },
      table: {
        title: 'beeomonitoring.pah.table.title',
        description: 'beeomonitoring.pah.table.description',
      },
    },
    pcb: {
      graph: {
        title: 'beeomonitoring.pcb.graph.title',
        description: 'beeomonitoring.pcb.graph.description',
      },
      definition: {
        title: 'beeomonitoring.pcb.definition.title',
        content: {
          para_1: 'beeomonitoring.pcb.definition.content.para_1',
        },
      },
      table: {
        title: 'beeomonitoring.pcb.table.title',
        description: 'beeomonitoring.pcb.table.description',
      },
    },
    pesticides: {
      title: 'beeomonitoring.pesticides.title',
      description: 'beeomonitoring.pesticides.description',
      definition: {
        content: {
          para1: 'beeomonitoring.pesticides.definition.content.para1',
          para2: 'beeomonitoring.pesticides.definition.content.para2',
          para3: 'beeomonitoring.pesticides.definition.content.para3',
        },
      },
      graph: {
        count_barchart: {
          title: 'beeomonitoring.pesticides.graph.count_barchart.title',
          description: 'beeomonitoring.pesticides.graph.count_barchart.description',
          legend: {
            y: 'beeomonitoring.pesticides.graph.count_barchart.legend.y',
          },
        },
        count_stacked_barchart: {
          title: 'beeomonitoring.pesticides.graph.count_stacked_barchart.title',
          description: 'beeomonitoring.pesticides.graph.count_stacked_barchart.description',
          legend: {
            y: 'beeomonitoring.pesticides.graph.count_stacked_barchart.legend.y',
          },
        },
        legend: {
          y: 'beeomonitoring.pesticides.graph.legend.y',
        },
      },
      legend: {
        title: 'beeomonitoring.pesticides.legend.title',
        description: 'beeomonitoring.pesticides.legend.description',
        details: {
          para1: 'beeomonitoring.pesticides.legend.details.para1',
          para2: 'beeomonitoring.pesticides.legend.details.para2',
          para3: 'beeomonitoring.pesticides.legend.details.para3',
          para4: 'beeomonitoring.pesticides.legend.details.para4',
        },
      },
    },
    siteDetail: {
      title: 'beeomonitoring.siteDetail.title',
      description: 'beeomonitoring.siteDetail.description',
      general: {
        title: 'beeomonitoring.siteDetail.general.title',
        description: 'beeomonitoring.siteDetail.general.description',
        name: 'beeomonitoring.siteDetail.general.name',
        longitude: 'beeomonitoring.siteDetail.general.longitude',
        latitude: 'beeomonitoring.siteDetail.general.latitude',
      },
      cards: {
        marketing: {
          biodiversity: 'beeomonitoring.siteDetail.cards.marketing.biodiversity',
          pesticides: 'beeomonitoring.siteDetail.cards.marketing.pesticides',
          metals: 'beeomonitoring.siteDetail.cards.marketing.metals',
          pah: 'beeomonitoring.siteDetail.cards.marketing.pah',
          pcb: 'beeomonitoring.siteDetail.cards.marketing.pcb',
          dioxin: 'beeomonitoring.siteDetail.cards.marketing.dioxin',
        },
        biodiversity: 'beeomonitoring.siteDetail.cards.biodiversity',
        pesticides: 'beeomonitoring.siteDetail.cards.pesticides',
        metals: 'beeomonitoring.siteDetail.cards.metals',
        pah: 'beeomonitoring.siteDetail.cards.pah',
        pcb: 'beeomonitoring.siteDetail.cards.pcb',
        dioxin: 'beeomonitoring.siteDetail.cards.dioxin',
      },
      map: {
        tooltip: 'beeomonitoring.siteDetail.map.tooltip',
      },
      content: {
        general: {
          title: 'beeomonitoring.siteDetail.content.general.title',
          description: 'beeomonitoring.siteDetail.content.general.description',
        },
        other: {
          title: 'beeomonitoring.siteDetail.content.other.title',
          description: 'beeomonitoring.siteDetail.content.other.description',
        },
      },
      error: {
        title: 'beeomonitoring.siteDetail.error.title',
        description: 'beeomonitoring.siteDetail.error.description',
      },
    },
    sites: {
      title: 'beeomonitoring.sites.title',
      description: 'beeomonitoring.sites.description',
    },
  },
  global: {
    admin: {
      page: {
        accounts: {
          title: 'global.admin.page.accounts.title',
          description: 'global.admin.page.accounts.description',
          table: {
            header: {
              name: 'global.admin.page.accounts.table.header.name',
              nbSites: 'global.admin.page.accounts.table.header.nbSites',
              nbUsers: 'global.admin.page.accounts.table.header.nbUsers',
              Produits: 'global.admin.page.accounts.table.header.Produits',
            },
          },
          toast: {
            success: 'global.admin.page.accounts.toast.success',
            error: 'global.admin.page.accounts.toast.error',
          },
          sheet: {
            title: 'global.admin.page.accounts.sheet.title',
            description: 'global.admin.page.accounts.sheet.description',
            add: {
              title: 'global.admin.page.accounts.sheet.add.title',
              description: 'global.admin.page.accounts.sheet.add.description',
              toast: {
                success: 'global.admin.page.accounts.sheet.add.toast.success',
                error: 'global.admin.page.accounts.sheet.add.toast.error',
              },
            },
            edit: {
              title: 'global.admin.page.accounts.sheet.edit.title',
              description: 'global.admin.page.accounts.sheet.edit.description',
              toast: {
                success: 'global.admin.page.accounts.sheet.edit.toast.success',
                error: 'global.admin.page.accounts.sheet.edit.toast.error',
              },
            },
            button: 'global.admin.page.accounts.sheet.button',
          },
          dialog: {
            title: 'global.admin.page.accounts.dialog.title',
            description: 'global.admin.page.accounts.dialog.description',
            button: {
              cancel: 'global.admin.page.accounts.dialog.button.cancel',
              delete: 'global.admin.page.accounts.dialog.button.delete',
            },
            toast: {
              success: 'global.admin.page.accounts.dialog.toast.success',
              error: 'global.admin.page.accounts.dialog.toast.error',
            },
          },
        },
      },
    },
    auth: {
      forgotpassword: {
        title: 'global.auth.forgotpassword.title',
        email: 'global.auth.forgotpassword.email',
        submit: 'global.auth.forgotpassword.submit',
        success: 'global.auth.forgotpassword.success',
        error: 'global.auth.forgotpassword.error',
      },
      activateuser: {
        title: 'global.auth.activateuser.title',
        password: 'global.auth.activateuser.password',
        create_password: 'global.auth.activateuser.create_password',
        password_confirmation: 'global.auth.activateuser.password_confirmation',
        success: 'global.auth.activateuser.success',
        error: 'global.auth.activateuser.error',
        submit: 'global.auth.activateuser.submit',
      },
      resetpassword: {
        title: 'global.auth.resetpassword.title',
        password: 'global.auth.resetpassword.password',
        submit: 'global.auth.resetpassword.submit',
        success: 'global.auth.resetpassword.success',
        error: 'global.auth.resetpassword.error',
        tokenExpired: 'global.auth.resetpassword.tokenExpired',
      },
    },
    common: {
      languages: {
        fr: 'global.common.languages.fr',
        nl: 'global.common.languages.nl',
        en: 'global.common.languages.en',
      },
      countries: {
        be: 'global.common.countries.be',
        fr: 'global.common.countries.fr',
      },
      season: {
        spring: 'global.common.season.spring',
        summer: 'global.common.season.summer',
        fall: 'global.common.season.fall',
        winter: 'global.common.season.winter',
      },
      user: {
        menu: {
          logout: 'global.common.user.menu.logout',
          settings: 'global.common.user.menu.settings',
          admin: 'global.common.user.menu.admin',
          release: 'global.common.user.menu.release',
          disconnect: 'global.common.user.menu.disconnect',
        },
      },
      errors: {
        '403': {
          title: 'global.common.errors.403.title',
          description: 'global.common.errors.403.description',
        },
        '404': {
          title: 'global.common.errors.404.title',
          description: 'global.common.errors.404.description',
        },
        '503': {
          title: 'global.common.errors.503.title',
          description: 'global.common.errors.503.description',
        },
        back: {
          home: 'global.common.errors.back.home',
        },
        contact: 'global.common.errors.contact',
        default: {
          title: 'global.common.errors.default.title',
          description: 'global.common.errors.default.description',
        },
        no_sites: {
          message: 'global.common.errors.no_sites.message',
          cta_with_permission: 'global.common.errors.no_sites.cta_with_permission',
          cta_without_permission: 'global.common.errors.no_sites.cta_without_permission',
        },
        noSitesFound: {
          title: 'global.common.errors.noSitesFound.title',
          description: 'global.common.errors.noSitesFound.description',
        },
        imageNotFound: 'global.common.errors.imageNotFound',
        missingTitle: 'global.common.errors.missingTitle',
      },
      buttons: {
        addUser: 'global.common.buttons.addUser',
        reInviteUser: 'global.common.buttons.reInviteUser',
        moreDetails: 'global.common.buttons.moreDetails',
        addSite: 'global.common.buttons.addSite',
        editSite: 'global.common.buttons.editSite',
        addAccount: 'global.common.buttons.addAccount',
        addBom: 'global.common.buttons.addBom',
        importSites: 'global.common.buttons.importSites',
        importSitesSubtitle: 'global.common.buttons.importSitesSubtitle',
        learnMore: 'global.common.buttons.learnMore',
      },
      datatable: {
        filter: 'global.common.datatable.filter',
        emptyResults: 'global.common.datatable.emptyResults',
        pagination: {
          page: 'global.common.datatable.pagination.page',
          of: 'global.common.datatable.pagination.of',
          rowsPerPage: 'global.common.datatable.pagination.rowsPerPage',
          goToFirstPage: 'global.common.datatable.pagination.goToFirstPage',
          goToPreviousPage: 'global.common.datatable.pagination.goToPreviousPage',
          goToNextPage: 'global.common.datatable.pagination.goToNextPage',
          goToLastPage: 'global.common.datatable.pagination.goToLastPage',
          rowSelected: 'global.common.datatable.pagination.rowSelected',
          rowSelected_other: 'global.common.datatable.pagination.rowSelected_other',
        },
      },
      noMobile: {
        title: 'global.common.noMobile.title',
        description: 'global.common.noMobile.description',
      },
      module: {
        impact: 'global.common.module.impact',
        initiative: 'global.common.module.initiative',
        audit: 'global.common.module.audit',
        monitoring: 'global.common.module.monitoring',
        metrics: 'global.common.module.metrics',
      },
      scoringType: {
        pesticides: 'global.common.scoringType.pesticides',
        biodiversity: 'global.common.scoringType.biodiversity',
      },
      data: {
        noDataAvailable: 'global.common.data.noDataAvailable',
        noDataToDisplay: 'global.common.data.noDataToDisplay',
      },
      radius: 'global.common.radius',
    },
    landingPage: {
      intro: {
        title: 'global.landingPage.intro.title',
        description: 'global.landingPage.intro.description',
        button: 'global.landingPage.intro.button',
        agricultural: 'global.landingPage.intro.agricultural',
        vegetation: 'global.landingPage.intro.vegetation',
      },
      timeline: {
        title: 'global.landingPage.timeline.title',
        description: 'global.landingPage.timeline.description',
        beeoimpact: {
          title: 'global.landingPage.timeline.beeoimpact.title',
          priorisation: {
            title: 'global.landingPage.timeline.beeoimpact.priorisation.title',
            description: 'global.landingPage.timeline.beeoimpact.priorisation.description',
          },
          assessment: {
            title: 'global.landingPage.timeline.beeoimpact.assessment.title',
            description: 'global.landingPage.timeline.beeoimpact.assessment.description',
          },
        },
        beeonsite: {
          title: 'global.landingPage.timeline.beeonsite.title',
          monitoring: {
            title: 'global.landingPage.timeline.beeonsite.monitoring.title',
            description: 'global.landingPage.timeline.beeonsite.monitoring.description',
          },
          dna: {
            title: 'global.landingPage.timeline.beeonsite.dna.title',
            description: 'global.landingPage.timeline.beeonsite.dna.description',
          },
          audit: {
            title: 'global.landingPage.timeline.beeonsite.audit.title',
            description: 'global.landingPage.timeline.beeonsite.audit.description',
          },
        },
        beeoinitiative: {
          title: 'global.landingPage.timeline.beeoinitiative.title',
          action: {
            title: 'global.landingPage.timeline.beeoinitiative.action.title',
            description: 'global.landingPage.timeline.beeoinitiative.action.description',
          },
        },
      },
      featureTitle: 'global.landingPage.featureTitle',
      features: {
        'beeoimpact-site-priorisation': {
          title: 'global.landingPage.features.beeoimpact-site-priorisation.title',
          subtitle: 'global.landingPage.features.beeoimpact-site-priorisation.subtitle',
          description: 'global.landingPage.features.beeoimpact-site-priorisation.description',
        },
        'beeoimpact-high-level-site-assessment': {
          title: 'global.landingPage.features.beeoimpact-high-level-site-assessment.title',
          subtitle: 'global.landingPage.features.beeoimpact-high-level-site-assessment.subtitle',
          description: 'global.landingPage.features.beeoimpact-high-level-site-assessment.description',
        },
        beeomonitoring: {
          title: 'global.landingPage.features.beeomonitoring.title',
          subtitle: 'global.landingPage.features.beeomonitoring.subtitle',
          description: 'global.landingPage.features.beeomonitoring.description',
        },
        beeodna: {
          title: 'global.landingPage.features.beeodna.title',
          subtitle: 'global.landingPage.features.beeodna.subtitle',
          description: 'global.landingPage.features.beeodna.description',
        },
        beeoaudit: {
          title: 'global.landingPage.features.beeoaudit.title',
          subtitle: 'global.landingPage.features.beeoaudit.subtitle',
          description: 'global.landingPage.features.beeoaudit.description',
        },
        beeoinitiative: {
          title: 'global.landingPage.features.beeoinitiative.title',
          subtitle: 'global.landingPage.features.beeoinitiative.subtitle',
          description: 'global.landingPage.features.beeoinitiative.description',
        },
      },
      benefitTitle: 'global.landingPage.benefitTitle',
      benefits: {
        biodiversity: {
          title: 'global.landingPage.benefits.biodiversity.title',
          description: 'global.landingPage.benefits.biodiversity.description',
        },
        centralize: {
          title: 'global.landingPage.benefits.centralize.title',
          description: 'global.landingPage.benefits.centralize.description',
        },
        compare: {
          title: 'global.landingPage.benefits.compare.title',
          description: 'global.landingPage.benefits.compare.description',
        },
        robust: {
          title: 'global.landingPage.benefits.robust.title',
          description: 'global.landingPage.benefits.robust.description',
        },
        frameworks: {
          title: 'global.landingPage.benefits.frameworks.title',
          description: 'global.landingPage.benefits.frameworks.description',
        },
      },
      cta: {
        title: 'global.landingPage.cta.title',
        button: 'global.landingPage.cta.button',
        subject: 'global.landingPage.cta.subject',
      },
    },
    nav: {
      beeoinitiative: {
        menuLink: {
          description: 'global.nav.beeoinitiative.menuLink.description',
        },
        listItem: {
          dashboard: {
            title: 'global.nav.beeoinitiative.listItem.dashboard.title',
            description: 'global.nav.beeoinitiative.listItem.dashboard.description',
          },
          sites: {
            title: 'global.nav.beeoinitiative.listItem.sites.title',
            description: 'global.nav.beeoinitiative.listItem.sites.description',
          },
          initiatives: {
            title: 'global.nav.beeoinitiative.listItem.initiatives.title',
            description: 'global.nav.beeoinitiative.listItem.initiatives.description',
          },
        },
      },
      beeoimpact: {
        menuLink: {
          description: 'global.nav.beeoimpact.menuLink.description',
        },
        listItem: {
          dashboard: {
            title: 'global.nav.beeoimpact.listItem.dashboard.title',
            description: 'global.nav.beeoimpact.listItem.dashboard.description',
          },
          sites: {
            title: 'global.nav.beeoimpact.listItem.sites.title',
            description: 'global.nav.beeoimpact.listItem.sites.description',
          },
          methodology: {
            title: 'global.nav.beeoimpact.listItem.methodology.title',
            description: 'global.nav.beeoimpact.listItem.methodology.description',
          },
        },
      },
      beeomonitoring: {
        menuLink: {
          description: 'global.nav.beeomonitoring.menuLink.description',
        },
        listItem: {
          sites: {
            title: 'global.nav.beeomonitoring.listItem.sites.title',
            description: 'global.nav.beeomonitoring.listItem.sites.description',
          },
        },
      },
      needHelp: {
        title: 'global.nav.needHelp.title',
        description: 'global.nav.needHelp.description',
        contact: 'global.nav.needHelp.contact',
      },
      beeometrics: {
        menuLink: {
          description: 'global.nav.beeometrics.menuLink.description',
        },
      },
      beeoaudit: {
        menuLink: {
          description: 'global.nav.beeoaudit.menuLink.description',
        },
        listItem: {
          sites: {
            title: 'global.nav.beeoaudit.listItem.sites.title',
            description: 'global.nav.beeoaudit.listItem.sites.description',
          },
        },
      },
    },
    settings: {
      title: 'global.settings.title',
      description: 'global.settings.description',
      menu: {
        users: 'global.settings.menu.users',
        profile: 'global.settings.menu.profile',
        security: 'global.settings.menu.security',
        sites: 'global.settings.menu.sites',
      },
      user: {
        email: 'global.settings.user.email',
        password: 'global.settings.user.password',
        confirmPassword: 'global.settings.user.confirmPassword',
        firstName: 'global.settings.user.firstName',
        lastName: 'global.settings.user.lastName',
      },
      page: {
        users: {
          title: 'global.settings.page.users.title',
          userNotLogged: 'global.settings.page.users.userNotLogged',
          description: 'global.settings.page.users.description',
          table: {
            header: {
              email: 'global.settings.page.users.table.header.email',
              firstName: 'global.settings.page.users.table.header.firstName',
              lastName: 'global.settings.page.users.table.header.lastName',
              actions: 'global.settings.page.users.table.header.actions',
            },
          },
          toast: {
            success: 'global.settings.page.users.toast.success',
            error: 'global.settings.page.users.toast.error',
          },
          sheet: {
            title: 'global.settings.page.users.sheet.title',
            description: 'global.settings.page.users.sheet.description',
          },
        },
        changePassword: {
          title: 'global.settings.page.changePassword.title',
          description: 'global.settings.page.changePassword.description',
          toast: {
            success: 'global.settings.page.changePassword.toast.success',
            error: 'global.settings.page.changePassword.toast.error',
          },
        },
        profile: {
          title: 'global.settings.page.profile.title',
          description: 'global.settings.page.profile.description',
        },
        security: {
          title: 'global.settings.page.security.title',
          description: 'global.settings.page.security.description',
        },
        sites: {
          title: 'global.settings.page.sites.title',
          description: 'global.settings.page.sites.description',
          label: {
            name: 'global.settings.page.sites.label.name',
            features: 'global.settings.page.sites.label.features',
            lat: 'global.settings.page.sites.label.lat',
            lng: 'global.settings.page.sites.label.lng',
            radius: 'global.settings.page.sites.label.radius',
            type: 'global.settings.page.sites.label.type',
            surface: 'global.settings.page.sites.label.surface',
            nace_code: 'global.settings.page.sites.label.nace_code',
            key: 'global.settings.page.sites.label.key',
            selectKey: 'global.settings.page.sites.label.selectKey',
            noKeyFound: 'global.settings.page.sites.label.noKeyFound',
          },
          toast: {
            success: 'global.settings.page.sites.toast.success',
            error: 'global.settings.page.sites.toast.error',
          },
          sheet: {
            add: {
              title: 'global.settings.page.sites.sheet.add.title',
              description: 'global.settings.page.sites.sheet.add.description',
              toast: {
                success: 'global.settings.page.sites.sheet.add.toast.success',
                error: 'global.settings.page.sites.sheet.add.toast.error',
              },
            },
            edit: {
              title: 'global.settings.page.sites.sheet.edit.title',
              description: 'global.settings.page.sites.sheet.edit.description',
              toast: {
                success: 'global.settings.page.sites.sheet.edit.toast.success',
                error: 'global.settings.page.sites.sheet.edit.toast.error',
              },
            },
            button: 'global.settings.page.sites.sheet.button',
          },
          dialog: {
            title: 'global.settings.page.sites.dialog.title',
            description: 'global.settings.page.sites.dialog.description',
            button: {
              cancel: 'global.settings.page.sites.dialog.button.cancel',
              delete: 'global.settings.page.sites.dialog.button.delete',
            },
            toast: {
              success: 'global.settings.page.sites.dialog.toast.success',
              error: 'global.settings.page.sites.dialog.toast.error',
            },
          },
          buildingType: {
            OFFICE: 'global.settings.page.sites.buildingType.OFFICE',
            INDUSTRIAL: 'global.settings.page.sites.buildingType.INDUSTRIAL',
            AGRICULTURAL: 'global.settings.page.sites.buildingType.AGRICULTURAL',
            EXTRACTION: 'global.settings.page.sites.buildingType.EXTRACTION',
            MANUFACTURING: 'global.settings.page.sites.buildingType.MANUFACTURING',
          },
          radius: {
            definition: 'global.settings.page.sites.radius.definition',
          },
        },
        dataVisualisation: {
          title: 'global.settings.page.dataVisualisation.title',
          description: 'global.settings.page.dataVisualisation.description',
        },
        multiSelect: {
          selectItem: 'global.settings.page.multiSelect.selectItem',
          noNaceCode: 'global.settings.page.multiSelect.noNaceCode',
          noItem: 'global.settings.page.multiSelect.noItem',
        },
      },
    },
    validation: {
      required: 'global.validation.required',
      passwordsNoMatch: 'global.validation.passwordsNoMatch',
    },
  },
}
