import { useState } from 'react'
import { Initiative } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

interface InitiativeImageProps {
  initiative?: Initiative
}
const InitiativeImage = ({ initiative }: InitiativeImageProps) => {
  const { t } = useTranslation()
  const [hasImageError, setHasImageError] = useState(false)

  return (
    <div className="flex h-36 w-full items-center justify-center rounded-t-md bg-[hsl(var(--biodiv-limeFiveHundred))]">
      {initiative?.image_url ? (
        <img
          src={initiative.image_url}
          className="h-36 w-full object-cover text-center leading-[9rem] opacity-90"
          alt={hasImageError ? t(i18nKeys.global.common.errors.imageNotFound) : 'initiative nature'}
          onError={() => setHasImageError(true)}
        />
      ) : (
        <p className="text-muted-foreground">{t(i18nKeys.global.common.errors.imageNotFound)}</p>
      )}
    </div>
  )
}

export default InitiativeImage
