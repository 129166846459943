import { SiteIdNameCenter, User } from '@/client/backend/models'

import { ScoringType } from '@/types/scoring-type'
import { MODULE } from '@/lib/module'
import SitesMap from '@/components/sites/sites-map'

interface SitesMapContainerProps {
  module: MODULE
  sites: SiteIdNameCenter[]
  hoveredSiteId?: number
  hoveredCardRef: React.RefObject<number | null>
  isLoading: boolean
  scoringType: ScoringType
  user: User
}

export const SitesMapContainer = ({
  module,
  sites,
  hoveredSiteId,
  hoveredCardRef,
  isLoading,
  scoringType,
}: SitesMapContainerProps) => {
  return (
    <div className="sticky top-16 h-[calc(100vh-10px)] md:w-0 lg:w-[400px] xl:w-[504.1px] 2xl:w-[504.1px] ">
      <SitesMap
        sites={sites}
        hoveredCard={hoveredSiteId}
        hoveredCardRef={hoveredCardRef}
        isLoading={isLoading}
        module={module}
        scoringType={scoringType}
      />
    </div>
  )
}
