import {
  accountsRetrieve,
  accountsStatsRetrieve,
  getAccountsRetrieveQueryKey,
  getAccountsStatsRetrieveQueryKey,
} from '@/client/backend/api/accounts/accounts'
import { getSitesListQueryKey, sitesList } from '@/client/backend/api/sites/sites'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { FeatureType } from '@/types/feature-type'
import { ScoringType } from '@/types/scoring-type'
import { hasAccesTo } from '@/lib/permission'
import { cn } from '@/lib/utils'
import { useAuth } from '@/hooks/useAuth'
import { Card } from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import DashboardBiodiversity from '@/components/beeoimpact/dashboard-biodiversity'
import DashboardPesticides from '@/components/beeoimpact/dashboard-pesticides'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

const DashboardImpactPage = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const accountId = user?.account_id ?? 0
  const userFeatures = user?.features ?? []

  const { data: stats, isLoading: isLoadingStats } = useQuery({
    queryKey: [getAccountsStatsRetrieveQueryKey(accountId)],
    queryFn: () => accountsStatsRetrieve(accountId),
    enabled: !!user,
  })

  const { data: account, isLoading: isLoadingAccount } = useQuery({
    queryKey: [getAccountsRetrieveQueryKey(accountId)],
    queryFn: () => accountsRetrieve(accountId),
    enabled: !!user,
  })

  const { data: sites } = useQuery({
    queryKey: [getSitesListQueryKey()],
    queryFn: () => sitesList(),
  })

  const hasBiodiversityAccess = hasAccesTo(userFeatures, FeatureType.BIODIVERSITY)
  const hasPesticidesAccess = hasAccesTo(userFeatures, FeatureType.PESTICIDES)
  const fullAccess = hasBiodiversityAccess && hasPesticidesAccess

  const RenderDashboard = () => {
    return (
      <div>
        {fullAccess ? (
          <Tabs defaultValue={ScoringType.BIODIVERSITY}>
            <div className="flex w-full items-center justify-between space-x-6">
              <div className="flex items-center justify-center space-x-5">
                <PageHeader className="m-0 mb-4">
                  <PageHeaderHeading className="tracking-normal">
                    {t(i18nKeys.beeoimpact.dashboard.title.main)}
                  </PageHeaderHeading>
                </PageHeader>

                <TabsList className="mb-3 flex">
                  <TabsTrigger
                    value={ScoringType.BIODIVERSITY}
                    className={cn(
                      'data-[state=active]:bg-biodivTealSevenHundred data-[state=active]:text-white',
                      'data-[state=inactive]:bg-muted data-[state=inactive]:text-black'
                    )}
                  >
                    {t(i18nKeys.beeoimpact.dashboard.trigger.biodiv)}
                  </TabsTrigger>
                  <TabsTrigger
                    value={ScoringType.PESTICIDES}
                    className={cn(
                      'data-[state=active]:bg-biodivTealSevenHundred data-[state=active]:text-white',
                      'data-[state=inactive]:bg-muted data-[state=inactive]:text-black'
                    )}
                  >
                    {t(i18nKeys.beeoimpact.dashboard.trigger.pesticides)}
                  </TabsTrigger>
                </TabsList>
              </div>
            </div>
            <Card className="flex h-16 w-full items-center border border-biodivLimeFiveHundred p-4 md:text-sm lg:text-base">
              {t(i18nKeys.beeoimpact.dashboard.definition.beeoimpact)}
            </Card>
            <TabsContent value={ScoringType.BIODIVERSITY}>
              {hasBiodiversityAccess ? (
                <div>
                  <DashboardBiodiversity
                    stats={stats}
                    isLoadingStats={isLoadingStats}
                    isLoadingAccount={isLoadingAccount}
                    account={account}
                  />
                </div>
              ) : null}
            </TabsContent>
            <TabsContent value={ScoringType.PESTICIDES}>
              {hasPesticidesAccess ? (
                <div>
                  <DashboardPesticides sitesCount={stats?.nb_sites ?? 0} sites={sites?.results?.features ?? []} />
                </div>
              ) : null}
            </TabsContent>
          </Tabs>
        ) : (
          <>
            <div className="flex w-full items-center justify-between space-x-6">
              <div className="flex items-center justify-center space-x-5">
                <PageHeader className="m-0 mb-4">
                  <PageHeaderHeading className="tracking-normal">
                    {t(i18nKeys.beeoimpact.dashboard.title.main)}
                  </PageHeaderHeading>
                </PageHeader>
              </div>
            </div>

            {hasBiodiversityAccess && (
              <div>
                <DashboardBiodiversity
                  stats={stats}
                  isLoadingStats={isLoadingStats}
                  isLoadingAccount={isLoadingAccount}
                  account={account}
                />
              </div>
            )}
            {hasPesticidesAccess && (
              <div>
                <DashboardPesticides sitesCount={stats?.nb_sites ?? 0} sites={sites?.results?.features ?? []} />
              </div>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <div className="container">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoimpact),
          },
        ]}
        currentPage={t(i18nKeys.beeoimpact.dashboard.breadcrumb.dashboard)}
      />
      <RenderDashboard />
    </div>
  )
}
export default DashboardImpactPage
