import L from 'leaflet'

import dotImage from '../assets/defaultMapMarker.png'
import { default as greenDot, default as greyDot } from '../assets/greenDot.png'
import { default as greenDotOn, default as greyDotOn } from '../assets/greenDotOn.png'
import orangeDot from '../assets/orangeDot.png'
import orangeDotOn from '../assets/orangeDotOn.png'
import pinOn from '../assets/pin-on.png'
import redDot from '../assets/redDot.png'
import redDotOn from '../assets/redDotOn.png'

export const getMapIcon = (color = 'grey') => {
  const iconUrlMap = {
    defaultPin: dotImage,
    pin: pinOn,
    green: greenDot,
    orange: orangeDot,
    red: redDot,
    grey: greyDot,
  }
  const iconUrl = iconUrlMap[color] || iconUrlMap['grey']

  return L.icon({
    iconUrl,
    iconSize: [14, 14],
    iconAnchor: [8, 8],
    popupAnchor: [-2, -6],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    shadowSize: [15, 15],
  })
}

export const hoverSiteIcon = (color = 'grey') => {
  const iconUrlMap = {
    defaultPinOn: pinOn,
    green: greenDotOn,
    orange: orangeDotOn,
    red: redDotOn,
    grey: greyDotOn,
  }
  const iconUrl = iconUrlMap[color] || iconUrlMap['grey']

  return L.icon({
    iconUrl,
    iconSize: [24, 30],
    iconAnchor: [8, 8],
    popupAnchor: [-2, -6],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    shadowSize: [15, 15],
  })
}
