import { useEffect, useRef, useState } from 'react'
import { getSitesListQueryKey, sitesList } from '@/client/backend/api/sites/sites'
import { SitesListParams } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import SitesTableBeeo from '@/pages/beeoinitiative/sites/sites-table-beeo'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { MODULE } from '@/lib/module'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Skeleton } from '@/components/ui/skeleton'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import SitesCard from '@/components/beeoinitiative/site-card-initiative'
import SiteFilter from '@/components/beeoinitiative/site-filter'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import { Icons } from '@/components/icons'
import NoSitesMessage from '@/components/no-sites-message'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import { PaginationControls } from '@/components/sites/pagination-controls'
import SitesMap from '@/components/sites/sites-map'

const PAGE_SIZE = 12
const defaultFilter = {
  page: 1,
  page_size: PAGE_SIZE,
  is_in_initiative: true,
  include: 'actions-taken,latest_action_taken_date,initiative_score',
}

const InitiativeSitesPage = () => {
  const { t } = useTranslation()
  const hoveredCardRef = useRef<number | null>(null)
  const [hoveredCard, setHoveredCard] = useState<number | null>(null)
  const [searchParams] = useSearchParams()
  const [filters, setFilters] = useState<SitesListParams>(defaultFilter)

  const { data: siteListPaginated, isLoading: isLoadingSites } = useQuery({
    queryKey: [getSitesListQueryKey(filters)],
    queryFn: () => sitesList(filters),
  })

  const siteList = siteListPaginated?.results?.features ?? []

  const generateSitesPath = (page: number) => {
    const params: SitesListParams = {
      ...searchParams,
      page,
    }

    return getPath(Routes.BEEOINITIATIVE_SITES, { queryParams: params })
  }

  useEffect(() => {
    const search = searchParams.get('search')
    const activeSite = searchParams.get('activeSite')
    const page = parseInt(searchParams.get('page') ?? '1')

    setFilters({
      ...defaultFilter,
      ...(search && { search }),
      ...(activeSite?.length && { has_actions_taken: activeSite === 'true' }),
      page,
    })
  }, [searchParams])

  return (
    <div className="pl-11">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoinitiative),
          },
        ]}
        currentPage={t(i18nKeys.beeoinitiative.common.breadcrumb.sites)}
      />
      <div className="flex flex-col">
        <div className="flex flex-col justify-between">
          <Tabs defaultValue="cards">
            <div className="flex flex-row items-center justify-start pr-11">
              <div className="flex h-28 w-full flex-row items-center justify-between pb-4">
                <PageHeader>
                  <PageHeaderHeading>{t(i18nKeys.beeoinitiative.sites.title)}</PageHeaderHeading>
                  {t(i18nKeys.beeoinitiative.sites.total)}: {siteListPaginated?.count}
                </PageHeader>
                <div className="flex flex-row items-center justify-center space-x-5 pt-6">
                  <SiteFilter />
                  <div>
                    <TabsList className="flex rounded-full">
                      <TabsTrigger
                        value="cards"
                        className="text-black data-[state=active]:rounded-full data-[state=active]:bg-black data-[state=active]:text-white"
                      >
                        <Icons.MapPin />
                      </TabsTrigger>
                      <TabsTrigger
                        value="list"
                        className="text-black data-[state=active]:rounded-full data-[state=active]:bg-black data-[state=active]:text-white"
                      >
                        <Icons.Grid2X2 />
                      </TabsTrigger>
                    </TabsList>
                  </div>
                </div>
              </div>
            </div>

            <TabsContent value="cards">
              {siteList?.length === 0 ? (
                <NoSitesMessage className="m-14 text-center" />
              ) : (
                <div className="flex flex-row justify-center gap-x-5">
                  <ScrollArea className="flex grow">
                    <div className="grid  grow grid-cols-2 gap-5  pb-4 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3">
                      {isLoadingSites
                        ? Array.from({ length: 9 }).map((_, index) => (
                            <Skeleton key={index} className="min-h-[280px] w-full max-w-[360px]" />
                          ))
                        : siteList?.map((site) => (
                            <SitesCard
                              key={site.id}
                              site={site}
                              onSiteHover={() => setHoveredCard(site.id)}
                              onSiteHoverOut={() => setHoveredCard(null)}
                              link={getPath(Routes.BEEOINITIATIVE_SITE, { params: { siteId: site.id } })}
                            />
                          ))}
                    </div>
                    {siteListPaginated && (
                      <PaginationControls paginatedData={siteListPaginated} transformPath={generateSitesPath} />
                    )}
                  </ScrollArea>
                  <div className="sticky top-16 h-[calc(100vh-100px)] md:w-0 lg:w-[400px] xl:w-[504.1px] 2xl:w-[504.1px] ">
                    <SitesMap
                      sites={siteList}
                      hoveredCard={hoveredCard}
                      hoveredCardRef={hoveredCardRef}
                      isLoading={isLoadingSites}
                      module={MODULE.INITIATIVE}
                    />
                  </div>
                </div>
              )}
            </TabsContent>
            <TabsContent value="list" className="pb-4 pr-11">
              <SitesTableBeeo data={siteList} />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default InitiativeSitesPage
