/* eslint-disable react-refresh/only-export-components */

import { createContext, ReactNode, useEffect, useState } from 'react'
import { authJwtCreateCreate } from '@/client/backend/api/auth/auth'
import { usersMeRetrieve, usersTokenRetrieve } from '@/client/backend/api/users/users'
import { AXIOS_INSTANCE } from '@/client/backend/backend-instance'
import { User } from '@/client/backend/models/user'
import { useQueryClient } from '@tanstack/react-query'

import { AvailableLanguage } from '@/types/available-language'
import { HTTP_CODE } from '@/lib/constants/http-code'
import { STORAGE_KEYS } from '@/lib/constants/local-storage'
import { setHeaders } from '@/lib/utils'
import { toast } from '@/components/ui/use-toast'

type AuthContextType = {
  user: User | null
  isLogged: boolean
  token: string | null
  isImpersonate: boolean
  signin: (email: string, password: string) => Promise<User | null>
  signout: () => void
  impersonate: (id: number) => Promise<User | null>
  stopImpersonate: () => void
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isLogged: false,
  token: null,
  isImpersonate: false,
  signin: () => Promise.resolve(null),
  signout: () => {},
  impersonate: () => Promise.resolve(null),
  stopImpersonate: () => {},
})

type AuthProviderProps = {
  children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useAuthProvider()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

const useAuthProvider = (): AuthContextType => {
  const queryClient = useQueryClient()

  // Retrieve tokens and language from localStorage
  const storedToken = localStorage.getItem(STORAGE_KEYS.TOKEN_IMPERSONATE) ?? localStorage.getItem(STORAGE_KEYS.TOKEN)
  const storedLanguage =
    (localStorage.getItem(STORAGE_KEYS.LAST_SELECTED_LANGUAGE) as AvailableLanguage) || AvailableLanguage.EN

  const [token, setToken] = useState<string | null>(storedToken)
  const [user, setUser] = useState<User | null>(null)
  const [isLogged, setLogged] = useState<boolean>(!!storedToken)
  const [isImpersonate, setIsImpersonate] = useState<boolean>(!!localStorage.getItem(STORAGE_KEYS.TOKEN_IMPERSONATE))

  const signout = () => {
    setUser(null)
    setLogged(false)
    localStorage.removeItem(STORAGE_KEYS.TOKEN)
    localStorage.removeItem(STORAGE_KEYS.TOKEN_IMPERSONATE)
    queryClient.clear()
    delete AXIOS_INSTANCE.defaults.headers.common['Authorization']
  }

  useEffect(() => {
    if (!token) return

    // Set default headers for axios with token and language
    setHeaders(token, storedLanguage)

    // Setup axios interceptor for handling response errors
    const interceptorId = AXIOS_INSTANCE.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error

        if (response?.status === HTTP_CODE.FORBIDDEN) {
          toast({ title: 'You do not have the permission for this action.' })
          return Promise.reject(error)
        }

        if (response?.status === HTTP_CODE.UNAUTHORIZED) {
          const tokenErrorMessages = ['Given token not valid for any token type', 'Token is invalid or expired']
          if (tokenErrorMessages.includes(response.data?.detail)) {
            toast({ title: 'Your session has expired. Please log in again.' })
            signout()
            return Promise.reject(error)
          }
        }

        return Promise.reject(error)
      }
    )

    // Fetch the current user and update state
    const fetchCurrentUser = async () => {
      try {
        const currentUser = await usersMeRetrieve()
        setUser(currentUser)
      } catch (error) {
        signout()
      }
    }

    fetchCurrentUser()

    // Cleanup interceptor on unmount or when token changes
    return () => {
      AXIOS_INSTANCE.interceptors.response.eject(interceptorId)
    }
  }, [token])

  const signin = async (email: string, password: string): Promise<User | null> => {
    try {
      const response = await authJwtCreateCreate({ email, password })
      localStorage.setItem(STORAGE_KEYS.TOKEN, response.access)
      setToken(response.access)
      setLogged(true)
      return null
    } catch (error) {
      setLogged(false)
      toast({
        variant: 'destructive',
        title: 'Wrong credentials',
        description: 'Please check your email and password',
      })
      return null
    }
  }

  const impersonate = async (id: number): Promise<User | null> => {
    try {
      const response = await usersTokenRetrieve(id)
      localStorage.setItem(STORAGE_KEYS.TOKEN_IMPERSONATE, response.access_token)
      setToken(response.access_token)
      setLogged(true)
      setIsImpersonate(true)
      queryClient.clear()
      setUser(response.user)
      return response.user
    } catch (error) {
      setLogged(false)
      toast({
        variant: 'destructive',
        title: 'Permission denied',
        description: 'You do not have the permission for this action.',
      })
      return null
    }
  }

  const stopImpersonate = () => {
    localStorage.removeItem(STORAGE_KEYS.TOKEN_IMPERSONATE)
    const mainToken = localStorage.getItem(STORAGE_KEYS.TOKEN) || null
    setToken(mainToken)
    setIsImpersonate(false)
    queryClient.clear()
  }

  return {
    user,
    isLogged,
    token,
    isImpersonate,
    signin,
    signout,
    impersonate,
    stopImpersonate,
  }
}

export default AuthProvider
