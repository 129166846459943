import { Card } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'

interface DashboardCardProps {
  title: string
  subtitle?: string | React.ReactNode
  footer?: React.ReactNode
  numberOf?: number
  icon?: React.ReactNode
  height?: string
  footerAlignment?: 'center' | 'left' | 'right'
  bgColor?: string
  children?: React.ReactNode
  isLoading: boolean
  skeletonHeight?: string
  width?: string
  justifyContent?: 'start' | 'between'
}
const DashboardCard = ({
  title,
  subtitle,
  footer,
  numberOf,
  icon,
  height,
  footerAlignment,
  bgColor,
  children,
  isLoading,
  skeletonHeight = 'h-[200px]', // default height
  width,
  justifyContent = 'between',
}: DashboardCardProps) => {
  if (isLoading) {
    return <Skeleton className={skeletonHeight} />
  }
  return (
    <Card
      className={`${height} ${bgColor} ${width} ${
        justifyContent === 'start' ? 'justify-start' : 'justify-between'
      } z-10 flex h-full flex-col  sm:px-8 sm:py-9 md:px-8 md:py-9 lg:px-8 lg:py-9 xl:px-6 xl:py-9 2xl:px-10 2xl:py-9`}
    >
      <div className="flex justify-between">
        <div className="flex flex-col space-y-2.5">
          <p className="text-xl font-semibold">{title}</p>
          <p className="text-sm">{subtitle}</p>
        </div>
        <div className="flex items-start justify-end space-x-2">
          <p className="text-4xl font-semibold">{numberOf}</p>
          {icon && <div className="pl-2 pt-0.5">{icon}</div>}
        </div>
      </div>
      <div className="w-full">{children}</div>
      {footer && (
        <p className={`flex underline ${footerAlignment === 'center' ? 'justify-center pt-7' : 'justify-start'}`}>
          {footer}
        </p>
      )}
    </Card>
  )
}

export default DashboardCard
