import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import { ChartConfig, ChartContainer, ChartTooltip } from '@/components/ui/chart'

const InitiativesTaken = ({ actionsTakenInitiatives }) => {
  const { t } = useTranslation()
  const chartConfig = {
    number: {
      label: t(i18nKeys.beeoinitiative.dashboard.nomberOfSites),
    },
  } satisfies ChartConfig

  const actionCounts = actionsTakenInitiatives?.results?.features?.map(
    (site) => site.properties?.actions_taken?.filter((action) => action.status === 'DONE').length || 0
  )

  const zeroData = actionCounts.filter((count) => count === 0).length
  const oneToThreeData = actionCounts.filter((count) => count > 0 && count < 4).length
  const moreThanThreeData = actionCounts.filter((count) => count > 3).length
  const chartData = [
    { initiative: '0', number: zeroData },
    { initiative: t(i18nKeys.beeoinitiative.dashboard.chartData.oneToThree), number: oneToThreeData },
    { initiative: t(i18nKeys.beeoinitiative.dashboard.chartData.moreThanThree), number: moreThanThreeData },
  ]
  const CustomBar = (props) => {
    const { x, y, width, height, payload } = props
    const fill =
      payload.initiative === '0' ? 'hsl(var(--biodiv-redSevenHundred))' : 'hsl(var(--biodiv-tealSevenHundred))'
    const radius = 10

    return <rect x={x} y={y} width={width} height={height} fill={fill} rx={radius} ry={radius} />
  }

  const CustomTooltip = ({ doneValue, title, color }) => {
    return (
      <div className="rounded-md bg-muted p-2 shadow-md">
        <div className="flex items-center justify-evenly">
          <div className="mr-2 size-2 rounded-full" style={{ backgroundColor: color }} />
          <span>{title}</span>
          <span className="ml-5 text-muted-foreground">{doneValue.toFixed()}</span>{' '}
        </div>
      </div>
    )
  }
  return (
    <div className="w-96">
      <ChartContainer config={chartConfig} className="h-[167px] min-w-[500px]">
        <BarChart
          accessibilityLayer
          data={chartData}
          margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
          barCategoryGap="35%"
        >
          <CartesianGrid vertical={false} className="bg-black" strokeDasharray="5 3" />
          <XAxis
            dataKey="initiative"
            tickLine={false}
            tickMargin={10}
            tickFormatter={(value) => value.slice(0, 20)}
            axisLine={{ strokeWidth: 0.2 }}
          />
          <YAxis axisLine={false} tickLine={false} tickMargin={10} />
          <ChartTooltip
            cursor={false}
            content={({ payload }) => {
              if (payload && payload.length) {
                const data = payload[0].payload
                const color =
                  data.initiative === '0' ? 'hsl(var(--biodiv-redSevenHundred))' : 'hsl(var(--biodiv-tealSevenHundred))'
                const title =
                  data.number === zeroData
                    ? t(i18nKeys.beeoinitiative.dashboard.takenInits.zeroTakenInits).toUpperCase()
                    : data.number === oneToThreeData
                      ? t(i18nKeys.beeoinitiative.dashboard.takenInits.oneToThreeTakenInits).toUpperCase()
                      : t(i18nKeys.beeoinitiative.dashboard.takenInits.moreThanThreeTakenInits).toUpperCase()
                return <CustomTooltip doneValue={data.number} title={title} color={color} />
              }
              return null
            }}
          />
          <Bar dataKey="number" shape={CustomBar} radius={[20, 0, 20, 0]} />
        </BarChart>
      </ChartContainer>
    </div>
  )
}
export default InitiativesTaken
