import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { dateUpdateFormat } from '@/lib/date-update-format'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import I18nNumberFormatter from '@/components/ui/i18n-number-formatter'
import { Progress } from '@/components/ui/progress'
import { Separator } from '@/components/ui/separator'
import { Icons } from '@/components/icons'

interface SitesCardInitiativeProps {
  site: Site
  onSiteHover: () => void
  onSiteHoverOut: () => void
  link: string
}

const SiteCardInitiative = ({ site, onSiteHover, onSiteHoverOut, link }: SitesCardInitiativeProps) => {
  const { t } = useTranslation()

  // Retrieve all actions
  const actions = site.properties?.actions_taken ?? []

  // Total number of initiatives
  const totalInitiatives = actions.length

  // Count completed initiatives
  const completedActions = actions.filter((action) => action?.status === 'DONE')
  const completedInitiativesCount = completedActions.length

  // Calculate progress percentage
  const progressPercentage = totalInitiatives > 0 ? (completedInitiativesCount / totalInitiatives) * 100 : 0

  // Get the date of the last completed action
  const lastCompletedDate =
    completedActions
      .map((action) => action.date_done)
      .sort()
      .reverse()[0] ?? ''
  const formattedDate = dateUpdateFormat(lastCompletedDate)

  // Calculate total budget
  const totalBudget = actions.reduce((acc, action) => acc + (Number(action?.planned_cost) || 0), 0)

  return (
    <Card
      className="flex min-h-[280px] w-full flex-col px-10 transition-all duration-200 hover:border hover:border-[#1f6f6d]"
      onMouseOver={onSiteHover}
      onMouseOut={onSiteHoverOut}
    >
      <div className="pb-1 pt-4">
        <p className="truncate text-xl font-semibold">{site?.properties?.name}</p>
      </div>
      <Separator className="bg-biodivLimeFiveHundred" />
      <div className="mb-4 space-y-2">
        <div className="mt-4 flex items-center justify-between text-sm">
          <div className="flex w-full items-center justify-between space-x-2">
            <div className="flex items-center space-x-1">
              <p>{t(i18nKeys.beeoinitiative.sites.initiatives)}</p>
              {formattedDate && <p className="pt-0.5 text-xs text-gray-300">({formattedDate})</p>}
            </div>
            <p className="text-sm font-bold">
              {completedInitiativesCount}/{totalInitiatives}
            </p>
          </div>
        </div>
        <Progress className="h-2" value={progressPercentage} bgColor="bg-biodivLimeFiveHundred" />
      </div>
      <div className="flex flex-col space-y-4">
        <Separator />
        <div className="flex items-center">
          <div className="flex flex-1 flex-col items-center text-sm">
            <p>{t(i18nKeys.beeoinitiative.sites.totalBudget)}</p>
            <div className="flex items-center font-semibold">
              {<I18nNumberFormatter value={totalBudget} />} <Icons.Euro className="size-3.5" />
            </div>
          </div>
          <Separator orientation="vertical" />
          <div className="flex flex-1 flex-col items-center text-sm">
            <p>{t(i18nKeys.beeoinitiative.sites.score)}</p>
            <p className="flex gap-x-1 font-semibold">
              {site?.properties?.initiative_score ?? 0} <span>{t(i18nKeys.beeoinitiative.sites.pts)}</span>
            </p>
          </div>
        </div>
        <Separator />
      </div>
      <div className="flex items-center justify-center pt-6">
        <Link to={link}>
          <Button variant="outline" size="lg" className="h-[35px] w-24 border-2 border-black">
            {t(i18nKeys.beeoinitiative.sites.details)}
          </Button>
        </Link>
      </div>
    </Card>
  )
}

export default SiteCardInitiative
