const InitiativeChartTooltip = ({ value, title, color }) => {
  return (
    <div className="rounded-md bg-muted p-2 shadow-md">
      <div className="flex items-center justify-evenly">
        <div className="mr-2 size-2 rounded-full" style={{ backgroundColor: color }} />
        <span>{title}</span>
        <span className="ml-2 text-muted-foreground">{value.toFixed()}</span>
      </div>
    </div>
  )
}

export default InitiativeChartTooltip
