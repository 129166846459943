import { useEffect, useState } from 'react'
import { useSitesList } from '@/client/backend/api/sites/sites'
import { Site, SitesListParams } from '@/client/backend/models'
import { Initiative } from '@/client/backend/models/initiative'
import { i18nKeys } from '@/locales/keys'
import MonthlySchedule from '@/pages/beeoinitiative/date-planning'
import { format } from 'date-fns'
import { CalendarIcon, Check, ChevronDown } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_INITIATIVE_CATEGORY, INITIATIVE_CATEGORIES } from '@/lib/initiative'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Card, CardDescription, CardTitle } from '@/components/ui/card'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import CategoryIcon from '@/components/beeoinitiative/category-icon'

import InitiativeImage from './initiative-image'

export const SiteSelectionField = ({
  siteList,
  form,
  defaultSite,
}: {
  siteList: { label: string | undefined; value: number }[]
  form
  defaultSite?: number
}) => {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filteredSites, setFilteredSites] = useState(siteList)

  const params: SitesListParams = {
    is_in_initiative: true,
    page_size: 4,
    search: searchQuery,
  }

  const siteListData = useSitesList(params)

  useEffect(() => {
    if (defaultSite && !form.getValues('site')) {
      form.setValue('site', defaultSite)
    }
  }, [defaultSite, form])

  useEffect(() => {
    if (searchQuery.length > 0 && siteListData.data) {
      setFilteredSites(
        siteListData.data.results.features?.map((site: Site) => ({
          label: site.properties?.name,
          value: site.id,
        })) ?? []
      )
    } else {
      setFilteredSites(siteList)
    }
  }, [searchQuery, siteListData.data, siteList])

  return (
    <FormField
      control={form.control}
      name="site"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel className="font-semibold">
            {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.selectASite)}
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn('w-full justify-between', !field.value && 'text-muted-foreground')}
                >
                  {field.value
                    ? filteredSites.find((site) => site.value === field.value)?.label
                    : t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.selectASite)}
                  <ChevronDown className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[310px] p-0">
              <Command>
                <CommandInput
                  placeholder={t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.searchASite)}
                  value={searchQuery}
                  onValueChange={(e) => setSearchQuery(e)}
                />
                <CommandList>
                  {siteListData.isLoading ? (
                    <CommandItem>{t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.loading)}</CommandItem>
                  ) : filteredSites.length === 0 ? (
                    <CommandEmpty>
                      {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.noSiteFound)}
                    </CommandEmpty>
                  ) : (
                    <CommandGroup>
                      {filteredSites.map((site) => (
                        <CommandItem
                          value={site.label}
                          key={site.value}
                          onSelect={() => form.setValue('site', site.value)}
                        >
                          <Check
                            className={cn('mr-2 size-4', site.value === field.value ? 'opacity-100' : 'opacity-0')}
                          />
                          {site.label}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  )}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export const DateSelectionField = ({ form }) => {
  const { t } = useTranslation()
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  return (
    <FormField
      control={form.control}
      name="date"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel className="font-semibold">
            {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.choseADate)}
          </FormLabel>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant={'outline'}
                  className={cn('w-full pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                >
                  {field.value ? (
                    format(field.value, 'PP')
                  ) : (
                    <span>{t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.pickADate)}</span>
                  )}
                  <CalendarIcon className="ml-auto size-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={(date) => {
                  field.onChange(date)
                  setIsCalendarOpen(false)
                }}
                disabled={(date) => date < new Date('1900-01-01')}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export const CostInputField = ({
  form,
  showDisabledInput,
  value = '',
}: {
  form
  showDisabledInput: boolean
  value?: number | string
}) => {
  const { t } = useTranslation()
  const { control } = form

  return (
    <FormField
      control={control}
      name="cost"
      render={({ field }) => {
        const displayValue = showDisabledInput ? `${value ?? ''}€` : field.value

        return (
          <FormItem>
            <FormLabel className="font-semibold">
              {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.estimatedCost)}
            </FormLabel>

            <FormControl className={showDisabledInput ? 'mt-3' : ''}>
              <Input
                placeholder="€"
                {...field}
                value={displayValue}
                readOnly={showDisabledInput}
                tabIndex={showDisabledInput ? -1 : undefined}
                className={showDisabledInput ? 'bg-gray-200 font-semibold' : ''}
              />
            </FormControl>

            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}

function getTimeLine(timeLine?: string): number[] {
  if (timeLine === 'All') {
    return Array.from({ length: 12 }, (_, i) => i + 1)
  }
  return timeLine ? timeLine.split(',').map(Number) : []
}

export const InitiativeCard = ({ initiative }: { initiative?: Initiative }) => {
  const { t } = useTranslation()
  const initiativeCategory = initiative?.category
    ? INITIATIVE_CATEGORIES[initiative.category]
    : DEFAULT_INITIATIVE_CATEGORY

  return (
    <Card
      className="flex w-full flex-col justify-between overflow-hidden bg-white text-sm"
      style={{ borderColor: initiativeCategory.color + '95' }}
    >
      <InitiativeImage initiative={initiative} />
      <div className="flex flex-row px-6 pt-6">
        <div
          className="flex size-[26px] items-center justify-center rounded-xl border"
          style={{ borderColor: initiativeCategory.color, backgroundColor: initiativeCategory.color + '10' }}
        >
          {initiativeCategory.iconName && (
            <CategoryIcon name={initiativeCategory.iconName} color={initiativeCategory.color} className="size-4" />
          )}
        </div>
        <div className="flex flex-col justify-between px-2 pt-[2px]">
          <CardTitle className="text-lg font-bold leading-5 text-gray-800">{initiative?.short_name}</CardTitle>
          <CardDescription className="mt-1 text-xs" style={{ color: initiativeCategory.color }}>
            {t(initiativeCategory.labelKey)}
          </CardDescription>
        </div>
      </div>
      <div className="flex flex-col justify-end p-6">
        <Separator />
        <div className="pb-3 pt-6">{t(i18nKeys.beeoinitiative.common.idealInstallationPeriod)}</div>
        <MonthlySchedule monthsToColor={getTimeLine(initiative?.time_line || 'null')} />
      </div>
    </Card>
  )
}
