import { useState } from 'react'
import { i18nKeys } from '@/locales/keys'
import { t } from 'i18next'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { Icons } from '@/components/icons'
import SiteForm from '@/components/site-form'

type AddSiteProps = {
  accountId?: number
  queryKey: readonly string[]
}

const AddSite = ({ accountId, queryKey }: AddSiteProps) => {
  const [openSiteSheet, setOpenSiteSheet] = useState(false)
  return (
    <Sheet open={openSiteSheet} onOpenChange={setOpenSiteSheet}>
      <SheetTrigger asChild>
        <Button size="sm" className="ml-2 h-8 lg:flex">
          <Icons.Plus className="mr-2 size-4" />
          {t(i18nKeys.global.common.buttons.addSite)}
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="overflow-y-scroll">
        <SheetHeader>
          <SheetTitle> {t(i18nKeys.global.settings.page.sites.sheet.add.title)}</SheetTitle>
          <SheetDescription>{t(i18nKeys.global.settings.page.sites.sheet.add.description)}</SheetDescription>
        </SheetHeader>
        <SiteForm setOpenSiteSheet={setOpenSiteSheet} accountId={accountId} formType="site" queryKey={queryKey} />
      </SheetContent>
    </Sheet>
  )
}

export default AddSite
