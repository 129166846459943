import { useEffect, useRef, useState } from 'react'
import { Site, SiteIdNameCenter } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import L, { LatLngExpression, Map, MarkerOptions } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { useNavigate } from 'react-router-dom'

import { ScoringType } from '@/types/scoring-type'
import { DEFAULT_CENTER, getSiteCenter } from '@/lib/geo'
import { getMapIcon, hoverSiteIcon } from '@/lib/map'
import { MODULE } from '@/lib/module'
import { getRiskColor } from '@/lib/pin-risk-color'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { Skeleton } from '@/components/ui/skeleton'
import MapPopup from '@/components/beeoinitiative/map-popup'
import { H4 } from '@/components/typography'

interface SitesMapProps {
  sites: SiteIdNameCenter[] | Site[]
  hoveredCard: number | null | undefined
  hoveredCardRef: React.MutableRefObject<number | null>
  isLoading: boolean
  module: MODULE
  scoringType?: ScoringType
  showTooltip?: boolean
}

interface CustomMarkerOptions extends MarkerOptions {
  id?: number
}

const SitesMap = ({
  sites,
  hoveredCard,
  hoveredCardRef,
  isLoading,
  module,
  scoringType,
  showTooltip = true,
}: SitesMapProps) => {
  const { t } = useTranslation()
  const mapRef = useRef<Map>(null)
  const markerRefs = useRef({})
  const navigate = useNavigate()
  const worldBounds = new L.LatLngBounds(new L.LatLng(-88, -180), new L.LatLng(88, 180))
  const [, setHoveredCluster] = useState<number | null>(null)

  const openPopup = (id) => {
    if (markerRefs.current[id]) {
      markerRefs.current[id].openPopup()
    }
  }

  const closePopup = (id) => {
    if (markerRefs.current[id]) {
      markerRefs.current[id].closePopup()
    }
  }

  useEffect(() => {
    if (hoveredCard) {
      openPopup(hoveredCard)
    } else {
      closePopup(hoveredCardRef.current)
    }
  }, [hoveredCard])

  useEffect(() => {
    if (hoveredCard) {
      hoveredCardRef.current = hoveredCard
      setHoveredCluster(hoveredCard)
    }
  }, [hoveredCard])

  const createClusterIcon = (cluster) => {
    const count = cluster.getChildCount()
    const clusterMarkers = cluster.getAllChildMarkers()

    const clusterIds = clusterMarkers.map((marker) => (marker.options as CustomMarkerOptions).id)

    const isHovered = clusterIds.includes(hoveredCardRef.current)

    return L.divIcon({
      html: `<div class="custom-cluster flex items-center justify-center w-9 h-9 text-sm ${
        isHovered ? 'bg-teal-100 border-2' : 'bg-white'
      } border border-biodivTealSevenHundred text-biodivTealSevenHundred rounded-full">
          ${count}
        </div>`,
      className: 'custom-cluster-icon',
      iconSize: L.point(40, 40, true),
    })
  }
  if (isLoading) {
    return <Skeleton className="h-[calc(100vh-100px)] md:w-0 lg:w-[400px] xl:w-[504.1px] 2xl:w-[504.1px]" />
  }

  return (
    <MapContainer
      className="z-0 size-full"
      center={DEFAULT_CENTER}
      zoom={6}
      dragging={true}
      scrollWheelZoom={false}
      ref={mapRef}
      maxBounds={worldBounds}
      maxBoundsViscosity={1}
    >
      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <MarkerClusterGroup
        spiderfyOnMaxZoom={true}
        zoomToBoundsOnClick={true}
        showCoverageOnHover={false}
        maxClusterRadius={50}
        iconCreateFunction={createClusterIcon}
      >
        {sites?.map((site) => {
          const isHovered = hoveredCard === site.id
          const position =
            module === MODULE.INITIATIVE
              ? getSiteCenter(site)
              : ({ lat: site.center.coordinates[1], lng: site.center.coordinates[0] } as LatLngExpression)

          let markerColor = 'defaultPin'

          if (!(module === MODULE.MONITORING || module === MODULE.AUDIT || module === MODULE.INITIATIVE)) {
            const markerColorBiodiversity = getRiskColor(site.score_biodiversity)
            const markerColorPesticide = getRiskColor(site.score_pesticide)

            markerColor = scoringType === ScoringType.BIODIVERSITY ? markerColorBiodiversity : markerColorPesticide
          }
          const iconColor =
            module === MODULE.MONITORING || module === MODULE.AUDIT || module === MODULE.INITIATIVE
              ? 'defaultPinOn'
              : markerColor
          return (
            <Marker
              icon={isHovered ? hoverSiteIcon(iconColor) : getMapIcon(markerColor)}
              key={site.id}
              position={position}
              eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                mouseout: (event) => event.target.closePopup(),
                click: () => {
                  switch (module) {
                    case MODULE.INITIATIVE:
                      return navigate(getPath(Routes.BEEOINITIATIVE_SITE, { params: { siteId: site.id } }))
                    case MODULE.IMPACT:
                      return navigate(getPath(Routes.BEEOIMPACT_SITE, { params: { siteId: site.id } }))
                    default:
                      return
                  }
                },
              }}
              ref={(ref) => {
                if (ref) {
                  markerRefs.current[site.id] = ref
                  return ((ref.options as CustomMarkerOptions).id = site.id)
                }
              }}
            >
              <Popup className="rounded-xl border border-biodivTealSevenHundred">
                {module === MODULE.INITIATIVE ? (
                  <MapPopup
                    siteName={site.properties?.name ?? ''}
                    title={t(i18nKeys.beeoinitiative.sites.initiatives)}
                    numberOfInits={`${site?.properties?.actions_taken?.filter((action) => action?.status === 'DONE').length ?? 0} / ${site?.properties?.actions_taken?.length ?? 0}`}
                    progressBarValue={
                      site?.properties?.actions_taken && site?.properties?.actions_taken.length > 0
                        ? (site.properties.actions_taken.filter((action) => action?.status === 'DONE').length /
                            site.properties.actions_taken.length) *
                          100
                        : 0
                    }
                  />
                ) : module === MODULE.MONITORING || module === MODULE.AUDIT ? (
                  showTooltip && <H4 className="mt-0">{site?.name}</H4>
                ) : (
                  <div className="flex items-center justify-evenly">
                    <div className={cn('mr-2 size-2 rounded-full')} style={{ backgroundColor: '#235576' }} />
                    <span> {t(i18nKeys.global.common.scoringType[scoringType ?? ''])}</span>
                    {scoringType === ScoringType.BIODIVERSITY ? (
                      <span className="ml-5 text-muted-foreground">{site?.score_biodiversity}%</span>
                    ) : (
                      <span className="ml-5 text-muted-foreground">{site?.score_pesticide}%</span>
                    )}
                  </div>
                )}
              </Popup>
            </Marker>
          )
        })}
      </MarkerClusterGroup>
    </MapContainer>
  )
}

export default SitesMap
