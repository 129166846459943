import { i18nKeys } from '@/locales/keys'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

type NoDataProps = {
  icon: React.ReactNode
  title: string
  description: string
  emailText: string
}

const NoData = ({ icon, title, description, emailText }: NoDataProps) => {
  const { t } = useTranslation()
  return (
    <div className="col-span-full flex min-h-[350px]  w-full items-center justify-center rounded-md border border-dashed">
      <div className="flex flex-col items-center justify-center text-center">
        {icon}
        <h3 className="mt-4 text-lg font-semibold">{title}</h3>
        <p className="mb-4 mt-2 text-sm text-muted-foreground">
          <Trans i18nKey={description} value={description} components={{ br: <br /> }} />
        </p>
        <div>
          <Button>
            <a href={`mailto:support@beeodiversity.com?subject=${encodeURIComponent(emailText)}`}>
              {t(i18nKeys.beeoimpact.dashboard.greenCard.button)}
            </a>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NoData
