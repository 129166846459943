import { useEffect, useMemo, useState } from 'react'
import { sitesList } from '@/client/backend/api/sites/sites'
import { PaginatedSiteList, Site, SitePropertiesBuildingType, SitesListParams } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnFiltersState, SortingState, type ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

import { PaginationData } from '@/types/pagination'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Badge } from '@/components/ui/badge'
import { DataTableSSR } from '@/components/data-table-ssr/data-table-ssr'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

type SitesTableProps = {
  data?: PaginatedSiteList
}

const SitesTablePesticides = ({ data }: SitesTableProps) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [sites, setSites] = useState<Site[]>(data?.results?.features ?? [])

  const [sorting, setSorting] = useState<SortingState>([])
  const [filter, setFilter] = useState<ColumnFiltersState>([])

  const urlParams = Object.fromEntries(searchParams.entries())

  const [pagination, setPagination] = useState<PaginationData>({
    count: data?.count ?? 0,
    current_page: (data?.current_page ?? 0) - 1,
    next_page: data?.next_page ?? null,
    previous_page: data?.previous_page ?? null,
    last_page: data?.last_page ?? 10,
    page_size: data?.page_size ?? 10,
  })

  useEffect(() => {
    setSites(data?.results?.features ?? [])
  }, [data])

  useEffect(() => {
    const search = searchParams.get('search')
    if (search) {
      setFilter([{ id: 'name', value: search }])
    }
    const ordering = searchParams.get('ordering')
    if (ordering) {
      const splitOrdering = ordering.split(',').map((o) => {
        const desc = o.startsWith('-')
        return { id: o.replace('-', ''), desc: desc }
      })

      setSorting(splitOrdering)
    }
  }, [])

  useEffect(() => {
    const fetchSites = async (sorting: SortingState, filter: ColumnFiltersState, pagination: PaginationData) => {
      let params: SitesListParams = {
        page_size: pagination.page_size ?? 10,
        page: Number(pagination.current_page) + 1,
      }

      if (sorting.length > 0) {
        const ordering = sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(',')
        params = { ...params, ordering: ordering, page: 1 }
      }
      if (filter.length > 0) {
        const filterName = filter.find((f) => f.id === 'name')
        params = { ...params, search: filterName?.value as string, page: 1 }
      }
      if (pagination.current_page !== (data?.current_page ?? 0) - 1) {
        params = { ...params, page: Number(pagination.current_page) + 1, page_size: pagination.page_size ?? 10 }
      }

      const mergedParams = {
        ...params, // Default params
        ...urlParams, // URL params override defaults
        page: urlParams.page ? Number(urlParams.page) : params.page,
        ordering: urlParams.ordering || params.ordering,
        search: urlParams.search || params.search,
      }

      if (sorting || filter || pagination) {
        const paginatedData = await sitesList(mergedParams)
        setSites(paginatedData.results?.features ?? [])
      }
    }

    fetchSites(sorting, filter, pagination)
  }, [sorting, filter, pagination])

  const getColumns = (): ColumnDef<Site>[] => {
    return [
      {
        accessorKey: 'properties.name',
        id: 'name',
        header: ({ column }) => {
          return <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.sites.table.header.name)} />
        },
        cell: ({ row }) => {
          return (
            <div className="flex space-x-2">
              <Link
                className="max-w-[200px] truncate"
                to={getPath(Routes.BEEOIMPACT_SITE, {
                  params: {
                    siteId: row.original.id ?? '',
                  },
                })}
              >
                <span className="font-medium hover:underline">{row.getValue('name')}</span>
              </Link>
            </div>
          )
        },
      },
      {
        accessorKey: 'properties.stats_pesticides.ecoscore',
        id: 'stats_pesticides__ecoscore',
        header: ({ column }) => {
          return <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.sites.table.header.score)} />
        },
        cell: ({ row }) => {
          const score = parseInt(row.getValue('stats_pesticides__ecoscore'))
          const color = score > 70 ? '#F44336' : score > 30 ? '#FF9800' : '#4CAF50'
          return (
            <div className="flex items-center justify-start space-x-2">
              <div className="size-2 rounded-full" style={{ backgroundColor: color }} />
              <span className="font-semibold">{row.getValue('stats_pesticides__ecoscore')}</span>
            </div>
          )
        },
      },
      {
        accessorKey: 'properties.building_type',
        id: 'building_type',
        header: ({ column }) => {
          return <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.sites.table.header.type)} />
        },
        cell: ({ row }) => {
          const building_type = row.original.properties?.building_type ?? SitePropertiesBuildingType.OFFICE
          const colors = {
            [SitePropertiesBuildingType.OFFICE]: 'bg-red-600',
            [SitePropertiesBuildingType.INDUSTRIAL]: 'bg-orange-600',
            [SitePropertiesBuildingType.AGRICULTURAL]: 'bg-green-600',
            [SitePropertiesBuildingType.MANUFACTURING]: 'bg-blue-600',
            [SitePropertiesBuildingType.EXTRACTION]: 'bg-brown-600',
          }
          return (
            <div className="flex space-x-2">
              {row.original.properties?.stats_biodiversity?.closest_reserve !== null && (
                <span className="w-[150px] truncate font-medium">
                  <Badge className={colors[building_type]}>
                    {t(i18nKeys.beeoimpact.sites.table.buildingType[building_type])}
                  </Badge>
                </span>
              )}
            </div>
          )
        },
      },
      {
        accessorKey: 'properties.stats_pesticides.matters',
        id: 'stats_pesticides__matters',
        header: ({ column }) => {
          return (
            <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.sites.table.header.substances)} />
          )
        },
        cell: ({ row }) => {
          return (
            <div className="flex space-x-2">
              <span className="font-medium">{row.getValue('stats_pesticides__matters')}</span>
            </div>
          )
        },
      },
      {
        accessorKey: 'properties.stats_pesticides.parcels',
        id: 'stats_pesticides__parcels',
        header: ({ column }) => {
          return <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.sites.table.header.crops)} />
        },
        cell: ({ row }) => {
          return (
            <div className="flex space-x-2">
              <span className="font-medium">{row.getValue('stats_pesticides__parcels')}</span>
            </div>
          )
        },
      },
    ]
  }
  const columns = useMemo<ColumnDef<Site, unknown>[]>(() => getColumns(), [])

  return (
    <DataTableSSR
      columns={columns}
      data={sites}
      sorting={sorting}
      setSorting={setSorting}
      paginationData={pagination}
      setPaginationData={setPagination}
      setFilterData={setFilter}
    />
  )
}

export default SitesTablePesticides
