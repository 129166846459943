export const getRiskColor = (score) => {
  if (score === undefined) {
    return 'grey'
  }

  if (score <= 30) {
    return 'green'
  } else if (score > 30 && score < 71) {
    return 'orange'
  } else if (score >= 71) {
    return 'red'
  } else {
    return 'grey'
  }
}
