import { NaceCode, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Badge } from '@/components/ui/badge'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'
import { Icons } from '@/components/icons'
import SettingsSitesTableAction from '@/components/settings-sites-table-action'
import SettingsSitesTableRowAction from '@/components/settings-sites-table-row-action'

type FeatureIconProps = {
  feature: keyof typeof i18nKeys.global.common.module
  icon: React.ReactNode
  route?: Routes
  siteId: number
}

const FeatureIcon = ({ feature, icon, route, siteId }: FeatureIconProps) => {
  const { t } = useTranslation()
  const path = route
    ? getPath(route, {
        params: {
          siteId,
        },
      })
    : '#'

  return (
    <Tooltip key={feature}>
      <TooltipTrigger asChild>
        <Link to={path} className="flex size-5 items-center justify-center">
          {icon}
        </Link>
      </TooltipTrigger>
      <TooltipContent>
        <p>{t(i18nKeys.global.common.module[feature])}</p>
      </TooltipContent>
    </Tooltip>
  )
}

type SitesTableProps = {
  sites: Site[]
  accountId?: number
  queryKey: readonly string[]
}

const SettingsSitesTable = ({ sites, accountId, queryKey }: SitesTableProps) => {
  const { t } = useTranslation()

  const columns: ColumnDef<Site>[] = [
    {
      accessorKey: 'properties.name',
      id: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.name)} />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex max-w-[150px] space-x-2 truncate">
            <span className="font-medium">{row.getValue('name')}</span>
          </div>
        )
      },
    },
    {
      id: 'feature',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.features)} />
      ),
      cell: ({ row }) => {
        return (
          <div className="grid grid-cols-2 place-items-center gap-2">
            <TooltipProvider>
              {row.original.properties?.is_in_impact && (
                <FeatureIcon
                  feature="impact"
                  icon={<Icons.BeeoImpactMinimal className="size-4" />}
                  route={Routes.BEEOIMPACT_SITE}
                  siteId={row.original.id}
                />
              )}
              {row.original.properties?.is_in_monitoring && (
                <FeatureIcon
                  feature="monitoring"
                  icon={<Icons.BeeoMonitoringMinimal className="size-4" />}
                  route={Routes.BEEOMONITORING_SITE}
                  siteId={row.original.id}
                />
              )}
              {row.original.properties?.is_in_metrics && (
                <FeatureIcon
                  feature="metrics"
                  icon={<Icons.BeeoMetricsMinimal className="size-4" />}
                  route={undefined}
                  siteId={row.original.id}
                />
              )}
              {row.original.properties?.is_in_initiative && (
                <FeatureIcon
                  feature="initiative"
                  icon={<Icons.BeeoInitiativeMinimal className="size-4" />}
                  route={Routes.BEEOINITIATIVE_SITE}
                  siteId={row.original.id}
                />
              )}
            </TooltipProvider>
          </div>
        )
      },
    },
    {
      accessorKey: 'properties.center.coordinates',
      id: 'lat',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.lat)} />
      ),
      cell: ({ row }) => {
        const center: number[] = row.getValue('lat')
        return (
          <div className="flex space-x-2">
            <span className="max-w-[50px] truncate font-medium">{center[1].toFixed(2)}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'properties.center.coordinates',
      id: 'lng',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.lng)} />
      ),
      cell: ({ row }) => {
        const center: number[] = row.getValue('lng')
        return (
          <div className="flex space-x-2">
            <span className="max-w-[50px] truncate font-medium">{center[0].toFixed(2)}</span>
          </div>
        )
      },
    },

    {
      accessorKey: 'properties.biodiversity_radius',
      id: 'biodiversity_radius',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.radius)} />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <span className="max-w-[1000px] truncate font-medium">{row.getValue('biodiversity_radius')}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'properties.surface_ha',
      id: 'surface_ha',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.surface)} />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <span className="max-w-[50px] truncate font-medium">{row.getValue('surface_ha')}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'properties.nace_codes',
      id: 'nace_codes',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.nace_code)} />
      ),
      cell: ({ row }) => {
        const naceCodes: NaceCode[] = row.getValue('nace_codes')

        return (
          <div className="grid grid-cols-2 gap-2">
            {naceCodes && naceCodes?.length > 0
              ? naceCodes.map((nace, index) => (
                  <Badge className="flex max-w-[120px] items-center justify-center truncate" key={index}>
                    {nace.code}
                  </Badge>
                ))
              : null}
          </div>
        )
      },
    },

    {
      accessorKey: 'properties.building_type',
      id: 'building_type',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.type)} />
      ),
      cell: ({ row }) => {
        const value: string = row.getValue('building_type')
        return (
          <div className="flex space-x-2">
            <span className="max-w-[1000px] truncate font-medium">
              {t(i18nKeys.global.settings.page.sites.buildingType[value])}
            </span>
          </div>
        )
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return (
          <SettingsSitesTableRowAction site={row.original} sites={sites} accountId={accountId} queryKey={queryKey} />
        )
      },
    },
  ]

  return (
    <DataTable
      columns={columns}
      data={sites}
      filterIdColumn="name"
      action={<SettingsSitesTableAction accountId={accountId} queryKey={queryKey} />}
    />
  )
}

export default SettingsSitesTable
