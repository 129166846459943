import { SensitiveAreaOut, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { SENSITIVE_AREA_COLORS } from '@/lib/constants/sensitive-area-colors'
import { SENSITIVE_AREA_SOURCE_LABELS_TW } from '@/lib/constants/sensitive-area-source'
import { cn } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'

import { Table, TableCell, TableHeaderCell, TableRow } from './table.print'

interface ClcTableProps {
  sensitiveAreas: SensitiveAreaOut[]
  site: Site
}

const SensitiveAreasTablePrint = ({ sensitiveAreas, site }: ClcTableProps) => {
  const { t } = useTranslation()

  return (
    <Table
      columns={6}
      header={
        <>
          <TableHeaderCell>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.type)}</TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.radius)}
          </TableHeaderCell>
          <TableHeaderCell>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name)}</TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.position)}
          </TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surface)}
          </TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.distance)}
          </TableHeaderCell>
        </>
      }
    >
      {sensitiveAreas.map(({ name, location, surface_ha, distance_m, source }, i) => {
        return (
          <TableRow key={i}>
            <TableCell>
              <Badge
                className={cn(
                  'flex h-6 w-12 justify-center text-center text-xs text-white',
                  `${SENSITIVE_AREA_SOURCE_LABELS_TW[source]}`
                )}
              >
                {t(i18nKeys.beeoimpact.sensitiveAreas.source[source])}
              </Badge>
            </TableCell>
            <TableCell>
              <span>{site?.properties?.biodiversity_radius}</span>
            </TableCell>
            <TableCell>
              <span>{name}</span>
            </TableCell>
            <TableCell>
              <Badge className={`w-max ${SENSITIVE_AREA_COLORS[location]}`}>
                {t(i18nKeys.beeoimpact.biodiversityTable.location[location])}
              </Badge>
            </TableCell>
            <TableCell>
              <span>{surface_ha?.toFixed(2)}</span>
            </TableCell>
            <TableCell>
              <span>{distance_m ? distance_m.toFixed(2) : ''}</span>
            </TableCell>
          </TableRow>
        )
      })}
    </Table>
  )
}

export default SensitiveAreasTablePrint
