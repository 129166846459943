import { useMemo } from 'react'
import { useAccountsStatsPesticidesRetrieve } from '@/client/backend/api/accounts/accounts'
import { ProbablePesticide, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { AlertTriangle, FlaskConical } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { toTitleCase } from '@/lib/string'
import { useAuth } from '@/hooks/useAuth'
import PesticidesChart from '@/components/beeoimpact/pesticides-chart'
import PesticidesTable from '@/components/beeoimpact/tables/pesticides-table'
import DashboardCard from '@/components/dashboard-card'
import IndicatorCard from '@/components/indicator-card'
import NoSitesMessage from '@/components/no-sites-message'

interface DashboardPesticidesProps {
  sitesCount: number
  sites: Site[]
}

const DashboardPesticides = ({ sitesCount, sites }: DashboardPesticidesProps) => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const { data: stats, isLoading: isLoadingStats } = useAccountsStatsPesticidesRetrieve(user?.account_id ?? 0, {
    query: { enabled: !!user && hasAccesTo(user?.features ?? [], FeatureType.PESTICIDES) },
  })

  const sortedPesticides = useMemo(() => {
    return (
      stats?.list_pesticides
        .sort((a, b) => b.occurrence - a.occurrence)
        .slice(0, 10)
        .map((pesticide) => ({
          ...pesticide,
          name: toTitleCase(pesticide.name),
        })) || []
    )
  }, [stats])

  const sortedCultures = useMemo(() => {
    return stats?.list_culture.sort((a, b) => b.hectare - a.hectare).slice(0, 10) || []
  }, [stats])

  const probablePesticidesData = sites
    ?.map((site) => {
      return site.properties?.stats_pesticides?.probable_pesticides
    })
    .flat()

  const mergedPesticides = sortedPesticides
    .map((p) => {
      const match = probablePesticidesData.find((pp) => pp?.name === p.name)
      return {
        ...p,
        ecoscores: match ? match.ecoscores : null,
      }
    })
    .filter((p) => p.ecoscores !== null) as ProbablePesticide[]

  if (sitesCount === 0) {
    return <NoSitesMessage />
  }

  return (
    <section>
      <div className="my-5 grid gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
        <IndicatorCard
          title={t(i18nKeys.beeoimpact.dashboard.pesticides.sites.title)}
          value={sitesCount}
          linkText={t(i18nKeys.beeoinitiative.dashboard.seeAllSites)}
          to={getPath(Routes.BEEOIMPACT_SITES)}
          skeletonSize="h-[210px]"
          isLoading={isLoadingStats}
        />

        <IndicatorCard
          title={t(i18nKeys.beeoimpact.dashboard.pesticides.sitesAtRisk.title)}
          value={stats?.nb_sites_at_risk ?? 0}
          linkText={t(i18nKeys.beeoimpact.dashboard.pesticides.sitesAtRisk.seeSitesAtRisk)}
          icon={<AlertTriangle className="size-6 text-red-600" />}
          bgColor="bg-red-50 border border-red-600"
          to={getPath(Routes.BEEOIMPACT_SITES)}
          skeletonSize="h-[210px]"
          isLoading={isLoadingStats}
        />

        <IndicatorCard
          title={t(i18nKeys.beeoimpact.dashboard.pesticides.title)}
          value={stats?.list_pesticides.length ?? 0}
          linkText={t(i18nKeys.beeoimpact.dashboard.pesticides.sites.totalOfAllYourSites)}
          icon={<FlaskConical className="size-6 text-blue-600" />}
          skeletonSize="h-[210px]"
          isLoading={isLoadingStats}
        />
      </div>
      <div className="mb-5 flex flex-col">
        <div className="mb-5 flex flex-col md:flex-col md:space-x-0 md:space-y-5 xl:flex-row xl:space-x-5 xl:space-y-0">
          <div className="xl:w-1/2">
            <DashboardCard
              title={t(i18nKeys.beeoimpact.dashboard.pesticides.mostPesticidesFound.title)}
              subtitle={t(i18nKeys.beeoimpact.dashboard.pesticides.mostPesticidesFound.description, {
                count: sitesCount,
              })}
              height="min-h-[755px] max-h-[755px]"
              isLoading={isLoadingStats}
              skeletonHeight="h-[755px]"
              children={
                <PesticidesChart
                  data={sortedPesticides}
                  nameKey="name"
                  valueKey="occurrence"
                  barColor="hsl(220, 80%, 60%)"
                  tooltipColor="hsl(220, 80%, 60%)"
                  responsiveContainerWidth="100%"
                />
              }
            />
          </div>
          <div className="md:hidden xl:block xl:w-1/2">
            <DashboardCard
              title={t(i18nKeys.beeoimpact.dashboard.pesticides.impactedTaxons.title)}
              isLoading={isLoadingStats}
              skeletonHeight="h-[755px]"
              height="min-h-[755px] max-h-[755px]"
              children={<PesticidesTable activeMatters={mergedPesticides} hideActiveMatterColumn={true} />}
              justifyContent="start"
            />
          </div>

          <div className="md:block xl:hidden">
            <DashboardCard
              title={t(i18nKeys.beeoimpact.dashboard.pesticides.impactedTaxons.title)}
              isLoading={isLoadingStats}
              skeletonHeight="h-[755px]"
              children={<PesticidesTable activeMatters={mergedPesticides} hideActiveMatterColumn={false} />}
            />
          </div>
        </div>
        <DashboardCard
          title={t(i18nKeys.beeoimpact.dashboard.pesticides.mostCommonCultures.title)}
          subtitle={t(i18nKeys.beeoimpact.dashboard.pesticides.mostCommonCultures.description, {
            count: sitesCount,
          })}
          isLoading={false}
          skeletonHeight="h-[755px]"
          children={
            <PesticidesChart
              data={sortedCultures}
              nameKey="name"
              valueKey="hectare"
              barColor="hsl(43,96%,56%)"
              tooltipColor="hsl(43,96%,56%)"
              responsiveContainerWidth="85%"
            />
          }
        />
      </div>
    </section>
  )
}

export default DashboardPesticides
